<template>
  <div class="payment-request-status statusMB">
      <div class="d-flex align-items-center filter fullWMb">
          <div class="filter" :span="2" v-for="item in listStatus" :key="item.index" @click="changeStatus(item)">
              <div class="item" :class="item.value === status ? 'active' : ''" style="cursor: pointer;">
                <span
                  class="label"
                  >{{ item.name }}</span
                >
                <span
                  class="count"
                  :style="{ backgroundColor: item.color }"
                  >{{ item.quantity }}</span
                >
              </div>
          </div>
      </div>

  </div>
</template>

<script>
import EventBus from '@/EventBus'
import { mapGetters } from "vuex";
export default {
  name: "StatusPayment",
  props: {
    listStatus: Array,
    status: String,
  },
  metaInfo() {
    return {
      title: "Yêu cầu thanh toán",
    };
  },
  components: {

  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters("account", ["logged", "accountInfo"]),

  },
  mounted() {
  },
  methods: {
    changeStatus(item) {
      this.$emit('status', item.value);
      EventBus.$emit('statusSelect', item.value);
    }
  },
  watch: {

  },
};
</script>

<style lang="css" scoped>
.payment-request-status {
  /* height: 63px;
  background: #ffffff; */
  height: 48px;
  background: #f6f8ff;
  border-bottom: 1px solid #bdd6ee;
}
.payment-request-status .filter {
  padding: 4px 10px;
}
.payment-request-status .filter .item {
  /* padding: 8px; */
  padding: 0 5px;
}
.payment-request-status .filter .item.active {
  background: #2196F3;
  border-radius: 4px;
  color: #ffffff;
}
.label {
  font-size: 14px;
  line-height: 31px;
  font-weight: 400;
  margin-right: 5px;
}
.count {
  color: #fff;
    border-radius: 3px;
    padding: 1px 5px;
    font-size: 13px;
}
.active .label{
  color: #ffffff;
}
@media only screen and (max-width: 768px), (max-width: 1023px) {
  .payment-request-status {
    height: auto;
    overflow-x: auto;
  }
  .min100 {
    min-width: 100px;
  }
  .fullWMb {
    width: max-content;
    padding: 10px !important;
  }
  .statusMB {
    margin: 0 !important;
  }
}
</style>
