<template>
    <div class="frame-content-common-2022 cart-create" v-loading="loading">
      <div class="xadal_title_head">
        <span>{{ listLanguage.menu_cart }}</span>
      </div>

      <div class="d-flex align-items-center mb-3" style="background-color: #fff; padding: 15px">
        <el-row>
          <el-col class="">
            <el-select class="mr-2 mt-2" v-model="filters.source" placeholder="Chọn nguồn">
              <el-option
                v-for="item in sources"
                :key="item.val"
                :label="item.name"
                :value="item.val">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>

      <div v-for="(supplier, index) in listCart" :key="index">
        <SupplierItem
          :key="filters.source + supplier.id"
          :class="index > 0 ? 'mt-2' : ''"
          :tableData="supplier"
          :supplierIndex="index"
          @changeAll="handleSelectionTableChange"
          @changeQuantity="changeQuantity"
          @deleteProduct="deleteProduct"
        />
      </div>

      <div class="mt-3"
        v-if="listCart.length"
        style="background-color: #fff; padding: 10px"
      >
        <el-row style="width: 100%">
            <el-col :span="6"></el-col>
            <el-col :span="18" class="d-flex align-items-center justify-content-center" style="float: right; text-align: center">
                <el-col :span="4">Tổng</el-col>
                <el-col :span="6">{{ totalQuantity }}</el-col>
                <el-col :span="6">¥{{ totalPrice }}</el-col>
                <el-col :span="8">
                    <el-button style="width: 90%" type="success" @click="orderNowConfirm()" :disabled="!canSubmit">
                        <el-icon class="el-icon-loading" v-if="!canSubmit"></el-icon> Tạo đơn
                    </el-button>
                </el-col>
            </el-col>
        </el-row>
      </div>

      <div v-if="!listCart.length" class="cart-none flex-column">
        <div>
            <img src="@/static/icon/order-create/icon.svg" alt=""/>
        </div>
        <span class="text-none py-4">
            {{ listLanguage.search_no_products }}<br>{{ listLanguage.search_in_the_cart }}
        </span>
      </div>

       <!-- // địa chỉ nhạn hàng  -->
       <Address
         :dialogAddress="dialogAddress"
         :source="filters.source"
         @cancel="cancelAddress"
         @addressDone="addressDone"
       />

    </div>
  </template>

  <script>
  import Address from "@/views/shopping/addressCN/Form";
  import axios from "axios";
  import {mapGetters} from "vuex";
  import SupplierItem from "../../views/shopping/components/cart/Items";

  export default {
    name: "CreateCart",
    components: {
        SupplierItem,
        Address
    },
    metaInfo() {
      return {
        title: "Giỏ hàng",
      };
    },
    mounted() {
      this.fetchCart();
    },

    data() {
      return {
        dialogAddress: false,
        canSubmit: true,
        // Filter
        loading: false,
        checkAll: false,

        // Cart Ext
        listCart: [],

        // others
        isChangingProductProperty: false,
        notes: [],
        totalPrice: 0,
        totalQuantity: 0,
        dataTableSelection: [],
        itemSelected: [],
        sources: [
          {
            val: 'taobao',
            name: 'Taobao'
          },
          {
            val: '1688',
            name: '1688'
          }
        ],
        filters: {
          source: 'taobao'
        },
        listCartFilter: [],
        receiver: ''
      };
    },

    computed: {
      ...mapGetters("account", ["accountInfo", "listLanguage"]),
    },

    methods: {
      fetchCart() {
        this.loading = true;
        axios.get("cart/product/list-cart").then((response) => {
          this.loading = false;
          switch (response.code) {
            case 200: {
              let data = response.data;
              //let dataCheck = this.checkDataCart(data);
              this.listCart       = JSON.parse(JSON.stringify(data));
              this.listCartFilter = JSON.parse(JSON.stringify(data));
              // this.listCartFilter.forEach(item => {
              //   item.checked = true;
              // })
              //this.handleSelectionTableChange();
              //this.dataTableSelection = JSON.parse(JSON.stringify(data));
              //console.log(this.dataTableSelection, 111);
              this.filterBySouce();
              break;
            }
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },
      // Xóa bản ghi
      deleteProduct(item) {
        this.$confirm("Xóa sản phẩm " + item.title, "Cảnh báo", {
          confirmButtonText: "Đồng ý",
          cancelButtonText: "Hủy",
          type: "warning",
        })
          .then(() => {
            this.deleteModelChild(item);
          })
          .catch(() => {
          });
      },
      deleteModelChild(item) {
        if (!item.mpSkuId) {
            return;
        }

        // let data = {
        //     mpSkuId: item.mpSkuId
        // }
        axios.post("cart/product/delete-cart/" + item.mpSkuId, {source: item.source}).then((response) => {
          switch (response.code) {
            case 200:
              this.$notify({
                title: "Thành công",
                message: "Xóa sản phẩm thành công",
                type: "success",
              });
              this.fetchCart();

              //this.$emit('checkCartNumber', Math.floor(Math.random() * 100000) + 1)
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },

      // địa chỉ nhận hàng 
      cancelAddress() {
        this.dialogAddress = false;
      },
      addressDone(data) {
        if (this.filters.source == 'taobao') {
          this.receiver = data;
        } else {
          this.receiver = {
            address: data.address,
            mobile: data.mobile_phone,
            fullName: data.name,
            postCode: data.zip,
            areaText: data.district,
            cityText: data.city,
            provinceText: data.city,
            country: data.country,
            shipping_address_id: data.shipping_address_id,
            status: data.status
          };
        }
        
        this.dialogAddress = false;
        this.submitForm();
      },

      orderNowConfirm() {
        this.dialogAddress = true;
      },

      submitForm() {
        if (this.totalQuantity < 1) {
          this.$notify({
            title: "Vui lòng kiểm tra lại thông tin",
            message: "Vui lòng chọn ít nhất 1 sản phẩm",
            type: "warning",
          });
          return;
        }

        let items = this.getDataItem();
        //console.log(items, 'items order');
        if (!items || !items.length) {
            this.$notify({
                title: "Vui lòng kiểm tra lại thông tin",
                message: "Sản phẩm tạo không hợp lệ!",
                type: "warning",
            });
            return;
        }

        this.canSubmit = false;

        let dataOrders = {
          items: items,
          totalPrice: (this.totalPrice * 100).toFixed(0),
          totalQuantity: this.totalQuantity,
          sourceType: 'xadal',
          receiver: this.receiver  
        };
        let url = ""
        if(this.filters.source == 'taobao'){
          url = "order/store";
        }else{
          url = "order/store-1688";
        }
        axios.post(url, dataOrders).then((response) => {
          this.canSubmit = true;
          switch (response.code) {
            case 200:
              this.$notify({
                title: "Thành công",
                message: "Tạo đơn thành công",
                type: "success",
              });
              this.fetchCart();
              break;
            case 422:
              this.$notify({
                title: "Có lỗi xảy ra",
                message: Object.values(response.data)[0],
                type: "warning",
              });
              break;
            case 400:
              this.$notify({
                title: "Có lỗi xảy ra",
                message: response.message,
                type: "warning",
              });
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },
      getDataItem() {
        let data = [];

        let itemSelected = this.itemSelected;
        if(this.filters.source == 'taobao'){
          this.dataTableSelection.forEach(function (item) {
            //console.log(item, item.mpSkuId, itemSelected.length, itemSelected.includes(item.mpSkuId));
            if (item.mpSkuId && itemSelected.length && itemSelected.includes(item.mpSkuId)) {
              let itemOrder = {
                itemId: item.mpId,
                orderLineNo: '1',
                quantity: item.quantity,
                price: (item.price * 100).toFixed(0),
                currency: 'CNY',
                skuId: item.mpSkuId,
                note: item.note,
                title: item.title,
                image: item.image,
                supplier: item.supplier
              }
              data.push(itemOrder);
            }
          });
        }else{
          this.dataTableSelection.forEach(function (item) {
            if (item.mpSkuId && itemSelected.length && itemSelected.includes(item.mpSkuId)) {
              let itemOrder = {
                specId: item.mpSkuId,
                quantity: item.quantity,
                offerId: item.mpId,
                supplier: item.supplier
              }
              data.push(itemOrder);
            }
          });
        }
        return data;
      },
      // Check all
      changeCheckAll() {
        // this.listCart.forEach((item) => {
        //   item.checked = true;
        // });
        // this.$nextTick(function () {
        //   this.$refs.cartTable.toggleAllSelection();
        // });
        this.listCart.forEach((supplier) => {
          supplier.checked = true;
          if (supplier.items && supplier.items.length) {
            supplier.items.forEach((item) => {
              item.checked = true;
            })
          }
        });
      },

      // Xử lý đếm số lượng realtime
      async caculaterCart() {
        let total_money    = 0;
        let total_quantity = 0;

        let newListCart  = [];
        let itemSelected = [];

        let source = this.filters.source;
        source = source.charAt(0).toUpperCase() + source.slice(1); // uppercase for first character
        //console.log(source, 'src')

        this.dataTableSelection.forEach((item) => {
          let total_item_money = 0;
          if (item.checked && item.source == source) {
            total_item_money = parseInt(item.quantity) * parseFloat(item.price);

            total_money    = total_money + total_item_money;
            total_quantity += parseInt(item.quantity);

            if (item.mpSkuId) {
                itemSelected.push(item.mpSkuId);
            }
          }

          // Lưu lại
          item.total_money = total_item_money;
          newListCart.push(item);
        });
        this.totalPrice    = parseFloat(total_money).toFixed(2);
        this.totalQuantity = total_quantity;
        //this.listCart      = newListCart;
        this.itemSelected = itemSelected;
        //console.log(itemSelected, 'item selected')
      },
      handleSelectionTableChange(supplierId, itemsActive, itemIdsInactive) {
        //console.log(supplierId, itemsActive, itemIdsInactive, 1111);

        if (itemsActive.length) {
          this.dataTableSelection.push(...itemsActive);
        }
        if (itemIdsInactive.length) {
          this.dataTableSelection = this.dataTableSelection.filter((item) => {
            return !itemIdsInactive.includes(item.mpId);
          })
        }

        //console.log(this.dataTableSelection);
      },
      changeQuantity(supplierIndex, index, type = 'plus', val = 1) {
          let listCart = this.listCart;

          if (!listCart[supplierIndex] || !listCart[supplierIndex]['items'][index]) {
              return;
          }

          let itemChange = listCart[supplierIndex]['items'][index];
          //console.log(itemChange, 'item change');

          let oldQuantity = itemChange.quantity ? itemChange.quantity : 1;
          oldQuantity     = parseInt(oldQuantity);
          //console.log(oldQuantity, 'old');

          let newVal = oldQuantity + val;
          if (type == 'minus') {
              newVal = oldQuantity > val ? oldQuantity - val : 1;
          } else if (type == 'change') {
              newVal = val;
          }
          //console.log(newVal, 'new');
          listCart[supplierIndex]['items'][index].quantity = newVal;
          //console.log(listCart, 'list');
          this.listCart = listCart;
      },
      filterBySouce() {
        //this.listCart = this.listCartFilter.filter((item) => { return item.source.toLowerCase() == this.filters.source })
        let source = this.filters.source;
        source = source.charAt(0).toUpperCase() + source.slice(1); // uppercase for first character
        //console.log(source, 'source');
        this.dataTableSelection = [];
        this.listCart = this.listCartFilter[source] ?? [];
        //console.log(this.listCart, 'cart filter')
        //console.log(this.listCart, 'cart');
        this.changeCheckAll();
      }
    },

    watch: {
      dataTableSelection: {
        handler: function () {
          this.caculaterCart();
        },
        deep: true,
      },
      'filters.source': function() {
        this.filterBySouce();
      }
    },
  };
  </script>

  <style lang="css" scoped>
  .btn-created-order {
    background: #10DD90;
    color: #ffffff;
  }
  .cart-create {
    color: #575757;
  }
.bg-create-order {
    background: #10DD90;
}
  .btn-delete {
    padding: 2px 8px;
    border-radius: 4px;
    background: #545454;
    color: #ffffff;
  }

  /*Phần bảng */
  .cart-create .icon-item {
    padding: 10px 5px 10px 5px;
    font-size: 12px;
    height: auto;
    text-align: center;
    background-color: #fff;
    color: #575757;
    width: 150px;
    flex-grow: 1;
  }

  .cart-create .icon-item.first {
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding-left: 15px;
    width: 420px;
    flex-grow: initial;
  }

  .cart-create .icon-item.last {
    width: 50px;
  }

  /*Phần bảng - tiêu đề */
  .cart-create-header {
    margin-bottom: 13px;
    padding: 0px 30px 0px 30px;
    background-color: #fff;
  }

  .cart-create-header .icon-item.first span {
    margin-left: 10px;
  }

  /*Phần bảng - từng nhà cung cấp */
  .cart-create-supplier-item {
    background-color: #fff;
  }

  .cart-create-supplier-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e1e1e1;
  }

  .cart-create-supplier-header .icon-88 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
  }

  .cart-create-supplier-header .icon-88.left {
    padding-left: 15px;
  }

  .cart-create-supplier-header .icon-88.left span {
    margin-left: 10px;
    font-weight: 700;
    color: #575757;
  }

  .cart-create-supplier-header .icon-88.right span {
    margin-left: 5px;
    color: #575757;
  }

  .cart-create-supplier-header .icon-88.right:hover {
    opacity: 0.8;
  }

  .cart-create-supplier-line {
    border-bottom: 1px solid #e1e1e1;
  }

  .cart-create-supplier-line .icon-item {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .cart-create-supplier-line .icon-item.first span {
    margin-left: 10px;
  }

  .cart-create-supplier-line .icon-item.first .sub-12x {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #8b8b8b;
    margin-top: 7px;
  }

  .cart-create-supplier-line .icon-item.first img {
    margin-right: 10px;
  }

  .cart-create-supplier-line .icon-item.first .img-product {
    width: 100px;
  }

  .cart-create-supplier-line .icon-item.first .link-product {
    margin-right: 5px;
    padding-top: 12px;
    padding-bottom: 5px;
    display: inline-flex;
    padding-left: 30px;
  }

  .cart-create-supplier-line .icon-item.first .link-product a {
    font-weight: 400;
    color: #575757;
    text-decoration: none;
    font-size: 12px;
  }

  .cart-create-supplier-line .price-item span {
    line-height: 20px;
  }

  .cart-create-supplier-line .price-item .price-red {
    color: #dd2120;
  }
  </style>

  <style type="text/css">
  .frame-textarea-common-2022 {
    font-size: 12px;
  }

  .frame-textarea-common-2022 textarea::-webkit-input-placeholder {
    font-weight: 300;
    color: #b9bcbe;
    font-size: 12px;
    font-style: italic;
  }

  .frame-textarea-common-2022 textarea::-moz-placeholder {
    font-weight: 300;
    color: #b9bcbe;
    font-size: 12px;
    font-style: italic;
  }

  .frame-textarea-common-2022 textarea::-ms-input-placeholder {
    font-weight: 300;
    color: #b9bcbe;
    font-size: 12px;
    font-style: italic;
  }

  /* // css giỏ hàng ko có dữ liệu  */
  .cart-none {
    display: flex;
    justify-content: center;
    /* min-height: 500px; */
    text-align: center;
    padding-top: 120px;
  }

  .cart-none .text-none {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #575757;
    letter-spacing: 0.02em;
  }

  .new-create-order {
    background: #26b01c;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: .02em;
    text-transform: uppercase;
    color: #fff;
    width: 225px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .sku-properties {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: .02em;
    color: #767676;
    margin-top: 5px; text-align: left;
  }

  </style>
