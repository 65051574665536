import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/vi";

// Filter
import * as filters from "@/filters";

// Aixos OK
import "@/api/config.js";

import SocketIO from 'socket.io-client';
import VueSocketIO from 'vue-socket.io';


Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;
// element-ui
Vue.use(ElementUI, { locale });

const socketConnection = SocketIO(process.env.VUE_APP_REALTIME_URL, {
  reconnection: process.env.VUE_APP_REALTIME_ACTIVE
});
Vue.use(new VueSocketIO({
  debug: true,
  connection: socketConnection,
}))

// CSS
import "@/static/css/style.css";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
