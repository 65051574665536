<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row" style="">
      <el-checkbox v-model="checked" @change="checkSupplier"></el-checkbox>
      <span class="ml-2">Nhà cung cấp: {{ supplier.name }}</span>
    </div>
    <div class="d-flex mt-2" v-if="supplier.items.length">
      <el-table
        :data="supplier.items"
        :ref="'cartTable'"
        border
        style="width: 100%"
        @selection-change="handleSelectionTableChange"
      >
        <el-table-column
          header-align="center"
          align="center"
          type="selection"
          min-width="55"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="title"
          label="Sản phẩm"
          min-width="300"
        >
          <template slot-scope="scope">
            <div class="d-flex align-items-center">
              <el-link target="_bank" :href="scope.row.link">
                <img width="80" height="80" class="img-product" :src="scope.row.image"/>
              </el-link>
              <div class="d-flex flex-column ml-2">
                <el-link target="_bank" style="text-align: left;" :href="scope.link">{{ scope.row.title }}</el-link>
                <!--<span class="sub-12x">{{ scope.classification }}</span>-->
                <span class="sku-properties">{{ scope.row.options.join(';') }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="title"
          label="Đơn giá"
          min-width="100"
        >
          <template slot-scope="scope">
            <div class="d-flex align-items-center justify-content-center">
              <span class="price-red">¥{{ scope.row.price }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="title"
          label="Số lượng"
          min-width="200"
        >
          <template slot-scope="scope">
            <div class="justify-content-center">
              <el-row class="">
                <el-col :span="24" class="d-flex align-items-center">
                  <el-col :span="6" style="text-align: right; padding-right: 10px;">
                    <el-link :underline="false"  icon="el-icon-remove-outline" style="color: #00D0FF"
                             @click="changeQuantity(scope.$index, 'minus')"
                    ></el-link>
                  </el-col>
                  <el-col :span="12">
                    <el-input
                      type="text"
                      placeholder="Số lượng"
                      v-model="scope.row.quantity"
                      size="mini"
                      style="text-align: center"
                      @change="changeQuantity(scope.$index, 'change', scope.row.quantity)"
                    >
                    </el-input>
                  </el-col>
                  <el-col :span="6" style="text-align: left; padding-left: 10px;">
                    <el-link :underline="false" icon="el-icon-circle-plus-outline"
                       style="color: #00D0FF;"
                       @click="changeQuantity(scope.$index, 'plus')"
                    >
                    </el-link>
                  </el-col>
                </el-col>
              </el-row>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="title"
          label="Thành tiền"
          min-width="100"
        >
          <template slot-scope="scope">
            <div class="d-flex align-items-center justify-content-center">
              <span class="price-red">¥{{ parseFloat(parseInt(scope.row.quantity) * parseFloat(scope.row.price)).toFixed(2) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="title"
          label="Ghi chú"
          min-width="250"
        >
          <template slot-scope="scope">
            <div class="d-flex align-items-center text-center">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="Ghi chú"
                v-model="scope.row.note">
              </el-input>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="title"
          label="Thao tác"
          min-width="100"
        >
          <template slot-scope="scope">
            <div class="d-flex align-items-center">
              <el-button type="info" @click="deleteProduct(scope.row)" size="mini">Xoá</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "TableSupplier",
    props: {
      tableData: Object,
      supplierIndex: Number
    },
    metaInfo() {
      return {
        title: "Danh sách sản phẩm",
      };
    },
    components: {

    },
    data() {
      return {
        dataTableSelection: [],
        tableRef: '',
        supplier: this.tableData,
        checked: true
      };
    },
    computed: {
      ...mapGetters("account", ["logged", "accountInfo", "listLanguage"]),

    },
    mounted() {
      //console.log(this.tableData, 'table data');
      this.tableRef = 'cartTable_' + this.supplier.id;
      //this.$refs[this.tableRef].toggleAllSelection();
      this.$refs.cartTable.toggleAllSelection();
    },
    methods: {
      handleSelectionTableChange(event) {
        let itemIdsInactive = [];
        let itemsActive     = event;

        if (event.length) {
          if (event.length == this.supplier.items.length) {
            this.checked = true;
          } else { // get item id inactive
            let itemIdsActive = [];
            itemsActive.forEach((item) => {
              itemIdsActive.push(item.mpId);
            })

            this.supplier.items.filter((item) => {
              if (!itemIdsActive.length || !itemIdsActive.includes(item.mpId)) {
                itemIdsInactive.push(item.mpId);
              }
            });
          }
        } else {
          this.checked = false;

          this.supplier.items.filter((item) => {
            itemIdsInactive.push(item.mpId);
          });
        }

        this.dataTableSelection = event;
        //console.log('check/uncheck all');
        this.$emit('changeAll', this.supplier.id, itemsActive, itemIdsInactive);
      },
      checkSupplier(val) {
        if (!val) {
          this.$refs.cartTable.clearSelection();
        } else {
          this.$refs.cartTable.toggleAllSelection();
        }
      },
      changeQuantity(index, type, val) {
        this.$emit('changeQuantity', this.supplierIndex, index, type, val);
      },
      deleteProduct(item) {
        this.$emit('deleteProduct', item);
      }
    },
    watch: {
      // "checked": function(val) {
      //   this.checkSupplier(val);
      // }
    },
  };
</script>

<style lang="css" scoped>
  .main-index {
    padding: 0 25px;
    background: #fff;
    min-height: calc(100vh - 130px);
  }

  .main-index .title-header {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px; /* 100% */
    letter-spacing: 0.32px;
    text-transform: uppercase;
    padding-top: 31px;
  }

  /* search website */
  .main-index .seach-china-2023 {
    padding: 16px 0;
  }

  .main-index .select-mall-index {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 1px solid #BFD5EC;
    background: #FFF;
    width: 80px;
    height: 35px;
    padding: 0 10px 0 20px;
    border-right: 1px solid #bfd5ec;
    cursor: pointer;
    position: relative;
  }

  .select-mall-index .image img {
    height: 22px;
    margin-right: 5px;
  }

  .sub-select-mall {
    position: absolute;
    min-height: 100px;
    background-color: #fff;
    width: 102px;
    left: 0;
    top: 35px;
    z-index: 1;
  }

  .sub-select-mall .image {
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-bottom: 1px solid #ccc;
  }

  .sub-select-mall .image:hover {
    background-color: #f2f2f2;
    transition: all 0.3s;
  }

  .main-index .seach-china-2023 input {
    width: 70%;
    font-size: 13px;
    color: #333;
    margin-right: 8px;
    outline: none;
    border: 1px solid #BFD5EC;
    height: 33px;
    border-left: none;
    padding-left: 17px;
  }

  .main-index .seach-china-2023 input::-webkit-input-placeholder {
    font-weight: 300;
    color: #1771C1;
    font-size: 12px;
    font-style: italic;
  }

  .seach-button-hhqq {
    width: 80px;
    margin-left: -40px;
    z-index: 999;
  }

  .seach-button-hhqq i {
    color: #fff !important;
  }

  .btn-hhqq {
    background: #fe6701;
    border-radius: 20px;
    height: 35px;
    width: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-hhqq:hover {
    cursor: pointer;
  }

  .btn-hhqq:hover img {
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
  }

  /* // */
  .main-index .txt-filter {
    color: #575757;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.28px;
    text-transform: capitalize;
    padding-right: 16px;
  }

  .btn-filter {
    width: 132px;
    height: 30px;
    border-radius: 4px;
    background: #26B01C;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 500;
    border: 1px solid #26B01C;
    cursor: pointer;;
  }

  .btn-filter:hover {
    background: #fff;
    color: #26B01C;
  }

  /* // bảng search */
  .box-main-search-category {
    background: #F0F1F6;
    display: flex;
    align-items: center;
  }

  .box-main-search-category img {
    margin: 0 5px;
  }

  .box-col-4 {
    background: #F0F1F6;
    width: 100%;
    cursor: pointer;
    margin: 4px 0;
    padding: 10px 0;
  }

  /* // sản phẩm  */
  .box-product {
    border: 1px solid #ECECEC;
    background-color: #FFF;
  }



  .box-product .item-image {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 220px;
    cursor: pointer;
    background-size: cover;
    position: relative;
  }
  .box-product .item-image .freeship{
    height: 25px;
    background-color: #084C94;
    position: absolute;
    bottom: 0%;
    opacity: 0.7;
    width: 100%;
    background: linear-gradient(to top left, transparent 15%, #084C94 70%);
  }
  .txt-detail {
    border: 1px solid #ECECEC;
    background-color: #FFF;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0.28px;
  }

  .txt-detail:hover {
    background: #1771C1;
    color: #fff;
  }

  .box-product .content {
    padding: 8px;
  }

  .txt-sp {
    color: #1771C1;
    height: 59px;
    max-height: 59px;
    overflow: hidden;
  }

  .txt-sp span {
    overflow: hidden;
  }

  .txt-sale {
    float: right;
    color: grey;
  }

  .txt-red {
    color: red;
  }
</style>

<style>
  .main-index .frame-filter-innput-2022 .el-input__inner {
    width: 130px;
  }

  .main-index .frame-filter-innput-2022 input {
    text-align: left;
    padding-left: 30px;
  }

  .txt-dropdown {
    font-size: 12px;
    width: 100%;
  }

  .pagi-search {
    padding-right: 0 !important;
  }

  @media only screen and (max-width: 576px) {
    .box-main-search-category {
      width: 190px !important;
    }
  }

  .input_search_key {
    width: 70%;
    border: 1px solid #BFD5EC;
    border-left: none;


  }

  .input_search_key input {
    height: 35px;
    border-radius: 0;
    border-left: none;
  }

  /************************/
  /**** CUSTOM CLASSES ****/
  /************************/
  .hoverPointer:hover {
    cursor: pointer !important;
  }

  .providerBlock {
    max-height: 42px;
    overflow-y: hidden;
  }

  .txt-provider {
    color: grey;
    margin: 8px 0 0 !important;
  }

  .action-buttons {
    padding: 0 8px 8px;
    display: flex;
    justify-content: space-between;
  }

  .action-buttons button {
    font-size: 12px;
    padding: 8px;
  }

  .action-buttons button + button {
    margin-left: 0;
  }

  /************************/
  /**** CUSTOM LOADING ****/
  /************************/
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: #ECECEC;
    z-index: 999;
  }

  .spinner {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1.2s linear infinite;
    margin-top: 120px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .frame-filter-item-2022 .el-select input {
    border-radius: 20px;
    height: 32px;
    border-color: #409EFF;
  }

  .frame-filter-item-2022 .el-button {
    height: 32px;
    padding: 4px 8px;
  }

  .frame-filter-item-2022 .el-checkbox {
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .frame-filter-item-2022 .el-checkbox .el-checkbox__label {
    margin-right: 8px;
  }

  .frame-filter-item-2022 .el-checkbox .el-checkbox__inner {
    border-radius: 4px;
  }
</style>
