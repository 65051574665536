<template>
    <div class="payment-request-table mt-3">
      <div class="mt-3 d-flex justify-content-between mg-b-15">
        <div class="d-flex align-items-center">
            <el-checkbox v-if="tab == 1" v-model="checkedAll" @change="selectAll" style="margin-right: 10px">Chọn tất cả</el-checkbox>
            <el-button class="btn-payment" @click="handlePaymentFast('multiple', '')">{{ listLanguage.payment_fast }}</el-button>
            <el-button class="btn-excel" @click="exportExcel()">{{ listLanguage.payment_export_excel_file }}</el-button>
            <el-button class="btn-deposit" @click="manyCreateDeposit()">Tạo đơn ký gửi</el-button>
            <el-button class="btn-payment" @click="showCheckPrice()" style="background-color: #054604;">Cập nhật giá tiền</el-button>
            <el-button class="btn-payment" @click="cancelMultiple('multiple', '')" style="background-color: #FF3E56;">Hủy nhiều đơn hàng</el-button>
            <el-button v-if="userLogin.parent_id !== null" class="btn-waiting-payment-sent" @click="changeStatus('multiple', '')">Chờ gửi thanh toán</el-button>
        </div>
        <div class="d-flex align-items-center total_money_check_box" v-if="multipleSelection.length > 0">
          <span class="title">Tổng giá trị cần thanh toán: </span>
          <span style="color: #10DD90;" class="pl-5"> ¥ {{ total_money_te | formatCurrency }}</span>
          <span style="color: #E91F63;" class="pl-5">{{ total_money_vnd | vnd }}</span>
        </div>
        <div class="d-flex align-items-center total_money_check_box" v-if="checkStatus == 'payment-sent'">
          <span class="title">Tổng giá trị thanh toán: </span>
          <span style="color: #10DD90;" class="pl-5"> ¥ {{ total_money_te_status | formatCurrency }}</span>
          <span style="color: #E91F63;" class="pl-5">{{ total_money_vnd_status | vnd }}</span>
        </div>
        <div class="show-list text-center">
          <el-tooltip placement="top">
            <div slot="content">Xem danh sách</div>
            <div class="width-half f-left" :class="tab == 1 ? 'active-1' : ''" @click="tabTable(1)" >
            <img src="@/static/icon/payment-request/list-1.svg"  alt="image" style="width: 20px"/>
          </div>
          </el-tooltip>
          <el-tooltip placement="top">
            <div slot="content">Xem dạng bảng</div>
            <div class="width-half" :class="tab == 2 ? 'active-2' : ''" @click="tabTable(2)">
              <img src="@/static/icon/payment-request/list-2.svg"  alt="image" style="width: 20px"/>
            </div>
          </el-tooltip>
        </div>
      </div>
      <el-table
        v-if="tab == 2"
        class="mt-3"
        ref="multipleTable"
        :data="tableData"
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="50" :selectable="canSelectRow"
          align="center"
        >
        </el-table-column>
        <el-table-column
          type="index"
          align="center"
          label="STT"
        ></el-table-column>
        <el-table-column
          property="order_code"
          label="Mã đơn hàng"
          min-width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-link @click="gotoDetail(scope.row.order_code)">{{ scope.row.order_code }}</el-link>
            <div class="d-flex flex-column">

              <div class="d-flex align-items-center justify-content-center">
                <div>
                  <span class="source-name">
                {{ scope.row.source_name }}
                </span>
                </div>
                <div class="pl-1 source_type">
                  <span class="source-name" v-if="scope.row.source_type == 1">Xadal</span>
                  <span class="source-name" v-if="scope.row.source_type == 2">Add On</span>
                  <span class="source-name" v-if="scope.row.source_type == 4">Auto</span>
                </div>
                <el-popover
                  v-if="scope.row.logistic.length > 0"
                  placement="bottom-start"
                  trigger="click"
                  class="pl-1"
                  >
                  <el-table :data="scope.row.logistic">
                    <el-table-column  type="index"
                    align="center"
                    label="STT"></el-table-column>
                    <el-table-column width="150" property="date" label="Mã vận đơn">
                        <template slot-scope="scope">
                            <span>{{ scope.row.logistic_code }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="150" property="name" label="Trạng thái">
                        <template slot-scope="scope">
                          <span :style="{color: scope.row.status_color}">{{ scope.row.status_text }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="150" label="Thao tác">
                      <template slot-scope="scope">
                        <div class="popover_content">
                        <el-popover
                          placement="right-start"
                          trigger="hover"
                          width="250"
                          class="pl-1"
                        >
                          <div class="main_step">
                            <div class="d-flex align-items-center pb-3">
                              <div class="car pr-2 pt-1">
                                <img src="@/static/icon/payment-request/car.svg" alt="">
                              </div>
                              <span class="title">Hành trình kiện hàng </span>
                            </div>
                            <div>
                              <div class="step_main" style="height: 200px;">
                                <el-steps direction="vertical">
                                  <el-step icon="el-icon-caret-right" v-for="(item , index) in scope.row.logistic_detail" :key="index" :title="item.status_text" :description="item.date">
                                  </el-step>
                                </el-steps>
                              </div>
                            </div>
                          </div>
                          <el-button @click="showDetail(scope.row.id)" slot="reference" type="small">Xem chi tiết </el-button>
                        </el-popover>
                        </div>
                        </template>
                    </el-table-column>
                  </el-table>
                  <el-button class="package-number" slot="reference">
                    {{ scope.row.logistic.length }} Kiện
                  </el-button>
                </el-popover>

              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          property="order_code"
          label="Mã đặt hàng"
          min-width="120"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.purchase_order_outer_id }}
          </template>
        </el-table-column>
        <el-table-column
          property="user_name_payment"
          label="Tài khoản mua hàng"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.user_name }}</div>
            {{ scope.row.user_name_payment }}
          </template>
        </el-table-column>
        <el-table-column
          property="note"
          align="center"
          label="Ghi chú"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="d-flex justify-content-center align-items-center">
              <el-link type="primary" class="mr-2" v-if="scope.row.note" @click="showListNote(scope.row.notes)">
                {{ scope.row.note }}
              </el-link>
              <el-button type="text" @click="updateNote(scope.row)">
                <img class="" src="@/static/icon/payment-request/edit.svg" />
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          property="created_at"
          :label="titleTable"
          show-overflow-tooltip
          align="center"
          >
          <template slot-scope="scope">
            <span v-if="titleTable == 'Ngày tạo'">{{ scope.row.created_at }}</span>
            <span v-else>{{ scope.row.payment_sent_date }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Thanh toán"
          show-overflow-tooltip
          align="center"
          min-width="160"
        >
          <template slot-scope="scope">
            <el-row  class="d-flex justify-content-center align-items-center text-left">
              <el-col :span="14" :xs="24">Tổng tệ</el-col>
              <el-col :span="10" :xs="24">
                <div v-if="scope.row.status === 'waiting-processing' || scope.row.status === 'refuse' || scope.row.status === 'waiting-payment-sent'"
                  @click="updatePrice(scope.row)" class="d-flex"
                >
                  <el-button type="text" class="pr-1" style="padding: 0;">
                    <span class="money" :style="scope.row.foreign_currency > 0 && scope.row.foreign_currency == scope.row.price_checked_client ? 'color: #26B01C' : ''">{{ scope.row.foreign_currency | formatCurrency }} ¥</span>
                  </el-button>
                  <img src="@/static/icon/payment-request/edit.svg" />
                </div>
                <div class="money" v-else>
                  <span class="money" :style="scope.row.foreign_currency > 0 && scope.row.foreign_currency == scope.row.price_checked_client ? 'color: #26B01C' : ''">
                    {{ scope.row.foreign_currency | formatCurrency }} ¥
                  </span>
                </div>
              </el-col>
            </el-row>
            <el-divider class="custom-divider"></el-divider>
            <el-row class="d-flex justify-content-center align-items-center text-left">
              <el-col :span="14" :xs="24">Tỷ giá</el-col>
              <el-col :span="10" :xs="24">
                <div v-if="scope.row.vip_price > 0" style="color: #000;">
                  {{ scope.row.vip_price | vnd2 }}
                </div>
              </el-col>
            </el-row>
            <el-divider class="custom-divider"></el-divider>
            <el-row class="d-flex justify-content-center align-items-center text-left">
              <el-col :span="14" :xs="24">Tổng tiền VNĐ</el-col>
              <el-col :span="10" :xs="24">
                <span class="money-transaction">{{ scope.row.transaction_value | vnd }}</span>
              </el-col>
            </el-row>
            <el-divider class="custom-divider" v-if="scope.row.deposit_percent > 0"></el-divider>
            <el-row class="d-flex justify-content-center align-items-center text-left" v-if="scope.row.deposit_percent > 0 && scope.row.refund_from_supplier > 0">
              <el-col :span="14" :xs="24">Tổng tiền hoàn</el-col>
              <el-col :span="10" :xs="24">{{ scope.row.refund_from_supplier | formatCurrency}}</el-col>
            </el-row>
            <el-divider class="custom-divider" v-if="scope.row.deposit_percent > 0 && scope.row.refund_from_supplier > 0"></el-divider>
            <el-row class="d-flex justify-content-center align-items-center text-left"
              v-if="scope.row.deposit_percent > 0 && scope.row.refund_remaining > 0">
              <el-col :span="14" :xs="24">Tổng tiền đã hoàn</el-col>
              <el-col :span="10" :xs="24">{{ scope.row.refund_remaining | formatCurrency }}</el-col>
            </el-row>
            <el-divider class="custom-divider" v-if="scope.row.deposit_percent > 0 && scope.row.refund_remaining > 0"></el-divider>
            <el-row class="d-flex justify-content-center align-items-center text-left"
              v-if="scope.row.total_fee_vn > 0 || scope.row.total_fee_cn > 0"
            >
              <el-col :span="14" :xs="24">Phí dịch vụ</el-col>
              <el-col :span="10" :xs="24">
                <div v-if="scope.row.type_refunds == 0 || scope.row.status === 'refuse'">
                  <div class="money-transaction">
                    {{  scope.row.total_fee_vn | vnd }}
                  </div>
                  <div>
                    {{  scope.row.total_fee_cn | formatCurrency }} ¥
                  </div>
                </div>
                <div v-else-if="scope.row.type_refunds == 1">
                  <div>
                    {{  scope.row.total_fee_vn | vnd }}
                  </div>
                </div>
                <div v-else-if="scope.row.type_refunds == 2 || scope.row.type_refunds == 3">
                  <div>
                    {{  scope.row.total_fee_cn | formatCurrency }} ¥
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-divider class="custom-divider" v-if="scope.row.total_fee_vn > 0 || scope.row.total_fee_cn > 0"></el-divider>
            <el-row class="d-flex justify-content-center align-items-center text-left"
              v-if="scope.row.deposit_percent > 0 && scope.row.is_percent_deposit == 1">
              <el-col :span="14" :xs="24">Đặt cọc</el-col>
              <el-col :span="10" :xs="24">
                {{ parseInt(scope.row.deposit_percent) }} %
              </el-col>
            </el-row>
            <el-divider class="custom-divider" v-if="scope.row.deposit_percent > 0 && scope.row.is_percent_deposit == 1"></el-divider>
            <el-row class="d-flex justify-content-center align-items-center text-left"
              v-if="(scope.row.total_fee_vn > 0 || scope.row.total_fee_cn > 0 || scope.row.deposit_percent > 0)  && scope.row.is_percent_deposit == 1"
            >
              <el-col :span="14" :xs="24">Tổng tiền thanh toán</el-col>
              <el-col :span="10" :xs="24">
                <div v-if="scope.row.type_refunds == 0 || scope.row.status === 'refuse'">
                  <div class="money-transaction">
                    {{  scope.row.total_payment_vn | vnd }}
                  </div>
                  <div>
                    {{  scope.row.total_payment_cn | formatCurrency }} ¥
                  </div>
                </div>
                <div v-else-if="scope.row.type_refunds == 1">
                  <div>
                    {{  scope.row.total_payment_vn | vnd }}
                  </div>
                </div>
                <div v-else-if="scope.row.type_refunds == 2 || scope.row.type_refunds == 3">
                  <div>
                    {{  scope.row.total_payment_cn | formatCurrency }} ¥
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-divider class="custom-divider"
              v-if="(scope.row.total_fee_vn > 0 || scope.row.total_fee_cn > 0 || scope.row.deposit_percent > 0)  && scope.row.is_percent_deposit == 1"
            >
            </el-divider>
            <el-row class="d-flex justify-content-center align-items-center text-left" v-if="scope.row.deposit_percent > 0  && scope.row.is_percent_deposit == 1">
              <el-col :span="14" :xs="24">Tiền còn lại</el-col>
              <el-col :span="10" :xs="24">
                <div v-if="scope.row.type_refunds == 0 || scope.row.status === 'refuse'">
                  <div class="money-transaction">
                    {{  scope.row.total_remaining_vn | vnd }}
                  </div>
                  <div>
                    {{  scope.row.total_remaining_cn | formatCurrency }} ¥
                  </div>
                </div>
                <div v-else-if="scope.row.type_refunds == 1">
                  <div>
                    {{  scope.row.total_remaining_vn | vnd }}
                  </div>
                </div>
                <div v-else-if="scope.row.type_refunds == 2 || scope.row.type_refunds == 3">
                  <div>
                    {{  scope.row.total_remaining_cn | formatCurrency }} ¥
                  </div>
                </div>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column
          property="status_text"
          align="center"
          label="Tình trạng"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.reason_cancel"
              placement="top"
              v-if="scope.row.status == 'cancel' || scope.row.status == 'refuse'"
            >
              <div>
                <span>{{ scope.row.status_text }} <i class="el-icon-view"></i></span>
              </div>
            </el-tooltip>
            <span v-else>{{ scope.row.status_text }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="130px"
          label="Thao tác"
        >
          <template slot-scope="scope">
            <div class="d-flex flex-column justify-content-center">
            <el-button
              v-if="(scope.row.status == 'paid' || scope.row.status == 'finish') && scope.row.is_created_deposit == 0"
              style="min-width: 90px" size="small" class="btn_create_con" @click="handleCreateConsignment(scope.row)">Tạo đơn ký gửi</el-button>
            <el-button
              style="min-width: 90px; margin-left: 0"
              v-if="scope.row.status === 'waiting-processing' || scope.row.status === 'refuse' || scope.row.status === 'waiting-payment-sent'"
              class="btn-payment-single mb-1" size="small"
              @click="handlePaymentFast('single', scope.row)"

            >Thanh toán</el-button>
            <el-button
              style="min-width: 90px; margin-left: 0"
              v-if="scope.row.status !== 'cancel' && scope.row.status !== 'paid'
              && scope.row.status !== 'processing' && scope.row.status !== 'finish'"
              class="btn-cancel mb-1" size="small" @click="handleCancel(scope.row)">Hủy bỏ</el-button>

            <el-button
                style="min-width: 90px"
                v-if="scope.row.status === 'payment-sent' || scope.row.status === 'processing' || scope.row.status === 'paid'"
                class="btn-complain mgl-0 mb-1" size="small" @click="toCreateComplain(scope.row)">Khiếu nại</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="tab == 1 && flagCheckTab">
        <div class="full-width bg-box" v-for="item in tableData" :key="item.id">
          <el-row :gutter="20" style="padding: 10px;">
            <el-col :span="5">
              <div>
                <el-checkbox v-model="item.checked"
                  style="margin-right: 10px;"
                  :disabled="item.status !== 'waiting-processing' && item.status !== 'refuse' && item.status !== 'waiting-payment-sent'">
                </el-checkbox>
                <span class="label-in-box">Mã đơn hàng</span> {{ item.order_code }}
              </div>
              <div class="d-flex flex-column mt-10">
                <div class="d-flex" style="margin-left: 25px">
                  <div>
                    <span class="source-name">
                  {{ item.source_name }}
                  </span>
                  </div>
                  <div class="pl-1 source_type">
                    <span class="source-name" v-if="item.source_type == 1">Xadal</span>
                    <span class="source-name" v-if="item.source_type == 2">Add On</span>
                    <span class="source-name" v-if="item.source_type == 4">Auto</span>
                  </div>
                  <el-popover
                    v-if="item.total_order_package > 0"
                    placement="bottom-start"
                    trigger="click"
                    class="pl-1"
                    >
                    <el-table :data="gridData">
                      <el-table-column  type="index"
                      align="center"
                      label="STT"></el-table-column>
                      <el-table-column width="150" property="date" label="Mã vận đơn"></el-table-column>
                      <el-table-column width="150" property="name" label="Trạng thái"></el-table-column>
                      <el-table-column width="150" label="Thao tác">
                        <template slot-scope="scope">
                          <el-popover
                            placement="right-start"
                            trigger="hover"
                            class="pl-1"
                          >
                            <div>
                              <!-- Chỗ step -->
                            </div>
                            <el-button @click="showDetail(scope.row.id)" slot="reference" type="small">Xem chi tiết </el-button>
                          </el-popover>
                          </template>
                      </el-table-column>
                    </el-table>
                    <el-button class="package-number" slot="reference">
                      {{ item.total_order_package }} kiện
                    </el-button>
                  </el-popover>

                </div>
                </div>
            </el-col>
            <el-col :span="5">
              <div>
                <span class="label-in-box">Mã đặt hàng</span> <span class="text-in-box">{{ item.purchase_order_outer_id }}</span>
              </div>
            </el-col>
            <el-col :span="4">
              <div>
                <span class="label-in-box">Tài khoản mua hàng:</span> <span class="text-in-box">{{ item.user_name }} </span>
                <span v-if="item.user_name_payment" class="text-in-box"> {{ item.user_name_payment }}</span>
              </div>
            </el-col>
            <el-col :span="5">
              <div>
                <span class="label-in-box">Ngày tạo: </span>
                <span v-if="titleTable == 'Ngày tạo'" class="text-in-box">{{ item.created_at }}</span>
                <span v-else class="text-in-box">{{ item.payment_sent_date }}</span>
              </div>
            </el-col>
            <el-col :span="2">
              <div>
                <span class="label-in-box">Trạng thái</span>
                <div class="text-in-box">{{ item.status_text }}</div>
              </div>
              </el-col>
              <el-col :span="3">
                  <el-button
                    v-if="(item.status == 'paid' || item.status == 'finish') && item.is_created_deposit == 0"
                    size="small" class="btn_create_con" @click="handleCreateConsignment(item)">Tạo đơn ký gửi</el-button>
                  <el-button
                    v-if="item.status === 'waiting-processing' || item.status === 'refuse' || item.status === 'waiting-payment-sent'"
                    class="btn-payment-single" size="small"
                    @click="handlePaymentFast('single-new', item)"

                  >Thanh toán</el-button>
                  <el-button
                    v-if="item.status !== 'cancel' && item.status !== 'paid'
                    && item.status !== 'processing' && item.status !== 'finish'"
                    class="btn-cancel" size="small" @click="handleCancel(item)">Hủy bỏ</el-button>

                  <el-button
                  v-if="item.status === 'payment-sent' || item.status === 'processing' || item.status === 'paid'"
                  class="btn-complain" size="small" @click="toCreateComplain(item)">Khiếu nại</el-button>
              </el-col>
          </el-row>
          <el-row>
            <el-col :span="16" style="padding: 15px;">
              <div class="box-supplier">
                <div v-if="item.order && item.order.suppliers && item.order.suppliers.length > 0">
                  <div v-for="(supplier, index) in item.order.suppliers" :key="index" :class="index > 0 ? 'mt-2' : ''">
                    <div class="">
                      <div class="d-flex flex-column">
                        <div class="d-flex flex-row" style="">
                          <span class="">Nhà cung cấp: {{ supplier.name }}</span>
                          <div v-if="supplier.source_name == 'Taobao'" class="d-flex align-items-center pl-2 txt_chat_ncc" @click="showChatNCC(supplier)">
                            <img v-if="chatNcc == false" src="@/static/icon/detail-search/chat.svg" alt="" />
                            <i v-else class="el-icon-loading"></i>
                            <span class="pl-2">{{ listLanguage.search_chat_with_supplier }}</span>
                          </div>
                        </div>
                        <div class="d-flex mt-2" v-if="supplier.items.length">
                          <el-table
                            :data="supplier.items"
                            :ref="'cartTableDetail'"
                            style="width: 100%"
                          >
                            <el-table-column
                              prop="title"
                              label="Sản phẩm"
                              min-width="300"
                            >
                              <template slot-scope="scope">
                                <div class="d-flex align-items-center">
                                  <el-link target="_bank" :href="scope.row.link">
                                    <img width="80" height="80" class="img-product" :src="scope.row.image"/>
                                  </el-link>
                                  <div class="d-flex flex-column ml-2">
                                    <el-link target="_bank" style="text-align: left;" :href="scope.link">{{ scope.row.name }}</el-link>
                                    <!--<span class="sub-12x">{{ scope.classification }}</span>-->
                                    <span class="sku-properties">{{ scope.row.options ? scope.row.options.join(';') : '' }}</span>
                                    <div class="">
                                      <div>
                                        <span class="text-in-box">Số lượng: </span> {{ scope.row.quantity }}
                                      </div>
                                      <div>
                                        <el-input
                                          type="textarea"
                                          :rows="2"
                                          placeholder="Ghi chú"
                                          v-model="scope.row.note"
                                          :disabled="true"
                                        >
                                        </el-input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column
                              align="center"
                              prop="title"
                              label="Đơn giá"
                              min-width="200"
                            >
                              <template slot-scope="scope">
                                <div class="d-flex align-items-center justify-content-center">
                                  <span class="price-red">¥{{ scope.row.price | formatCurrency }}</span>
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column
                              align="center"
                              prop="title"
                              label="Thành tiền"
                              min-width="200"
                            >
                              <template slot-scope="scope">
                                <div class="d-flex align-items-center justify-content-center">
                                  <span class="price-red">¥{{ parseFloat(parseInt(scope.row.quantity) * parseFloat(scope.row.price)).toFixed(2)  | formatCurrency }}</span>
                                </div>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="text-in-box" style="font-style: italic;">
                  Đơn hàng này không tìm thấy sản phẩm. Vui lòng kiểm tra lại mã đơn hàng.
                </div>
                <div class="d-flex mt-2" v-if="item.order && item.order.shipping_address">
                  <p>Địa chỉ nhận hàng: {{ item.order.shipping_address.address + ' - ' + item.order.shipping_address.district + ' - ' +
                      item.order.shipping_address.city}}</p>
                </div>

                <div class="d-flex align-items-center justify-content-center mt-3"
                style="background-color: #fff; padding: 10px"
                >
                <el-row style="width: 100%" v-if="item.order">
                    <el-col :span="8"></el-col>
                    <el-col :span="12" class="d-flex align-items-center justify-content-center" style="float: right; text-align: right">
                        <el-col :span="12">Phí Ship</el-col>
                        <el-col :span="6"></el-col>
                        <el-col :span="6">
                            <span style="font-weight: 700; color: #DA100B">¥{{ item.order ? item.order.total_fee : ''  | formatCurrency}}</span>
                        </el-col>
                    </el-col>
                </el-row>
                </div>
                <div class="d-flex align-items-center justify-content-center"
                style="background-color: #fff; padding: 10px"
                >
                  <el-row style="width: 100%" v-if="item.order">
                    <el-col :span="8"></el-col>
                    <el-col :span="12" class="d-flex align-items-center justify-content-center" style="float: right; text-align: right">
                      <el-col :span="12">Tổng</el-col>
                      <el-col :span="6">
                        <span style="font-weight: 700; color: #DA100B">{{ item.order.total_quantity }}</span>
                      </el-col>
                      <el-col :span="6">
                        <span style="font-weight: 700; color: #DA100B">¥{{ item.order ? item.order.total_price : ''  | formatCurrency }}</span>
                      </el-col>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <el-col :span="8" style="padding: 15px; padding-left: 0px">
              <div class="box-payment">
                <div class="label-in-box mb-10">Thanh toán</div>
                <el-row class="pd-row">
                  <el-col :span="18"><span class="text-in-box">Tổng tệ</span></el-col>
                  <el-col :span="6">
                    <div v-if="item.status === 'waiting-processing' || item.status === 'refuse' || item.status === 'waiting-payment-sent'"
                      @click="updatePrice(item)" class="d-flex"
                    >
                      <el-button type="text" class="pr-1" style="padding: 0;">
                        <span class="money" :style="item.foreign_currency > 0 && item.foreign_currency == item.price_checked_client ? 'color: #26B01C' : ''">{{ item.foreign_currency | formatCurrency }} ¥</span>
                      </el-button>
                      <img src="@/static/icon/payment-request/edit.svg" />
                    </div>
                    <div class="money" v-else>
                      <span class="money" :style="item.foreign_currency > 0 && item.foreign_currency == item.price_checked_client ? 'color: #26B01C' : ''">
                        {{ item.foreign_currency | formatCurrency }} ¥
                      </span>
                    </div>
                  </el-col>
                </el-row>
                <el-divider class="custom-divider"></el-divider>
                <el-row class="pd-row">
                  <el-col :span="18"><span class="text-in-box">Tỷ giá</span></el-col>
                  <el-col :span="6">
                    <div v-if="item.vip_price > 0" style="color: #000;">
                      {{ item.vip_price | vnd2 }}
                    </div>
                  </el-col>
                </el-row>
                <el-divider class="custom-divider"></el-divider>
                <el-row class="pd-row">
                  <el-col :span="18"><span class="text-in-box">Tổng tiền VNĐ </span></el-col>
                  <el-col :span="6">
                    <span class="money-transaction">{{ item.transaction_value | vnd }}</span>
                  </el-col>
                </el-row>
                <el-divider class="custom-divider"></el-divider>
                <el-row  class="pd-row" v-if="item.deposit_percent > 0 && item.refund_from_supplier > 0">
                  <el-col :span="18"><span class="text-in-box">Tổng tiền hoàn</span></el-col>
                  <el-col :span="6">
                    <div v-if="item.vip_price > 0" style="color: #000;">
                      {{ item.vip_price | vnd2 }}
                    </div>
                  </el-col>
                </el-row>
                <el-divider v-if="item.deposit_percent > 0 && item.refund_from_supplier > 0" class="custom-divider"></el-divider>
                <el-row class="pd-row" v-if="item.deposit_percent > 0 && item.refund_remaining > 0">
                  <el-col :span="18"><span class="text-in-box">Tổng tiền đã hoàn</span></el-col>
                  <el-col :span="6">
                    {{ item.refund_remaining | formatCurrency }}
                  </el-col>
                </el-row>
                <el-divider v-if="item.deposit_percent > 0 && item.refund_remaining > 0" class="custom-divider"></el-divider>
                <el-row class="pd-row" v-if="item.total_fee_vn > 0 || item.total_fee_cn > 0">
                  <el-col :span="18"><span class="text-in-box">Phí dịch vụ</span></el-col>
                  <el-col :span="6">
                    <div v-if="item.type_refunds == 0 || item.status === 'refuse'">
                    <div class="money-transaction">
                      {{  item.total_fee_vn | vnd }}
                    </div>
                    <div>
                      {{  item.total_fee_cn | formatCurrency }} ¥
                    </div>
                  </div>
                  <div v-else-if="item.type_refunds == 1">
                    <div>
                      {{  item.total_fee_vn | vnd }}
                    </div>
                  </div>
                  <div v-else-if="item.type_refunds == 2 || item.type_refunds == 3">
                    <div>
                      {{  item.total_fee_cn | formatCurrency }} ¥
                    </div>
                  </div>
                  </el-col>
                </el-row>
                <el-divider v-if="item.total_fee_vn > 0 || item.total_fee_cn > 0" class="custom-divider"></el-divider>
                <el-row class="pd-row" v-if="item.deposit_percent > 0 && item.is_percent_deposit == 1">
                  <el-col :span="18"><span class="text-in-box">% Đặt cọc</span></el-col>
                  <el-col :span="6">
                    {{ parseInt(item.deposit_percent) }} %
                  </el-col>
                </el-row>
                <el-divider v-if="item.deposit_percent > 0 && item.is_percent_deposit == 1" class="custom-divider"></el-divider>
                <el-row class="pd-row" v-if="(item.total_fee_vn > 0 || item.total_fee_cn > 0 || item.deposit_percent > 0)  && item.is_percent_deposit == 1">
                  <el-col :span="18"><span class="text-in-box">Tổng tiền thanh toán</span></el-col>
                  <el-col :span="6">
                    <div v-if="item.type_refunds == 0 || item.status === 'refuse'">
                      <div class="money-transaction">
                        {{  item.total_payment_vn | vnd }}
                      </div>
                      <div>
                        {{  item.total_payment_cn | formatCurrency }} ¥
                      </div>
                    </div>
                    <div v-else-if="item.type_refunds == 1">
                      <div>
                        {{  item.total_payment_vn | vnd }}
                      </div>
                    </div>
                    <div v-else-if="item.type_refunds == 2 || item.type_refunds == 3">
                      <div>
                        {{  item.total_payment_cn | formatCurrency }} ¥
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-divider v-if="(item.total_fee_vn > 0 || item.total_fee_cn > 0 || item.deposit_percent > 0)  && item.is_percent_deposit == 1" class="custom-divider"></el-divider>
                <el-row class="pd-row" v-if="item.deposit_percent > 0  && item.is_percent_deposit == 1">
                  <el-col :span="18"><span class="text-in-box">Tiền còn lại</span></el-col>
                  <el-col :span="6">
                    <div v-if="item.type_refunds == 0 || item.status === 'refuse'">
                      <div class="money-transaction">
                        {{  item.total_remaining_vn | vnd }}
                      </div>
                      <div>
                        {{  item.total_remaining_cn | formatCurrency }} ¥
                      </div>
                    </div>
                    <div v-else-if="item.type_refunds == 1">
                      <div>
                        {{  item.total_remaining_vn | vnd }}
                      </div>
                    </div>
                    <div v-else-if="item.type_refunds == 2 || item.type_refunds == 3">
                      <div>
                        {{  item.total_remaining_cn | formatCurrency }} ¥
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-divider v-if="item.deposit_percent > 0  && item.is_percent_deposit == 1" class="custom-divider"></el-divider>
                <div class="mt-10">
                  <span class="label-in-box">Ghi chú</span>
                  <div class="">
                    <el-link type="primary" class="mr-2" v-if="item.note" @click="showListNote(item.notes)">
                      {{ item.note }}
                    </el-link>
                    <el-button class="pdt-0" type="text" @click="updateNote(item)" v-if="!item.note">
                      <img src="@/static/icon/payment-request/edit.svg" /> Thêm
                    </el-button>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div
        class="china-panigation-1988 d-flex justify-content-end pt-1"
      >
        <el-pagination
          background
          layout="sizes, prev, pager, next"
          :page-size="pagination.per_page"
          :total="pagination.total"
          :current-page="filter.page"
          @current-change="changeCurrentPage"
          :page-sizes="[15, 50, 75, 100, 200, 500]"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>

      <el-dialog
        :title="listLanguage.payment_add_notes"
        :visible.sync="dialogNote"
        width="30%"
        >
        <el-input v-model="note" :placeholder="listLanguage.payment_enter_notes" />
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogNote = false">{{ listLanguage.common_cancel }}</el-button>
          <el-button type="primary" @click="saveNote()">{{ listLanguage.common_add }}</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title="Sửa tiền ngoại tệ"
        :visible.sync="dialogPrice"
        width="30%"
        >
        <el-input v-model="price" placeholder="Nhập số tiền" />
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogPrice = false">Hủy bỏ</el-button>
          <el-button type="primary" @click="savePrice()">Cập nhật giá</el-button>
        </span>
      </el-dialog>

      <el-dialog
        :title="listLanguage.payment_want_to_cancel + '?'"
        :visible.sync="dialogCancel"
        width="30%"
        >
        <el-select style="width: 100%;" v-model="reason_cancel" :placeholder="listLanguage.payment_select_a_reason">
          <el-option
            v-for="(item, key) in listReason"
            :key="item.value"
            :label="item"
            :value="key">
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogCancel = false">{{ listLanguage.common_cancel }}</el-button>
          <el-button type="primary" @click="cancelPayment()">{{ listLanguage.common_confirm }}</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :title="listLanguage.payment_select_source"
        :visible.sync="dialogSourcePayment"
        width="30%"
        >
        <div>
          <span>{{ listLanguage.convert_from }}</span>
          <span style="float: right;">{{ listLanguage.transaction_surplus }}: <span class="money-overbalance">{{ overbalance | formatCurrency }}</span></span>
        </div>
        <el-select @change="changePayment()" style="width: 100%; margin-top: 10px;" v-model="selectPayment" :placeholder="listLanguage.payment_select_source">
          <el-option
            v-for="i in listSourcePayment"
            :key="i.value + Math.random(2)"
            :label="i.label"
            :value="i.code"
            >
          </el-option>
        </el-select>
          <p>{{ listLanguage.payment_amount }}:
            <span class="money-payment" v-if="selectPaymentVN && totalPriceVN >= 0">
              {{ totalPriceVN | vnd }}
            </span>
            <span class="money-payment" v-if="selectPaymentCN && totalPriceCN >= 0">
              {{ totalPriceCN | formatCurrency }}
            </span>
            <span class="money-payment" v-if="selectPaymentCN1688 && totalPriceCN1688 >= 0">
              {{ totalPriceCN | formatCurrency }}
            </span>
          </p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogSourcePayment = false">{{ listLanguage.common_cancel }}</el-button>
          <el-button v-if="loadingPayment" type="primary" @click.once="handlePayment()">{{ listLanguage.common_confirm }}</el-button>
          <el-button type="primary" v-else><i class="el-icon-loading"></i></el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="Tạo khiếu nại"
        :visible.sync="dialogComplain"
        width="40%"
        >
          <div class="d-flex flex-column item_create">
            <span>Mã đơn hàng</span>
            <el-input
              v-model="this.dataComplain.order_code"
              :disabled="true"
              class="pt-2"
            >
            </el-input>
          </div>
          <div class="d-flex flex-column item_create">
            <span>Hình ảnh</span>
<!--            <div class="upload_image" v-if="loadingImage">
              <i class="el-icon-loading"></i>
            </div>-->
            <div class="upload_image" @click="selectImageReceived()">
              <img src="@/static/icon/payment-request/upload.svg"  alt="upload-image"/>
            </div>
            <!-- // ds images  -->
            <div class="list_image">
              <div v-for="image in images" :key="image.key">
                <img id="preview" :src="image" alt="">
              </div>
            </div>
          </div>
          <div class="d-flex flex-column item_create">
            <span>Phương án <span style="color: red;">*</span></span>
            <el-select
              v-model="plain"
              placeholder="Chọn phương án"
              size="mini"
              class="pt-2"
            >
              <el-option
                v-for="item in optionPa"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="d-flex flex-column item_create">
            <span>Ghi chú</span>
            <el-input
              v-model="noteComplain"
              class="pt-2"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
            >
            </el-input>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogComplain = false">Đóng</el-button>
            <el-button
              type="primary"
              @click="handleSaveComplain()"
              :disable="!canSubmit"
              :loading="!canSubmit"
            >Gửi yêu cầu</el-button>
          </span>
      </el-dialog>

      <!-- up ảnh  -->
      <div style="display: none;">
        <input
          type="file"
          class="upload-avatar"
          id="fileReceived"
          ref="fileReceived"
          multiple
          v-on:change="handleFileUploadReceived()"
        />
      </div>

      <!-- // tạo đơn ký gửi -->
        <CreateConsignment
          :dialogCreateConsignment="dialogCreateConsignment"
          :createSelect="createSelect"
          @cancelCreate="cancelCreate"
          @addressDoneCreate="addressDoneCreate"
        />

      <!--// tạo đơn ký gửi nhiều đơn -->
      <CreateManyDeposit
        :dialogCreateMany="dialogCreateMany"
        @cancelCreateMany="cancelCreateMany"
        @doneCreateMany="doneCreateMany"
      ></CreateManyDeposit>

      <el-dialog
        title="Ghi chú"
        :visible.sync="dialogListNote"
        width="40%"
      >
        <el-table
          :data="noteShow"
          border
          style="width: 100%"
        >
          <el-table-column
            property="user_name"
            label="Người sửa"
            show-overflow-tooltip
            align="center"
          ></el-table-column>
          <el-table-column
            property="content"
            label="Nội dung"
            show-overflow-tooltip
            align="center"
          ></el-table-column>
          <el-table-column
            property="created_at"
            label="Ngày sửa"
            show-overflow-tooltip
            align="center"
          ></el-table-column>
        </el-table>
      </el-dialog>


      <!--      // pop-up tạo kiểu nại v2-->
      <FormCreateComplainV2
        :dataCreateComplain="dataCreateComplain"
          :dialogCreateComplain="dialogCreateComplain"
          @toCancelCreate="toCancelCreate"
          @toDoneCreate="toDoneCreate"
      ></FormCreateComplainV2>

    </div>
  </template>

  <script>
  import CreateConsignment from "@/views/payment_request/components/FormCreateConsignment";
  import CreateManyDeposit from "@/views/deposit/ManyCreate";
  import FormCreateComplainV2 from "@/views/complain_v2/Create";
  import ls from "local-storage";
  import EventBus from '@/EventBus'
  import axios from "axios";
  import { mapGetters, mapActions } from "vuex";
  import AuthLogin from "@/AuthLogin";
  export default {
    name: "TablePayment",
    props: {
      tableData: Array,
      listReason: Array,
      listSourcePayment: Array,
      pagination: Object,
      source: String,
      loadingApi: Boolean,
      filter: Object,
      flagCheckTab: Boolean
    },
    metaInfo() {
      return {
        title: "Yêu cầu thanh toán",
      };
    },
    components: {
      CreateConsignment,
      CreateManyDeposit,
      FormCreateComplainV2,
    },
    data() {
      return {
        checkedAll: false,
        checked: false,
        tab: 2,
        chatNcc: false,
          loadingImage: false,
          loadingExport : true,
          loadingPayment: true,
          multipleSelection: [],
          dialogNote: false,
          dialogPrice: false,
          dialogCancel: false,
          dialogPayment: false,
          dialogSourcePayment: false,
          dialogListNote: false,
          itemUpdate: {},
          note: '',
          noteComplain: '',
          images: [],
          price: '',
          reason_cancel: '',
          selectPayment: '',
          selectPaymentCN: false,
          selectPaymentCN1688: false,
          selectPaymentVN: false,
          pricePayment: '',
          totalPriceVN: 0,
          totalPriceCN: 0,
          totalPriceCN1688: 0,
          overbalance: 0,
          dataPayment: [],
          dataComplain: {},
          dialogComplain: false,

          canSubmit: true,
          plain: "",
          optionPa: [
          {
            name: "Bồi thường",
            value: 1,
          },
          {
            name: "Phát bù",
            value: 2,
          }
        ],
        srcImage: "",
        imagesPush: [],
        imageUpload: [],

        // tổng số tiền khi ấn checkbox bảng
        total_money_vnd: 0,
        total_money_te: 0,
        // tổng số tiền khi ấn chọn trạng thái đã gửi thanh toán
        checkStatus: '',
        dataStatus: [],
        total_money_vnd_status: 0,
        total_money_te_status: 0,

        // tạo đơn ký gủi
        dialogCreateConsignment: false,
        createSelect: [],
        titleTable: "Ngày tạo",
        noteShow: [],
          userLogin: [],

        dialogCreateMany: false,
        // phần tạo khiếu nại v2
        dialogCreateComplain: false,
        dataCreateComplain: {
          order_code: "",
          source_id: "",
          source_name: "",
          id: "",
        },
      };
    },
    computed: {
      ...mapGetters("account", ["logged", "accountInfo", "listLanguage"]),
    },
    mounted() {
      EventBus.$on('statusSelect', this.handler);
      setTimeout(() => {
        this.txtLang = this.listLanguage;
      }, 500)
      EventBus.$on('handTitle', this.handlerObj);
      this.userLogin = AuthLogin.user
    },
    methods: {
      tabTable(tab) {
        this.tab = tab
        this.$emit('tab', this.tab);
      },

      // phần tao đơn ký gửi nhiều đơn
      updateStatusAll() {
        this.loadingPayment = false
        axios.post("/request_payment/update-status",
          {
            'ids': this.dataPayment,
            'status': 'cancel'
          })
          .then((response) => {
            this.loading = false;
            this.loadingPayment = true
            switch (response.code) {
              case 200:
                this.$emit('fetchList', true);
                this.$emit('fetchStatusTable', true);
                this.$emit('fetchNoti', true);
                this.$notify({
                  type: 'success',
                  message: response.message
                });
                break;
              case 422:
                this.$notify({
                  title: "Đơn hàng chuyển trạng thái chưa thành công",
                  message: Object.values(response.data)[0],
                  type: "warning",
                });
                break;
              default:
                this.$notify.error({
                  title: "Error",
                  message: response.message,
                });
                break;
            }
          })
          .catch( errors => {
            this.$notify.error({
              title: "Error",
              message: errors.message,
            });
          });
      },
      cancelMultiple() {
        let data = [];
        this.multipleSelection.forEach(row => {
          if(row.status === 'waiting-processing' || row.status === 'waiting-payment-sent') {
            data.push(row.id);
          }
        });
        if(data.length === 0) {
          this.$notify({
            title: "Cảnh báo",
            message: "Vui lòng chọn đơn hàng cần hủy",
            type: "warning",
          });
          return;
        }
        this.dataPayment = data;
        this.$confirm('Bạn có chắc muốn hủy các đơn hàng đã chọn?', 'Cảnh báo', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning'
        }).then(() => {
          this.updateStatusAll();
        }).catch(() => {

        });
      },
      manyCreateDeposit() {
        this.dialogCreateMany = true;
      },
      cancelCreateMany() {
        this.dialogCreateMany = false;
      },
      doneCreateMany() {
        this.dialogCreateMany = false;
        this.$emit('fetchList', true);
      },
      changeStatus(type, item){
          const data = [];
          if(type === 'multiple') {
              this.multipleSelection.forEach(row => {
                  if(row.status === 'waiting-processing') {
                      data.push(row.id);
                  }
              });
              if(data.length === 0) {
                  this.$notify({
                      title: "Cảnh báo",
                      message: "Vui lòng chọn đơn hàng đang ở trạng thái Chờ xử lý để chuyển trạng thái Chờ gửi thanh toán",
                      type: "warning",
                  });
                  return;
              }
               this.dataChangeStatus = data;
          } else{
              this.$refs.multipleTable.clearSelection();
              this.dataChangeStatus = [item];
          }
        this.loadingPayment = false
        axios.post("/request_payment/update-status",
          {
            'ids': this.dataChangeStatus,
            'status': 'waiting-payment-sent'
          })
          .then((response) => {
            this.loading = false;
            this.loadingPayment = true
            switch (response.code) {
              case 200:
                this.$emit('fetchList', true);
                this.$emit('fetchStatusTable', true);
                this.$emit('fetchNoti', true);
                this.$notify({
                  type: 'success',
                  message: response.message
                });
                break;
              case 422:
                this.$notify({
                  title: "Đơn hàng chuyển trạng thái chưa thành công",
                  message: Object.values(response.data)[0],
                  type: "warning",
                });
                break;
              default:
                this.$notify.error({
                  title: "Error",
                  message: response.message,
                });
                break;
            }
          })
          .catch( errors => {
            this.$notify.error({
                title: "Error",
                message: errors.message,
            });
          });
      },
    // phần tạo đơn ký gửi
    cancelCreate() {
      this.dialogCreateConsignment = false;
    },
    showCheckPrice() {
        let data = [];
        if(this.tab == 2) {
          this.multipleSelection.forEach(row => {
            if(row.status === 'waiting-processing' || row.status === 'refuse' || row.status === 'waiting-payment-sent') {
              data.push(row.id);
            }
          });
        }
        if(this.tab == 1) {
          this.tableData.forEach(row => {
            if(row.status === 'waiting-processing' || row.status === 'refuse' || row.status === 'waiting-payment-sent') {
              if(row.checked) {
                data.push(row.id);
              }
            }
          });
        }

        if(!this.source) {
          this.$notify({
            title: "Cảnh báo",
            message: "Vui lòng chọn nguồn",
            type: "warning",
          });
          return;
        }
        if(data.length === 0) {
          this.$notify({
            title: "Cảnh báo",
            message: "Vui lòng chọn đơn hàng cần kiểm tra giá",
            type: "warning",
          });
          return;
        }
        let count = data.length;
        let msg = 'Check giá ' + count + ' đơn hàng đã chọn?';
        this.$confirm(msg, '', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning'
        }).then(() => {
          this.$emit('handleCheck', data, this.source)
        }).catch(() => {
        });
      },
    addressDoneCreate() {
      this.dialogCreateConsignment = false;
      this.$emit('fetchList', true);
    },
    selectAll() {
      if(this.checkedAll == true) {
        this.tableData.forEach((item) => {
          if(item.status == 'waiting-processing' || item.status == 'refuse' || item.status == 'waiting-payment-sent') {
            item.checked = true;
          }
        })
      } else {
        this.tableData.forEach((item) => {
          item.checked = false;
        })
      }
    },
    showChatNCC(supplier) {
      let data = {
        sourceType: "order",
        bizId: supplier.items[0].taobao_purchase_id,
      }
      this.chatNcc = true;
      axios.post("/user/get-iframe-chat-taobao", data).then((response) => {
        this.chatNcc = false;
        switch (response.code) {
          case 200:
            window.open(response.data.link);
            break;
          case 400:
            this.$notify({
              title: "Thất bại",
              message: response.errors[0],
              type: "warning",
            });
            break;
          case 422:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: Object.values(response.data)[0],
              type: "warning",
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    handleCreateConsignment(row) {
      this.createSelect = [row];
      this.createSelect.map(function (i) {
        i.note = "";
        i.package_number = 1;
        i.is_woodworking = false;
        i.is_shock = false;
        i.is_inspection = false;
        i.is_insurance = false;
        return i;
      });
      this.createSelect = JSON.parse(JSON.stringify(this.createSelect));
      this.dialogCreateConsignment = true;
    },
      // phần tạo khiếu lại v2
      toCreateComplain(item) {
        this.dataCreateComplain = {
          order_code: item.order_code,
          source_id: item.source_id,
          source_name: item.source_name,
          id: item.id,
        };
        console.log(this.dataCreateComplain);
        this.dialogCreateComplain = true;
      },
      toCancelCreate() {
        this.dialogCreateComplain = false;
      },
      toDoneCreate() {
        this.dialogCreateComplain = false;
      },
      // --------------------------------------------
      handlerObj(e) {
        if(e == 2) {
          this.titleTable = 'Ngày thanh toán'
        } else {
          this.titleTable = 'Ngày tạo'
        }
      },
    // up load ảnh
    selectImageReceived() {
      document.getElementById("fileReceived").click();
    },
    handleFileUploadReceived() {
      this.file = this.$refs.fileReceived.files[0];
      let file = this.file;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("Dung lượng file tối đa là 2M");
        return;
      }
      let typeFile = file["type"];
      let arr_type = [
        "image/jpeg",
        "image/png",
        "image/jpg",
      ];
      if (!arr_type.includes(typeFile)) {
        this.$message.error("Chỉ được upload file ảnh");
        return;
      }
      if (this.imageUpload.length + 1 > 5) {
        this.$message.error("Bạn không được tải quá 5 ảnh!");
        return;
      }
      const [image] = this.$refs.fileReceived.files;
      if (image) {
        this.srcImage = URL.createObjectURL(image);
        this.images.push(this.srcImage);
      }
      for (let file of this.$refs.fileReceived.files) {
        this.imageUpload.push(file);
      }
      /*this.submitFileReceived();*/
    },
    async submitFileReceived() {
      this.loadingImage = true;
      let formData = new FormData();
      formData.append("image", this.file);
      let headers = {
        'Authorization': 'Bearer ' + ls.get("token"),
      }
      fetch(process.env.VUE_APP_API_URL + "complain/upload-image", {
        method: "POST",
        body: formData,
        headers: headers,
      })
        .then((response) => response.json())
        .then((result) => {
          this.loadingImage = false;
          this.imagesPush.push(result.data);
        })
        .catch(() => {
          this.$notify.error({
            title: "Error",
            message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
          });
        });
    },
      ...mapActions("account", ["getAccountInfo", "getLanguage"]),
    //
    handler(e) {
      this.checkStatus = e;
    },
    exportExcel(){
      let search = {
          source_id: this.filter.source_id,
          user_id: this.filter.user_id,
          order_code: this.filter.order_code,
          status: this.filter.status,
          from_created_at: this.filter.from_created_at,
          to_created_at: this.filter.to_created_at,
          user_name_payment: this.filter.user_name_payment,
          type_fund: this.filter.type_fund
      }
      axios.get('/request_payment/export-excel', {
        params: search,
        responseType: 'blob'
      }).then((response) => {
        if (response) {
          this.loadingExport = false;
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download","excel-yeu-cau-thanh-toan.xlsx");
          document.body.appendChild(link);
          link.click();
        }
      })
    },
    handleSizeChange(val) {
      // eslint-disable-next-line vue/no-mutating-props
      this.filter.per_page = val;
      this.$emit('fetchList', true);
    },
      canSelectRow(row) {
        return (row.status === 'waiting-processing' ||  row.status === 'refuse' || row.status === 'waiting-payment-sent')
      },
      // Thay đổi Current Page
      changeCurrentPage(currentPage) {
        // eslint-disable-next-line vue/no-mutating-props
        this.filter.page = currentPage;
        this.$emit('fetchList', true);
        document.getElementById("ST2023").scrollIntoView({ behavior: "smooth" });
      },
      changePayment() {
        if(this.selectPayment === 'VN') {
          for(var i in this.listSourcePayment) {
            if(this.listSourcePayment[i].code === 'VN') {
              this.overbalance = this.listSourcePayment[i].value;
            }
          }
          this.selectPaymentVN = true;
          this.selectPaymentCN = false;
          this.selectPaymentCN1688 = false;
        } else if(this.selectPayment === 'CN'){
          for(var j in this.listSourcePayment) {
            if(this.listSourcePayment[j].code === 'CN') {
              this.overbalance = this.listSourcePayment[j].value;
            }
          }
          this.selectPaymentCN = true;
          this.selectPaymentVN = false;
          this.selectPaymentCN1688 = false;
        } else if(this.selectPayment === 'CN1688'){
          for(var k in this.listSourcePayment) {
            if(this.listSourcePayment[k].code === 'CN1688') {
              this.overbalance = this.listSourcePayment[k].value;
            }
          }
          this.selectPaymentCN1688 = true;
          this.selectPaymentCN = false;
          this.selectPaymentVN = false;
        }
      },
      handlePaymentFast(type, item) {
        let data = [];
        let totalPriceVN = 0;
        let totalPriceCN = 0;
        let totalPriceCN1688 = 0;
        if(type === 'multiple' && this.tab == 2) {
          this.multipleSelection.forEach(row => {
            if(row.status === 'waiting-processing' || row.status === 'refuse' || row.status === 'waiting-payment-sent') {
              data.push(row);
              totalPriceVN += parseFloat(row.total_payment_vn) > 0 ? parseFloat(row.total_payment_vn) : parseFloat(row.transaction_value);
              totalPriceCN += parseFloat(row.total_payment_cn) > 0 ? parseFloat(row.total_payment_cn) : parseFloat(row.foreign_currency);
              totalPriceCN1688 += parseFloat(row.total_payment_cn) > 0 ? parseFloat(row.total_payment_cn) : parseFloat(row.foreign_currency);
            }
          });
          if(data.length === 0) {
            this.$notify({
              title: "Cảnh báo",
              message: "Vui lòng chọn đơn hàng cần thanh toán",
              type: "warning",
            });
            return;
          }
          this.dataPayment = data;
        } else if (type === 'multiple' && this.tab == 1) {
          this.tableData.forEach(item => {
              if(item.checked) {
                data.push(item);
                totalPriceVN += parseFloat(item.total_payment_vn) > 0 ? parseFloat(item.total_payment_vn) : parseFloat(item.transaction_value);
                totalPriceCN += parseFloat(item.total_payment_cn) > 0 ? parseFloat(item.total_payment_cn) : parseFloat(item.foreign_currency);
                totalPriceCN1688 += parseFloat(item.total_payment_cn) > 0 ? parseFloat(item.total_payment_cn) : parseFloat(item.foreign_currency);
              }
          });
          this.dataPayment = data;
        } else {
          if(type == 'single') {
            this.$refs.multipleTable.clearSelection();
          }
          totalPriceVN = item.total_payment_vn > 0 ? item.total_payment_vn : item.transaction_value;
          totalPriceCN = item.total_payment_cn > 0 ? item.total_payment_cn : item.foreign_currency;
          totalPriceCN1688 = item.total_payment_cn > 0 ? item.total_payment_cn : item.foreign_currency;
          this.dataPayment = [item];
        }
        this.totalPriceVN = totalPriceVN;
        this.totalPriceCN = totalPriceCN;
        this.totalPriceCN1688 = totalPriceCN1688;
        this.itemUpdate = item;
        this.dialogSourcePayment = true;
        this.selectPaymentVN = false;
        this.selectPaymentCN = false;
        this.selectPaymentCN1688 = false;
        this.selectPayment = '';
        this.overbalance = 0;
      },
      handleComplain(item) {
        this.dataComplain = item;
        this.images = [];
        this.imagesPush = [];
        this.dialogComplain = true;
      },
      handleSaveComplain() {
        if (this.plain === '') {
            this.$notify({
              title: "Có lỗi xảy ra",
              message: "Bạn chưa chọn phương án",
              type: "warning",
            });
            return;
        }
        this.canSubmit = false;
        console.log(this.imageUpload)
        let formData = new FormData();
        for(let i = 0; i < this.imageUpload.length; i++){
          formData.append('images[' + i + ']', this.imageUpload[i]);
        }
        formData.append("plain", this.plain);
        formData.append("id", this.dataComplain.id);
        formData.append("note", this.noteComplain);
        axios.post("/request_payment/create-complain", formData,{
              headers: {
                'Content-Type': 'multipart/form-data'
              }
        }).then((response) => {
          this.imageUpload = [];
          this.loading = false;
          this.canSubmit = true;
          this.dialogSourcePayment = false;
          switch (response.code) {
            case 200:
              this.$notify({
                type: 'success',
                message: 'Đã gửi khiếu nại thành công'
              });
              this.dialogComplain = false
              break;
            case 422:
              this.$notify({
                title: "có lỗi xảy ra",
                message: Object.values(response.data)[0],
                type: "warning",
              });
              break;
            case 500:
              this.$notify({
                title: "có lỗi xảy ra",
                message: response.message,
                type: "warning",
              });
              break;
            default:
              this.$notify.error({
                title: "Có lỗi xảy ra",
                message: "Vui lòng liên hệ Admin để khắc phục",
              });
              break;
          }
        });
      },
      handlePayment() {
        if(this.selectPayment == '') {
          this.$notify({
              title: "Cảnh báo",
              message: "Vui lòng chọn nguồn cần thanh toán",
              type: "warning",
            });
            return;
        }
        if (this.loadingPayment === false) {
          return;
        }
        this.loadingPayment = false
        axios.post("/request_payment/create-from-payment",
          {
            'data': this.dataPayment,
            'totalPriceVN': this.totalPriceVN,
            'totalPriceCN': this.totalPriceCN,
            'totalPriceCN1688': this.totalPriceCN1688,
            'sourceType': this.selectPayment,
            'overbalance': this.overbalance
          })
        .then((response) => {
          this.loading = false;
          this.loadingPayment = true
          this.dialogSourcePayment = false;
          switch (response.code) {
            case 200:
              this.$emit('fetchList', true);
              this.$emit('fetchStatusTable', true);
              this.$emit('fetchNoti', true);
              this.$notify({
                type: 'success',
                message: response.message
              });
              break;
            case 422:
              this.$notify({
                title: "Đơn hàng thanh toán chưa thành công",
                message: Object.values(response.data)[0],
                type: "warning",
              });
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: response.message,
              });
              break;
          }
        });
      },
      handleSelectionChange(rows) {
        if (rows) {
          rows.forEach(row => {
            if(row.status !== 'waiting-processing' &&  row.status !== 'refuse' && row.status !== 'waiting-payment-sent') {
              this.$refs.multipleTable.toggleRowSelection(row);
            }
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
        console.log('rows', rows);
        this.multipleSelection = rows;

        // Hiện thị tổng ố tiền khi ấn check box
        this.total_money_vnd = 0;
        this.total_money_te = 0;
        rows.forEach((row) => {
          if(row.status === 'waiting-processing' || row.status === 'refuse' || row.status === 'waiting-payment-sent') {
            this.total_money_vnd +=  parseFloat(row.total_payment_vn) > 0 ? parseFloat(row.total_payment_vn) : parseFloat(row.transaction_value);
            this.total_money_te += parseFloat(row.total_payment_cn) > 0 ? parseFloat(row.total_payment_cn) : parseFloat(row.foreign_currency);
          }
        })
      },
      updateNote(item) {
        this.dialogNote = true;
        this.note = '';
        this.itemUpdate = item;
      },
      saveNote() {
        if(!this.note.trim()) {
          this.$notify({
            title: "Lỗi dữ liệu nhập",
            message: "Vui lòng nhập ghi chú",
            type: "warning",
          });
          return false;
        }
        this.update(this.itemUpdate, {note: this.note});
        this.dialogNote = false
      },
      handleCancel(item) {
        this.dialogCancel = true;
        this.itemUpdate = item;
      },
      cancelPayment() {
        if(this.reason_cancel === '') {
          this.$notify({
            title: "Lỗi",
            message: "Vui lòng chọn lý do hủy thanh toán",
            type: "warning",
          });
          return;
        }
        this.update(this.itemUpdate, {status: 'cancel', reason_cancel: this.reason_cancel});
        this.dialogCancel = false
      },
      update(item, field) {
        axios.post("/request_payment/update/" + item.id, field).then((response) => {
          this.loading = false;
          switch (response.code) {
            case 200:
              this.$emit('fetchList', true);
              if(field.status) {
                this.$emit('fetchStatusTable', true);
                this.$notify({
                  type: 'success',
                  message: 'Hủy bỏ thành công'
                });
              }
              if(field.note) {
                this.$notify({
                  type: 'success',
                  message: 'Cập nhật ghi chú thành công'
                });
              }
              if(field.foreign_currency) {
                this.$notify({
                  type: 'success',
                  message: 'Cập nhật giá tệ thành công'
                });
              }
              break;
            case 500:
              this.$notify({
                type: 'error',
                message: response.message
              });
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },
      gotoDetail(id) {
        this.$emit('detail', id);
      },
      updatePrice(item) {
        this.price = item.foreign_currency;
        this.itemUpdate = item;
        this.dialogPrice = true;
      },
      savePrice() {
        this.update(this.itemUpdate, {foreign_currency: this.price});
        this.dialogPrice = false
      },
      showListNote(notes) {
        this.noteShow       = notes;
        this.dialogListNote = true;
      }
    },
    watch: {
      tableData:function() {
        this.total_money_vnd_status = 0;
        this.total_money_te_status = 0;
        let total_money_te = 0;
        let total_money_vnd = 0;
        this.tableData.forEach((item) => {
          if(item.status == 'payment-sent') {
            total_money_vnd += parseFloat(item.transaction_value);
            total_money_te += parseFloat(item.foreign_currency);
          }
        });
        this.total_money_vnd_status = total_money_vnd.toFixed(2);
        this.total_money_te_status = total_money_te.toFixed(2);
      }
    },
  };
  </script>
<style lang="css" scoped src="@/static/css/request_payment/list.css">
</style>
<style>
  .step_main .el-step__title {
    color: #2196F3 !important;
    font-weight: 600;
  }
  .step_main .el-step__description {
    color: #000 !important;
  }
  .step_main {
    color: #2196F3 !important;
  }
</style>
<style>
@media only screen and (max-width: 768px), (max-width: 1023px) {
  .el-message-box {
    width: auto !important;
  }
}
</style>
