<template>
  <div class="product-detail">
    <el-row :gutter="32">
      <el-col :span="8">
        <el-card :body-style="{ padding: '0px' }" shadow="hover">
          <img :src="activeThumbnail" class="image" />
          <div style="padding: 14px">
            <div
              class="d-flex align-items-center justify-content-center listPicbottom"
            >
              <div
                v-for="(thumb, thumbIndex) in productDetail.productImage"
                :key="thumbIndex"
                class="mr-2"
              >
                <img
                  @click="setCurrentThumbnail(thumb)"
                  :src="thumb"
                  alt="Image Thumbnail"
                />
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="16">
        <div class="d-flex flex-column">
          <div class="title-header-product">
            <el-link
              :href="productDetail.itemUrl"
              target="_blank"
              class="title-product"
            >
              {{ productDetail.subjectTrans }}
            </el-link>
          </div>
          <div class="shop pd-0-12">
            <div class="d-flex align-items-center">

              <span class="title-normal"
                >Sản phẩm của shop: {{ productDetail.sellerNickName }}</span
              >
              <span class="title-header-product">{{
                productDetail.shopName
              }}</span>

              <div class="d-flex align-items-center pl-2 txt_chat_ncc" @click="showChatNCC()">
                <img v-if="chatNcc == false" src="@/static/icon/detail-search/chat.svg" alt="" />
                <i v-else class="el-icon-loading"></i>
                <span class="pl-2">Chat với ncc</span>
              </div>

            </div>

            <div style="color: red; padding-top: 5px; font-style: italic">
              (Lưu ý*: Mua tối thiểu {{ productDetail.minOrderQuantity }} sản
              phẩm)
            </div>
          </div>
          <div
            class="d-flex pl-3"
            style="line-height: 30px; background-color: rgb(249, 249, 250)"
          >
            <div class="price-content">
              <div class="item-price">
                <span>Giá CNY</span>
                <span>Giá VND</span>
                <span>Số lượng tối thiểu</span>
              </div>
            </div>
            <div
              class="price-content"
              v-for="(value, key) in productDetail.productSaleInfo.priceRangeList"
              :key="key"
            >
              <div class="item-price">
                <span class="price-product-item">¥ {{ value.price }}</span>
                <span class="price-product-item">{{
                  (value.price * 3500) | vnd
                }}</span>
                <span>{{ value.startQuantity }} sản phẩm</span>
              </div>
            </div>
          </div>
          <template>
            <div v-if="productDetail.skuAttributes">
              <template v-if="Object.keys(productDetail.skuAttributes).length == 2">
                <el-row
                  class="pd-0-12 d-flex"
                  v-for="(item, index) in productDetail.skuAttributes"
                  :key="index"
                >
                  <template v-if="Object.values(item)[0].skuImageUrl != ''">
                    <el-col :span="4">
                      <span class="title-header-product">{{ index }}</span>
                    </el-col>
                    <el-col :span="20" v-model="actives">
                      <span
                        v-for="(color, propIndex) in listColor"
                        :key="propIndex"
                        @click="handleActiveProp(propIndex)"
                        style="display: inline-block"
                        class="p-0-2 mb-2"
                      >
                        <span
                        style="position: relative;"
                          class="property-image"

                          ref="prop"
                          :id="key"
                          :class="{
                            active: color.isActive,
                          }"
                        >
                          <span class="image-sku">
                            <img :src="color.image" class="item-image" />
                          </span>
                          <span>{{ color.value }}</span>
                          <span class="prop-item-total" v-if="color.quantity > 0">
                            x{{ color.quantity }}
                          </span>
                        </span>
                      </span>
                    </el-col>
                  </template>
                  <template v-else>
                    <el-col :span="4">
                      <span class="title-header-product">{{ index }}</span>
                    </el-col>
                    <el-col :span="20">
                      <div class="d-flex" style="flex-direction: column">
                        <div
                          style="
                            line-height: 20px;
                            border-top: 1px dashed #e9e9e9;
                            padding: 10px 0;
                            display: flex;
                          "
                          v-for="(size, key) in listSizes"
                          :key="key"
                        >
                          <span style="min-width: 200px">{{ size.label }}</span>
                          <span
                          style="align-self: center;"
                            >Có sẵn
                            <b style="color: #1d60a6">{{ size.inventory }}</b> sản
                            phẩm</span
                          >
                          <div style="margin-left: auto">
                            <el-input-number
                              v-model="size.quantity"
                              :min="0"
                              :max="size.inventory"
                              size="mini"
                              @change="changeQuantity()"
                            />
                          </div>
                        </div>
                      </div>
                    </el-col>
                  </template>
                </el-row>
              </template>
              <template v-else>
                <el-row
                  class="pd-0-12 d-flex"
                  v-for="(pro, key) in properties"
                  :key="key"
                >
                  <el-col :span="4">
                    <span class="title-header-product">{{ key }}</span>
                  </el-col>
                  <el-col :span="20">
                    <div class="d-flex" style="flex-direction: column">
                      <div
                        style="
                          line-height: 20px;
                          border-top: 1px dashed #e9e9e9;
                          padding: 10px 0;
                          display: flex;
                        "
                        v-for="(item, key) in pro"
                        :key="key"
                      >

                        <div class="listProp_item listProp_item_color" style="min-width: 200px">
                          <img :src="item.image" v-if="item.image" style="height: 100%;
                          aspect-ratio: 1/1;
                          -o-object-fit: cover;
                          object-fit: cover;
                          margin-right: 8px;" />
                          <span>{{ item.label }}</span>
                        </div>
                        <span
                        style="align-self: center;"
                          >Có sẵn
                          <b style="color: #1d60a6">{{ item.inventory }}</b> sản
                          phẩm</span
                        >
                        <div style="margin-left: auto">
                          <el-input-number
                            v-model="item.quantity"
                            :min="0"
                            :max="item.inventory"
                            size="mini"
                            @change="changeQuantity()"
                          />
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </template>
            </div>
            <div class="price-quantity" v-if="totalProduct > 0">
              <div>Số lượng mua: <b style="color: #26b01c; font-size: 16px;">{{ totalProduct }}</b></div>
              <div>Tổng giá tiền: <b style="color: #ff4000; font-size: 16px;">{{ (priceProduct * totalProduct * 3500) | vnd }}</b></div>
            </div>
            <el-row class="pd-0-12">
              <el-col :span="4">
                <p></p>
              </el-col>
              <el-col :span="20">
                <el-button class="btn-add-cart" @click="addToCart()"
                  >THÊM VÀO GIỎ HÀNG</el-button
                >
                <el-button class="btn-order" @click="orderNowConfirm()"
                :loading="!canSubmit"
                  >ĐẶT HÀNG NGAY</el-button
                >
              </el-col>
            </el-row>
          </template>
        </div>
      </el-col>
    </el-row>

    <!-- // địa chỉ nhạn hàng  -->
    <Address
      :dialogAddress="dialogAddress"
      :source="'1688'"
      @cancel="cancelAddress"
      @addressDone="addressDone"
    />

  </div>
</template>

<script>
import Address from "@/views/shopping/addressCN/Form";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "ShoppingProductDetail1688",
  props: {
    productDetail: Object,
    activeThumbnail: String,
  },
  metaInfo() {
    return {
      title: "Thông tin",
    };
  },
  components: {
    Address,
  },
  data() {
    return {
      loading: true,
      canSubmit: true,
      chatNcc: false,
      dialogAddress: false,
      quantityNumber: 1,
      listThumbnail: [],
      isUpdatingNumber: false,
      activeThumb: this.activeThumbnail,
      itemSizeSelected: "",
      actives: [],
      isSelected: [],
      arrayUniqueByKey: [],
      arrayItemSku: [],
      arrayValueIdFilter: [],
      arrayKey: [],
      skuItem: {
        title: "",
        mpSkuId: "",
        quantity: 1,
        mpId: "",
        price: 0,
        picUrl: "",
        valueName: "",
        quantity_in_stock: 0,
        supplier: ""
      },
      listSize: [],
      listColor: [],
      listSizes: [],
      totalProduct: 0,
      priceProduct: 0,
      properties: [],
      receiver: ''
    };
  },
  computed: {
    ...mapGetters("account", ["logged"]),
  },
  created() {

  },
  mounted() {
  },
  methods: {
    // địa chỉ nhận hàng
    cancelAddress() {
        this.dialogAddress = false;
      },
      addressDone(data) {
        this.receiver = {
          address: data.address,
          mobile: data.mobile_phone,
          fullName: data.name,
          postCode: data.zip,
          areaText: data.district,
          cityText: data.city,
          provinceText: data.city,
          country: data.country,
          shipping_address_id: data.shipping_address_id,
          status: data.status
        };
        this.dialogAddress = false;
        this.orderNow(this.productDetail);
      },
    // chat vs ncc
      showChatNCC() {
        this.chatNcc = true;
        let data = {
          offerId: this.productDetail.offerId,
          openUid: this.productDetail.sellerOpenId,
        }
        axios.post("/user/get-iframe-chat-1688", data).then((response) => {
          this.chatNcc = false;
          switch (response.code) {
            case 200:
              window.open(response.data.link);
              break;
            case 400:
              this.$notify({
                title: "Thất bại",
                message: response.errors[0],
                type: "warning",
              });
              break;
            case 422:
              this.$notify({
                title: "Có lỗi xảy ra",
                message: Object.values(response.data)[0],
                type: "warning",
              });
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },
    ...mapActions("account", ["getAccountInfo"]),
    async formatData() {
      this.totalProduct = 0;
      let listColor = [];
      if(Object.keys(this.productDetail.skuAttributes).length === 2){
        Object.keys(this.productDetail.skuAttributes).forEach((skuAttr) => {
            Object.values(this.productDetail.skuAttributes[skuAttr]).forEach(
              (item) => {
                if(item.skuImageUrl != ''){
                  listColor.push({
                    image: item.skuImageUrl,
                    value: item.value,
                    quantity: 0,
                    isActive: false,
                  });
                }
              }
            );
        });
        listColor = listColor.map((color) => {
          let listSize = [];
          this.productDetail.dataMapProductSkuInfos.map((sku) => {
            let valueCheck = sku.keyItem.filter((value) => {
              return value == color.value;
            });
            if (valueCheck.length > 0) {
              sku.keyItem.forEach((i) => {
                if (i != color.value) {
                  listSize.push({
                    label: i,
                    inventory: parseInt(sku.amountOnSale),
                    quantity: 0,
                  });
                }
              });
            }
          });
          color.sizes = listSize;
          return color;
        });
        this.listColor = listColor;
        this.priceProduct = this.productDetail.productSaleInfo.priceRangeList[0].price
        this.handleActiveProp(0);
      }else{

        Object.keys(this.productDetail.skuAttributes).forEach(key => {
          let properties = [];
          Object.values(this.productDetail.skuAttributes[key]).forEach(sku => {
            properties.push({
              image: sku.skuImageUrl,
              label: sku.value,
              quantity: 0
            });
          });
          properties.map(pro => {
            this.productDetail.dataMapProductSkuInfos.forEach((sku) => {
              let valueCheck = sku.keyItem.filter((value) => {
                return value == pro.label;
              });
              if (valueCheck.length > 0) {
                pro.inventory = parseInt(sku.amountOnSale);
                pro.offerId = sku.offerId;
                pro.skuId = sku.skuId;
                pro.specId = sku.specId;
              }
            });
            return pro;
          })
          let obj = {};
          obj[key] = properties;
          this.properties = obj;
        })
      }

    },
    handleActiveProp(index) {
      this.listColor.forEach((item, key) => {
        if (index === key) {
          item.isActive = true;
          this.listSizes = item.sizes;
          this.setCurrentThumbnail(item.image);
        } else {
          item.isActive = false;
        }
      });
    },
    async changeQuantity () {
      let totalQuantity = 0;
      if(Object.keys(this.productDetail.skuAttributes).length == 2){
        this.listColor.forEach(data => {
          let quantity = 0;
          data.sizes.forEach(size => {
              quantity += size.quantity;
              totalQuantity += size.quantity
          })
          data.quantity = quantity;
        })
      }else{
        Object.values(this.properties).forEach(pro => {
          pro.forEach(i => {
            totalQuantity += i.quantity;
          })
        })
      }
      this.totalProduct = totalQuantity;
      this.productDetail.productSaleInfo.priceRangeList.forEach(range => {
        if(totalQuantity >= range.startQuantity){
          this.priceProduct = range.price;
        }
      })
    },

    getDataOrder() {
      let data = [];
      if(Object.keys(this.productDetail.skuAttributes).length == 2){
        this.listColor.forEach(color => {
          if(color.quantity > 0){
            color.sizes.forEach(item => {
              if(item.quantity > 0){
                this.productDetail.dataMapProductSkuInfos.forEach(sku => {
                  if(sku.keyItem.join(';') == `${color.value};${item.label}` || sku.keyItem.join(';') == `${item.label};${color.value}`){
                    data.push({
                      mpSkuId: sku.specId, /* id mua hang san pham con,  ở bên 1688 là specId */
                      mpId: sku.offerId, /* id mua hang san pham cha, ở bên 1688 là offerId */
                      title: this.productDetail.subjectTrans,
                      quantity: item.quantity,
                      price: this.priceProduct,
                      picUrl: color.image,
                      options: 7,
                      source : '1688',
                      valueName: sku.keyItem,
                      supplier: {
                        'id': this.productDetail.sellerOpenId,
                        'name': this.productDetail.sellerNickName,
                        'url': this.productDetail.sellerShopUrl
                      }
                    })
                  }
                })
              }
            })
          }
        })
      }else{
        Object.values(this.properties).forEach(pro => {
          pro.forEach(item => {
            if(item.quantity > 0){
              data.push({
                mpSkuId: item.specId, /* id mua hang san pham con,  ở bên 1688 là specId */
                mpId: item.offerId, /* id mua hang san pham cha, ở bên 1688 là offerId */
                title: this.productDetail.subjectTrans,
                quantity: item.quantity,
                price: this.priceProduct,
                picUrl: item.image,
                options: 7,
                source : '1688',
                valueName: [item.label],
                supplier: {
                  'id': this.productDetail.sellerOpenId,
                  'name': this.productDetail.sellerNickName,
                  'url': this.productDetail.sellerShopUrl
                }
              })
            }
          })
        })
      }
      return data;
    },

    addToCart() {
      if (!this.validateBeforeOrder()) {
        return;
      }
      let data = this.getDataOrder();
      console.log(data, 'data')

      this.loading = true;
      axios
        .post("/cart/product/add-to-cart", data)
        .then((response) => {
          this.loading = false;
          switch (response.code) {
            case 200:
              this.$notify({
                title: "Thành công",
                message: "Thêm sản phẩm vào giỏ hàng thành công",
                type: "success",
              });
              this.formatData();
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        })
        .catch(() => {
          this.$notify.error({
            title: "Error",
            message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
          });
        });
    },
    validateBeforeOrder() {
      if (this.totalProduct < this.productDetail.minOrderQuantity) {
        this.$notify.error({
          title: "Thông báo",
          message: "Số lượng mua phải lớn hơn số lượng tối thiếu",
        });
        return false;
      }
      return true;
    },
    setCurrentThumbnail(url) {
      this.$emit("changeThumbnail", url);
    },
    setMoneyRelatedTerms() {},
    orderNowConfirm() {
      this.dialogAddress = true;
    },
    orderNow() {
      if (!this.validateBeforeOrder()) {
        return;
      }
      this.canSubmit = false;
      let data = this.getDataOrder();
      data = data.map(item => {
        return {
          specId: item.mpSkuId,
          quantity: item.quantity,
          offerId: item.mpId,
          supplier: {
            'id': this.productDetail.sellerOpenId,
            'name': this.productDetail.sellerNickName,
            'url': this.productDetail.sellerShopUrl
          }
        }
      })
      axios
        .post("order/store-1688", {items: data, receiver: this.receiver, sourceType: 'xadal'})
        .then((response) => {
          this.loading = false;
          this.canSubmit = true;
          switch (response.code) {
            case 200:
              this.$notify({
                title: "Thành công",
                message: "Đặt hàng thành công",
                type: "success",
              });
              this.formatData();
              break;
            default:
              this.$notify.error({
                title: "Lỗi",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        })
        .catch(() => {
          this.$notify.error({
            title: "Lỗi",
            message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
          });
        });
    },
  },
  watch: {
    productDetail: function() {
      this.formatData();
    },
  },
};
</script>

<style lang="css" scoped>
.image-sku {
  position: relative;
}
.item-image {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 3px;
}
.property-image {
  padding: 7px 6px 7px 27px;
  background: #ececec;
  color: #333333;
  cursor: pointer;
}

.property-image .color {
  display: flex;
  width: 86px;
}
.property-image :hover {
  color: #ffffff;
  background: #6c5ce7;
}
.property-image.active {
  color: #ffffff;
  background: #6c5ce7;
}
.p-0-2 {
  padding: 8px 8px 8px 0;
}
.product-detail {
  background: #ffffff;
  padding: 15px 3px;
}
.property {
  padding: 7px 20px;
  background: #ececec;
  color: #333333;
  cursor: pointer;
}
.property.color {
  display: flex;
  width: 86px;
}
.property:hover {
  color: #ffffff;
  background: #6c5ce7;
}
.property.active {
  color: #ffffff;
  background: #6c5ce7;
}
.image {
  width: 100%;
  max-height: 500px;
  object-fit: contain;
}
.title-normal {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.title-product {
  color: #333333;
  font-size: 16px;
}
.title-header-product {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}
.pd-0-12 {
  padding: 12px 0;
}
.price-product {
  color: #da100b;
  font-size: 16px;
}
.btn-order {
  padding: 7px 14px;
  width: 210px;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  background: #fd6120;
  color: #ffffff;
}
.btn-add-cart {
  padding: 7px 14px;
  width: 210px;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  background: #26b01c;
  color: #ffffff;
}
.listPicbottom {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

.listPicbottom > div {
  width: 53px;
  aspect-ratio: 1/1;
  cursor: pointer;
}

.listPicbottom img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.price-content {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.item-price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 142px;
}
.price-product-item {
  font-size: 20px;
  font-weight: 500;
  color: #ff4000;
}
.prop-item-total {
  position: absolute;
  right: 0;
  top: -10px;
  border-radius: 6px;
  background-color: #ff4000;
  padding: 3px;
  font-size: 10px;
  color: #fff;
}
.price-quantity {
  background: #f5f5f5;
  margin-top: 20px;
  padding-left: 12px;
  align-items: baseline;
  position: relative;
  padding: 20px 0;
  margin: 20px 0;
  display: flex;
  justify-content: space-around;
}
.listProp_item {
  background-color: rgba(0, 0, 0, 0.06);
  margin: 0 12px 8px 0;
  padding: 4px 6px;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.listProp_item_color {
  height: 30px;
  width: auto;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
}
.txt_chat_ncc {
      color: var(--1, #00D0FF);
      font-size: 14px;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      cursor: pointer;
    }
</style>
