<template>
  <div class="menu-left">
    <div class="icon-action" @click="isCollapseData()">
      <span v-if="isCollapse"><i class="el-icon-s-unfold"></i></span>
      <span v-else><i class="el-icon-s-fold"></i></span>
    </div>

    <el-menu :default-active="path" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
      <el-menu-item index="1" :class="!isCollapse ? 'avatar-left' : 'text-center'">
        <img v-if="isCollapse" class="image-left" src="@/static/icon/menu-left/account.svg" alt="" />
        <el-avatar v-else :size="44"> <img class="image-left" src="@/static/icon/menu-left/account.svg" alt="" /></el-avatar>
        <template slot="title">
          <div class="info-acc">
            <div class="title-acc">
              {{ listLanguage.menu_account }}
            </div>
            <div class="name-acc">
              <span style="font-weight: 700;">{{ profile.name }}</span>
            </div>
          </div>
        </template>
      </el-menu-item>
      <!-- // Dashboard -->
      <el-menu-item :class="isCollapse ? 'text-center line-height-fix' : ''" index="" @click="toHome()">
        <img src="@/static/icon/menu-left/dashboard.svg" alt="" />
        <span slot="title" class="pl-1">{{ listLanguage.menu_dashboard }}</span>
        <div v-if="isCollapse" class="title-fix">{{ listLanguage.menu_dashboard }}</div>
      </el-menu-item>
      <!-- // Mua hàng  -->
      <el-submenu index="shopping" :class="isCollapse ? 'line-height-fix' : ''">
        <template slot="title">
            <!-- <img :class="isCollapse ? 'submenuPl' : ''" src="@/static/icon/menu-left/shopping-cart.svg" alt="" />
            <span class="pl-1" style="color: white">Mua hàng</span>
            <div v-if="isCollapse" class="title_submenu_fix">Mua hàng</div> -->
            <div :class="isCollapse ? 'line-height-fix-submenu' : ''" slot="title">
                <img :class="isCollapse ? 'submenuPl' : ''" src="@/static/icon/menu-left/shopping-cart.svg" alt="" />
                <span class="pl-1" style="color: white">{{ listLanguage.menu_purchase }}</span>
                <div v-if="isCollapse" class="title_submenu_fix">{{ listLanguage.menu_purchase }}</div>
            </div>
        </template>


        <el-menu-item :class="isCollapse ? 'subMenuItem' : ''" index="shopping-search" @click="toShoppingSearch()">
          <img class="width10" src="@/static/icon/menu-left/search-normal.svg" alt="" />
          <span slot="title" class="pl-2">{{ listLanguage.menu_search }}</span>
        </el-menu-item>
        <el-menu-item :class="isCollapse ? 'subMenuItem' : ''" index="shopping-cart" @click="toShoppingCart()">
          <img class="width10" src="@/static/icon/menu-left/shopping-cart.svg" alt="" />
          <span slot="title" class="pl-2">{{ listLanguage.menu_cart }}</span>
        </el-menu-item>
        <el-menu-item :class="isCollapse ? 'subMenuItem' : ''" index="shopping-warehouse" @click="toListAddress()">
          <img class="width10" src="@/static/icon/menu-left/location.svg" alt="" />
          <span slot="title" class="pl-2">{{ listLanguage.menu_china_shipping_address }}</span>
        </el-menu-item>
      </el-submenu>
      <!-- // Yêu cầu thanh toán -->
      <el-menu-item :class="isCollapse ? 'text-center line-height-fix height-fix-menu-left' : ''" index="payment-request" @click="toPaymentRequest()">
        <img src="@/static/icon/menu-left/payment.svg" alt="" />
        <span slot="title" class="pl-1">{{ listLanguage.menu_payment_request }}</span>
        <div v-if="isCollapse" class="title-fix title-full-width">
          {{ listLanguage.menu_payment_request }}
        </div>
      </el-menu-item>
      <!-- // quản lý tài khoản  -->
      <el-menu-item index="user" @click="toUser()" v-if="isCheckPageUser" :class="isCollapse ? 'text-center line-height-fix height-fix-menu-left' : ''">
        <img src="@/static/icon/menu-left/user.svg" alt="" />
        <span slot="title" class="pl-1">{{ listLanguage.menu_account_management }}</span>
        <div v-if="isCollapse" class="title-fix title-full-width">
          {{ listLanguage.menu_account_management }}
        </div>
      </el-menu-item>
      <!-- // tài chính  -->
      <el-menu-item index="transaction" @click="toTransaction()" :class="isCollapse ? 'text-center line-height-fix' : ''">
        <img src="@/static/icon/menu-left/finance.svg" alt="" />
        <span slot="title" class="pl-1">{{ listLanguage.menu_transaction }}</span>
        <div v-if="isCollapse" class="title-fix">{{ listLanguage.menu_transaction }}</div>
      </el-menu-item>
      <!-- // chuyển đổi -->
      <el-menu-item index="convert" @click="toConvert()" :class="isCollapse ? 'text-center line-height-fix' : ''">
        <img src="@/static/icon/menu-left/convert.svg" alt="" />
        <span slot="title" class="pl-1">{{ listLanguage.menu_convert }}</span>
        <div v-if="isCollapse" class="title-fix">{{ listLanguage.menu_convert }}</div>
      </el-menu-item>
      <!-- // khiếu nại -->
      <el-menu-item index="complain" @click="toComplain()" :class="isCollapse ? 'text-center line-height-fix' : ''">
        <img src="@/static/icon/menu-left/complain.svg" alt="" />
        <span slot="title" class="pl-1">Khiếu nại</span>
        <div v-if="isCollapse" class="title-fix">Khiếu nại</div>
      </el-menu-item>
      <!-- // ký gửi  -->
      <el-menu-item index="deposit" @click="toDeposit()" :class="isCollapse ? 'text-center line-height-fix' : ''">
        <img src="@/static/icon/menu-left/consin.svg" alt="">
        <span slot="title" class="pl-1">Ký gửi</span>
        <div v-if="isCollapse" class="title-fix">Ký gửi</div>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import AuthLogin from "@/AuthLogin";
export default {
  name: "HomeIndex",
  metaInfo() {
    return {
      title: "Xadal",
    };
  },
  components: {},
  data() {
    return {
      loading: true,
      isCollapse: true,
      listPack: [],
      profile: {},
      isCheckPageUser: false
    };
  },
  computed: {
    ...mapGetters("account", ["logged", "accountInfo", "listLanguage"]),
    ...mapGetters(["path", "full_path"]),
  },
  mounted() {
    this.profile = AuthLogin.user
    if (AuthLogin.user.parent_id == null) {
      this.isCheckPageUser = true;
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    isCollapseData() {
      this.isCollapse = !this.isCollapse;
      this.$emit("toggleMenu", this.isCollapse);
    },
    // màn tài chính
    toTransaction() {
      this.pushRouter("partner.transaction.index");
    },
    toPaymentRequest() {
      this.pushRouter("payment-request");
    },
    toUser() {
      this.pushRouter("user");
    },
    toHome() {
      this.pushRouter("home-index");
    },
    toShoppingSearch() {
      this.pushRouter("shopping-search");
    },
    toShoppingCart() {
      this.pushRouter("shopping-cart");
    },
    toConvert() {
      this.pushRouter("partner.convert.index");
      // this.$message('Tính năng đang nâng cấp');
    },
    toListAddress() {
      this.pushRouter("partner.address.index");
    },
    toComplain() {
      this.pushRouter("partner.complain.index");
    },
    // ký gửi
    toDeposit() {
      this.pushRouter("partner.deposit.index");
    },
    pushRouter(name, params = '') {
      this.$router.push({ name: name, params: params }).catch(() => {})
    }
  },
  watch: {},
};
</script>
<style>
  .menu-left .el-menu--collapse {
    width: 86px;
  }
  .info-account {
    height: 100px;
  }
  .img-header-left {
    width: 44px;
    height: 44px;
    margin-right: 12px;
  }
  .img-header-left img{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 100%;
  }
  .menu-left {
    /* height: 100%; */
    position: relative;
    width: 100%;
  }
  .title-fix {
    position: absolute;
    top: 26px;
    /* left: 12px; */
    left: 0;
    right: 0;
    margin: 0 auto;
    font-size: 12px;
    padding: 0 5px;
  }

  .el-menu-item.is-active {
    background: #294C94;
    color: unset;
    border-radius: 3px;
  }
  .el-menu-item:focus, .el-menu-item:hover {
    background: #294C94 !important;
    border-radius: 3px;
  }
  .menu-left .el-menu {
    background: #00194C;
    /* height: 100%; */
    font-size: 14px;
    line-height: 24px;
    /* font-weight: 600; */
  }
  .menu-left .el-menu-item {
    color: #ffffff;
    height: 65px;
    line-height: 60px;
    position: relative;
    /* margin: 0 5px; */
    /* margin-left: 3px;
    margin-right: 5px; */
    margin: 10px 5px 10px 3px;
    padding-left: 10px !important;
  }
  .menu-left .el-submenu {
    /* height: 65px; */
    /* margin: 0 5px; */
    margin-left: 3px;
    margin-right: 5px;
    color: #fff;
  }
  .subMenuItem {
    height: 40px !important;
    line-height: 40px !important;
    background: #00194C;
    color: #fff !important;
  }
  .subMenuItem:hover {
    /* color: #00194C !important; */
  }
  .el-submenu__title {
    height: 65px !important;
    padding-left: 10px !important;
    /* line-height: 45px !important; */
  }
  .line-height-fix-submenu {
    line-height: 45px !important;
  }
  .flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-submenu__title:hover .title_submenu_fix {
  color: #00194C;
}
  .title_submenu_fix {
    position: absolute;
    top: 26px;
    left: 12px;
    right: 0;
    margin: 0 auto;
    font-size: 12px;
    color: #fff;
  }
  .submenuPl {
    padding-left: 16px;
  }
  .height-fix-menu-left {
    height: 90px !important;
  }
  .line-height-fix {
    line-height: 45px !important;
  }

  .line-height-fix-mh {
    line-height: 30px !important;
  }

  .menu-left .el-menu-item img {
    /* margin-right: 10px; */
  }
  .avatar-left {
    height: 82px !important;
    padding: 16px 0 16px 20px !important
  }
  .title-full-width {
    white-space: pre-line;
    line-height: 20px;
    left: 0;
    top: 48px;
  }
  .info-acc {
    position: absolute;
    left: 78px;
    top: 20px;
  }
  .info-acc .title-acc {
    height: 20px;
    line-height: 20px;
  }
  .info-acc .name-acc {
    height: 20px;
    line-height: 20px;
  }
  .menu-left .icon-left {
    position: absolute;
    top: 20px;
    left: 215px;
    z-index: 10;
  }
  .menu-left .icon-right {
    position: absolute;
    top: 20px;
    left: 50px;
    z-index: 10;
  }
  .menu-left .icon i {
    background: #962DFF;
    border-radius: 50%;
    font-size: 20px;
    padding: 3px;
    color: #fff;
  }
.icon-action {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #00194C;
  color: #fff;
}
.submenu-left .el-submenu__title {
    color: #fff;
}
.submenu-left img {
  margin-right: 10px;
}
/* .el-menu-item:hover {
  color: #00194C;
} */
.el-submenu__title:hover {
  background: #294C94 !important;
  border-radius: 3px;
}
.el-submenu__title:hover .title_submenu_fix {
  color: #fff;
  /* color: #00194C; */
}
.menu-left .el-menu-item.is-active {
  color: #fff;
  font-weight: 700;
}
/* .el-submenu:hover > .el-submenu__title > span {
  color: #00194C !important;
}
.menu-left .el-menu-item.is-active:hover {
  color: #00194C;
} */
.el-submenu .el-menu-item {
  min-width: 180px !important;
}
.width10 {
  width: 16px;
}

/* @media only screen and (max-width: 768px), (max-width: 1023px) {
  .user-index .filter-list {
    margin: 0 0 10px 0;
  }
} */

.el-menu--popup {
  padding: 0 !important;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .el-submenu .el-menu-item {
    min-width: 170px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px){
  .el-submenu .el-menu-item {
    min-width: 180px;
  }
}
</style>
