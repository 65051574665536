<template>
  <div class="home-index-create-request frame-content-common-2022">
    <el-dialog
      top="5%"
      width="40%"
      :visible="dialogCreateRequest"
      :show-close="false"
      title="Thêm mới"
    >
      <div class="dialog-body">
        <div class="create_item">
          <div class="create_title">
            <span>Chọn nguồn</span>
          </div>
          <div class="d-flex align-items-center pt-2">
            <div v-for="item in optionSource" :key="item.id" class="pr-3">
              <div class="content_radio">
                <img src="@/static/icon/payment-request/radio.svg" alt="" v-if="item.value === typeSource">
                <img src="@/static/icon/payment-request/radio-no.svg" alt="" v-else @click.stop="changeTypeSource(item.value)">
                <span @click.stop="changeTypeSource(item.value)">{{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="create_item">
          <div class="create_title">
            <span>Chọn loại thêm mới</span>
          </div>
          <div class="d-flex align-items-center pt-2">
            <div v-for="item in optionCreate" :key="item.id" class="pr-3">
              <div class="content_radio">
                <img src="@/static/icon/payment-request/radio.svg" alt="" v-if="item.value === typeCreate">
                <img src="@/static/icon/payment-request/radio-no.svg" alt="" v-else @click.stop="changeTypeCreate(item.value)">
                <span @click.stop="changeTypeCreate(item.value)">{{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- // type 1 -->
        <div class="create_item" v-if="typeCreate === 1">
          <!-- bảng nhập -->
          <div class="tableDataCreate">
            <el-table
              ref="multipleTable"
              :data="inputOrderCode"
              style="width: 100%"
              border
            >
              <el-table-column width="50" label="STT" type="index" align="center"></el-table-column>
              <el-table-column label="Mã đơn hàng" align="center">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.order_code"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="Tổng tiền (¥)" align="center">
                <template slot-scope="scope">
                  <el-input size="mini" type="number" v-model="scope.row.amount_change"></el-input>
                </template>
              </el-table-column>
              <el-table-column width="100" label="Thao tác" align="center">
                <template slot-scope="scope">
                  <i style="cursor: pointer;" class="el-icon-delete" @click="deleteRow(scope.row)"></i>
                </template>
              </el-table-column>
            </el-table>
            <div class="d-flex align-items-center" style="cursor: pointer; font-size: 12px; color: #2196F3; margin-top: 10px;" @click="addRow()">
              <i style="padding-right: 5px;" class="el-icon-circle-plus-outline"></i>
              <span>Thêm dòng</span>
            </div>
          </div>
        </div>
        <!-- // type 2 -->
        <div class="create_item" v-else>
          <p>
            <a :href="url + 'request_payment/get-file-excel-create-request-payment-demo'">Tải file excel mẫu</a>
            <br>
            <span style="color: red">Lưu ý nếu mã đơn hàng là dạng số mà quá dài thì thêm dấu ' phía trước để không bị lỗi định dạng !</span>
            <br>
            <span style="color: red">Ví dụ : '2049925981726880794</span>
          </p>
          <div class="uploadExcel" @click="selectFileExcel()">
            <img src="@/static/icon/payment-request/upExcel.svg" alt="">
          </div>
          <!-- //  -->
          <div style="font-size: 12px;">
            <span>{{ fileExcel }}</span>
          </div>
        </div>
      </div>
      <div class="btn_action_50 d-flex align-items-center justify-content-between">
        <el-button class="btnToCancel" @click="cancelCreateRequest();">Đóng</el-button>
        <el-button
          class="btnDone"
          @click="doneCreate()"
          :disable="!canSubmit"
          :loading="!canSubmit"
        >Thêm</el-button>
      </div>
    </el-dialog>



    <div>
      <input
        type="file"
        class="upload-file-none"
        id="fileExcel"
        ref="fileExcel"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        @change="handleFileExcel()"
      />
    </div>

  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "FormAddress",
  props: {
    dialogCreateRequest: Boolean,
  },
  metaInfo() {
    return {
      title: "",
    };
  },
  components: {},
  data() {
    return {
      loading: true,
      canSubmit: true,
      optionCreate: [
        {
          name: "Mã đơn hàng",
          value: 1
        },
        {
          name: "File Exel",
          value: 2
        },
      ],
      optionSource: [
        {
          name: "Nguồn 1688",
          value: 1
        },
        {
          name: "Nguồn Taobao",
          value: 2
        },
      ],
      typeCreate: 1,
      typeSource: 1,
      inputOrderCode: [
        {
          id: 1,
          order_code: "",
          amount_change: 0,
        },
      ],
      fileExcel: "",
      dataSent: "",
      fileImport: "",
      url:"",
    };
  },
  mounted() {
    this.url = process.env.VUE_APP_API_URL;
  },
  methods: {
    addRow() {
      this.inputOrderCode.push(
        {
          'id': Math.floor(Math.random() * 100000) + 1,
          'order_code': '',
          'amount_change': 0,
        }
      )
    },
    deleteRow(row) {
      var index = this.inputOrderCode.indexOf(row);
      if (index !== -1) {
        this.inputOrderCode.splice(index, 1)
      }
    },
    // phần up load
    selectFileExcel() {
      document.getElementById("fileExcel").click();
    },
    handleFileExcel() {
      let file  = this.$refs.fileExcel.files[0];
      this.fileImport = file;
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("Dung lượng file tối đa là 2M");
        return;
      }
      this.fileExcel = file.name;
    },
    //
    cancelCreateRequest() {
      this.$emit('cancelCreateRequest');
    },
    changeTypeCreate(value) {
      this.typeCreate = value;
    },
    changeTypeSource(value) {
      this.typeSource = value;
    },
    doneCreate() {
      this.canSubmit = false;
      if (this.typeCreate === 1) {
          this.createWithOrderCode()
      } else {
          this.createWithFileExcel()
      }
    },
    createWithOrderCode(){
      let data = {
          'typeCreate': this.typeCreate,
          'typeSource': this.typeSource,
          'orderData': this.inputOrderCode
      }
      axios.post("/request_payment/create-request-payment", data).then((response) => {
        this.canSubmit = true;
        this.inputOrderCode = [
          {
            id: 1,
            order_code: "",
            amount_change: 0,
          }
        ]
        switch (response.code) {
          case 200:
            this.$notify({
              title: "Thành công",
              message: response.message,
              type: "success",
            });
            this.$emit('doneCreate', true);
            break;
          case 400:
            this.$notify({
              title: "Thất bại",
              message: response.errors[0],
              type: "warning",
            });
            break;
          case 422:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: Object.values(response.data)[0],
              type: "warning",
            });
            break;
          case 500:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: response.message,
              type: "error",
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      }).catch((errors) => {
        switch (errors.code) {
          case 200:
            this.$notify({
              title: "Thành công",
              message: errors.message,
              type: "success",
            });
            break;
          case 400:
            this.$notify({
              title: "Thất bại",
              message: errors.message,
              type: "warning",
            });
            break;
          case 422:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: errors.message,
              type: "warning",
            });
            break;
          case 500:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: errors.message,
              type: "error",
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    createWithFileExcel(){
      this.formData = new FormData();
      this.formData.append('typeCreate', this.typeCreate);
      this.formData.append('orderExcel', this.fileImport);
      this.formData.append('typeSource', this.typeSource);
      axios.post("/request_payment/create-request-payment", this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        this.canSubmit = true;
        this.inputOrderCode = [
          {
            id: 1,
            order_code: "",
            amount_change: 0,
          }
        ]
        switch (response.code) {
          case 200:
            this.$notify({
              title: "Thành công",
              message: response.message,
              type: "success",
            });
            this.$emit('doneCreate', true);
            break;
          case 400:
            this.$notify({
              title: "Thất bại",
              message: response.errors[0],
              type: "warning",
            });
            break;
          case 422:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: Object.values(response.data)[0],
              type: "warning",
            });
            break;
          case 500:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: response.message,
              type: "error",
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      }).catch((errors) => {
        switch (errors.code) {
          case 200:
            this.$notify({
              title: "Thành công",
              message: errors.message,
              type: "success",
            });
            break;
          case 400:
            this.$notify({
              title: "Thất bại",
              message: errors.message,
              type: "warning",
            });
            break;
          case 422:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: errors.message,
              type: "warning",
            });
            break;
          case 500:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: errors.message,
              type: "error",
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    }
  },
  watch: {},
};
</script>

<style lang="css" scoped>
.content_radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  padding: 10px 0;
}
.content_radio img {
  padding-right: 5px;
}
.home-index-create-request .create_title {
  font-size: 14px;
  font-weight: 400;
}
.home-index-create-request .note {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F4F8;
  color: #204D6C;
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
  margin: 10px 0;
}
.home-index-create-request .uploadExcel {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #DCDCDC;
  padding: 20px 0;
  margin: 10px 0;
}
.btn_action_50 {
  padding: 10px 0;
}
</style>
<style>
.home-index-create-request .el-dialog__body {
  padding: 10px 20px;
}
.btn_action_50 .el-button {
  width: 100%;
}
.upload-file-none {
  display: none;
}
.tableDataCreate {
  padding-top: 10px;
}
.tableDataCreate .el-table th.el-table__cell>.cell {
  font-weight: 600;
  font-size: 13px;
}
.tableDataCreate .el-table--border th.el-table__cell, .el-table__fixed-right-patch {
  background: #F5F4F8;
}
.tableDataCreate .el-table .cell {
  font-size: 12px;
}
</style>
