<template>
    <div class="product-table mt-3">
      <el-dialog
        id="detailOrderSupplier"
        :title="'Chi tiết đơn ' + (detail.order_code)"
        :visible.sync="showDialog"
        width="70%"
        :before-close="closeModal"
      >
        <div v-if="detail.suppliers && detail.suppliers.length">
          <div v-for="(supplier, index) in detail.suppliers" :key="index" :class="index > 0 ? 'mt-2' : ''">
            <div class="">
              <table-supplier
                :key="supplier.source_name + supplier.id"
                :tableData="supplier"
                :orderCode="detail.order_code"
              />
            </div>
          </div>
        </div>

        <div class="d-flex mt-2" v-if="detail.shipping_address">
            <p>Địa chỉ nhận hàng: {{ detail.shipping_address.address + ' - ' + detail.shipping_address.district + ' - ' +
                detail.shipping_address.city}}</p>
        </div>

        <div class="d-flex align-items-center justify-content-center mt-3"
         style="background-color: #fff; padding: 10px"
        >
         <el-row style="width: 100%">
            <el-col :span="8"></el-col>
            <el-col :span="12" class="d-flex align-items-center justify-content-center" style="float: right; text-align: right">
                <el-col :span="12">Phí Ship</el-col>
                <el-col :span="6"></el-col>
                <el-col :span="6">
                    <span style="font-weight: 700; color: #DA100B">¥{{ detail.total_fee }}</span>
                </el-col>
            </el-col>
         </el-row>
        </div>
        <div class="d-flex align-items-center justify-content-center"
         style="background-color: #fff; padding: 10px"
        >
          <el-row style="width: 100%">
            <el-col :span="8"></el-col>
            <el-col :span="12" class="d-flex align-items-center justify-content-center" style="float: right; text-align: right">
              <el-col :span="12">Tổng</el-col>
              <el-col :span="6">
                <span style="font-weight: 700; color: #DA100B">{{ detail.total_quantity }}</span>
              </el-col>
              <el-col :span="6">
                <span style="font-weight: 700; color: #DA100B">¥{{ detail.total_price }}</span>
              </el-col>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
    </div>
  </template>

  <script>
  import { mapGetters } from "vuex";
  import TableSupplier from "../../views/payment_request/components/TableSupplier.vue";

  export default {
    name: "OrderDetail",
    props: {
      dialogShow: Boolean,
      detail: Object,
    },
    metaInfo() {
      return {
        title: "Chi tiết đơn hàng",
      };
    },
    components: {
      TableSupplier
    },
    data() {
      return {
        configList: {
          exchange_rate: 3.5
        },
        showDialog: this.dialogShow,
      };
    },
    computed: {
      ...mapGetters("account", ["logged", "accountInfo"]),

    },
    mounted() {
      //console.log(this.detail, this.showDialog, 'order detail')
    },
    methods: {
      formatItemSales(sales) {
        const formattedSales = String(sales);
        const sale = formattedSales?.includes('万') ? formattedSales?.split('万')[0] * 10000 : formattedSales?.split('+')[0];
        return Number(sale);
      },
      toDetail(item, target = 'internal') {
        //console.log(item);
        let targets = ['internal', 'external'];
        let filter = this.formSearch;

        let itemId = this.getItemIdBySource(filter.source, item);
        if (!itemId) {
          return;
        }

        if (targets.includes(target)) {
          window.open(
            target === 'internal'
              ? this.$router.resolve({
                name: "shopping-detail",
                query: {search: filter.search, itemId: itemId},
              }).href
              : (item.itemUrl ?? ''),
            '_blank'
          )
        }
      },
      getItemIdBySource(source, item) {
        let itemId = '';

        switch (source) {
          case 'taobao':
            itemId = item.itemId;
            break;
          case '1688':
            itemId = item.offerId;
            break;
        }

        return itemId;
      },
      convertStringToSlug(string) {
        return String(string)
          .normalize('NFKD') // split accented characters into their base characters and diacritical marks
          .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
          .trim() // trim leading or trailing whitespace
          .toLowerCase() // convert to lowercase
          .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
          .replace(/\s+/g, '-') // replace spaces with hyphens
          .replace(/-+/g, '-'); // remove consecutive hyphens
      },
      changeCurrentPage(currentPage) {
        //console.log(this.formSearch, '123');
        this.formSearch.page = currentPage;
        this.$emit('search', this.formSearch);
        document.getElementById("ST2022").scrollIntoView({behavior: "smooth"});
      },
      closeModal() {
        this.$emit('close');
      }
    },
    watch: {
      dialogShow: function (val) {
        this.showDialog = val;
      }
    },
  };
  </script>


<style lang="css" scoped>
  .main-index {
    padding: 0 25px;
    background: #fff;
    min-height: calc(100vh - 130px);
  }

  .main-index .title-header {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px; /* 100% */
    letter-spacing: 0.32px;
    text-transform: uppercase;
    padding-top: 31px;
  }

  /* search website */
  .main-index .seach-china-2023 {
    padding: 16px 0;
  }

  .main-index .select-mall-index {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 1px solid #BFD5EC;
    background: #FFF;
    width: 80px;
    height: 35px;
    padding: 0 10px 0 20px;
    border-right: 1px solid #bfd5ec;
    cursor: pointer;
    position: relative;
  }

  .select-mall-index .image img {
    height: 22px;
    margin-right: 5px;
  }

  .sub-select-mall {
    position: absolute;
    min-height: 100px;
    background-color: #fff;
    width: 102px;
    left: 0;
    top: 35px;
    z-index: 1;
  }

  .sub-select-mall .image {
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-bottom: 1px solid #ccc;
  }

  .sub-select-mall .image:hover {
    background-color: #f2f2f2;
    transition: all 0.3s;
  }

  .main-index .seach-china-2023 input {
    width: 70%;
    font-size: 13px;
    color: #333;
    margin-right: 8px;
    outline: none;
    border: 1px solid #BFD5EC;
    height: 33px;
    border-left: none;
    padding-left: 17px;
  }

  .main-index .seach-china-2023 input::-webkit-input-placeholder {
    font-weight: 300;
    color: #1771C1;
    font-size: 12px;
    font-style: italic;
  }

  .seach-button-hhqq {
    width: 80px;
    margin-left: -40px;
    z-index: 999;
  }

  .seach-button-hhqq i {
    color: #fff !important;
  }

  .btn-hhqq {
    background: #fe6701;
    border-radius: 20px;
    height: 35px;
    width: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-hhqq:hover {
    cursor: pointer;
  }

  .btn-hhqq:hover img {
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
  }

  /* // */
  .main-index .txt-filter {
    color: #575757;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.28px;
    text-transform: capitalize;
    padding-right: 16px;
  }

  .btn-filter {
    width: 132px;
    height: 30px;
    border-radius: 4px;
    background: #26B01C;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 500;
    border: 1px solid #26B01C;
    cursor: pointer;;
  }

  .btn-filter:hover {
    background: #fff;
    color: #26B01C;
  }

  /* // bảng search */
  .box-main-search-category {
    background: #F0F1F6;
    display: flex;
    align-items: center;
  }

  .box-main-search-category img {
    margin: 0 5px;
  }

  .box-col-4 {
    background: #F0F1F6;
    width: 100%;
    cursor: pointer;
    margin: 4px 0;
    padding: 10px 0;
  }

  /* // sản phẩm  */
  .box-product {
    border: 1px solid #ECECEC;
    background-color: #FFF;
  }



  .box-product .item-image {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 220px;
    cursor: pointer;
    background-size: cover;
    position: relative;
  }
  .box-product .item-image .freeship{
    height: 25px;
    background-color: #084C94;
    position: absolute;
    bottom: 0%;
    opacity: 0.7;
    width: 100%;
    background: linear-gradient(to top left, transparent 15%, #084C94 70%);
  }
  .txt-detail {
    border: 1px solid #ECECEC;
    background-color: #FFF;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0.28px;
  }

  .txt-detail:hover {
    background: #1771C1;
    color: #fff;
  }

  .box-product .content {
    padding: 8px;
  }

  .txt-sp {
    color: #1771C1;
    height: 59px;
    max-height: 59px;
    overflow: hidden;
  }

  .txt-sp span {
    overflow: hidden;
  }

  .txt-sale {
    float: right;
    color: grey;
  }

  .txt-red {
    color: red;
  }
</style>

<style>
  .main-index .frame-filter-innput-2022 .el-input__inner {
    width: 130px;
  }

  .main-index .frame-filter-innput-2022 input {
    text-align: left;
    padding-left: 30px;
  }

  .txt-dropdown {
    font-size: 12px;
    width: 100%;
  }

  .pagi-search {
    padding-right: 0 !important;
  }

  @media only screen and (max-width: 576px) {
    .box-main-search-category {
      width: 190px !important;
    }
  }

  .input_search_key {
    width: 70%;
    border: 1px solid #BFD5EC;
    border-left: none;


  }

  .input_search_key input {
    height: 35px;
    border-radius: 0;
    border-left: none;
  }

  /************************/
  /**** CUSTOM CLASSES ****/
  /************************/
  .hoverPointer:hover {
    cursor: pointer !important;
  }

  .providerBlock {
    max-height: 42px;
    overflow-y: hidden;
  }

  .txt-provider {
    color: grey;
    margin: 8px 0 0 !important;
  }

  .action-buttons {
    padding: 0 8px 8px;
    display: flex;
    justify-content: space-between;
  }

  .action-buttons button {
    font-size: 12px;
    padding: 8px;
  }

  .action-buttons button + button {
    margin-left: 0;
  }

  /************************/
  /**** CUSTOM LOADING ****/
  /************************/
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: #ECECEC;
    z-index: 999;
  }

  .spinner {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1.2s linear infinite;
    margin-top: 120px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .frame-filter-item-2022 .el-select input {
    border-radius: 20px;
    height: 32px;
    border-color: #409EFF;
  }

  .frame-filter-item-2022 .el-button {
    height: 32px;
    padding: 4px 8px;
  }

  .frame-filter-item-2022 .el-checkbox {
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .frame-filter-item-2022 .el-checkbox .el-checkbox__label {
    margin-right: 8px;
  }

  .frame-filter-item-2022 .el-checkbox .el-checkbox__inner {
    border-radius: 4px;
  }

  .tbl-header {
    font-weight: 700 !important;
    color: #545454 !important;
  }

  #detailOrderSupplier .el-dialog__title {
    font-weight: 700;
    font-size: 16px;
  }
</style>
