import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";
import PaymentRequest from "@/views/payment_request/List.vue";
import ShoppingSearch from "@/views/shopping/Search.vue";
import ShoppingDetail from "@/views/shopping/Detail.vue";
import ShoppingCart from "@/views/shopping/Cart.vue";

import Meta from "vue-meta";
import auth from "@/router/auth";

Vue.use(VueRouter);

Vue.use(Meta, {
  keyName: "metaInfo", // the component option name that vue-meta looks for meta info on.
  attribute: "data-vue-meta", // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: "data-vue-meta-server-rendered", // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: "vmid", // the property name that vue-meta uses to determine whether to overwrite or append a tag
});

const routes = [
  {
    path: "/payment-request",
    name: "payment-request",
    component: PaymentRequest,
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/",
    name: "home-index",
    component: Home,
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/login",
    name: "account-login",
    component: () => import("@/views/account/Login.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/register",
    name: "account-register",
    component: () => import("@/views/account/Register.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/forgot-password",
    name: "account-forgot-password",
    component: () => import("@/views/account/Forgot.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Profile
  {
    path: "/profile",
    name: "account-profile",
    component: () => import("@/views/account/Profile.vue"),
    beforeEnter: auth.requireAuth,
  },
  // tài chính
  {
    path: "/transaction",
    name: "partner.transaction.index",
    component: () => import("@/views/transaction/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/user",
    name: "user",
    component: () => import("@/views/user/List.vue"),
    beforeEnter: auth.requireAuth
  },
  {
    path: "/shopping-search",
    name: "shopping-search",
    component: ShoppingSearch,
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/shopping-detail",
    name: "shopping-detail",
    component: ShoppingDetail,
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/shopping-cart",
    name: "shopping-cart",
    component: ShoppingCart,
    beforeEnter: auth.requireAuth,
  },
  // chuyển đổi
  {
    path: "/convert",
    name: "partner.convert.index",
    component: () => import("@/views/convert/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  // địa chỉ nhận hàng TQ
  {
    path: "/shopping-warehouse",
    name: "partner.address.index",
    component: () => import("@/views/shopping/addressCN/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  // ngân hàng
  {
    path: "/bank-list",
    name: "partner.bank.index",
    component: () => import("@/views/bank/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  // khiếu nại
  {
    path: "/complain",
    name: "partner.complain.index",
    component: () => import("@/views/complain_v2/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  // ký gửi
  // deposit
  {
    path: "/deposit",
    name: "partner.deposit.index",
    component: () => import("@/views/deposit/List.vue"),
    beforeEnter: auth.requireAuth,
  },
  // deposit
  {
    path: "/customer_delivery",
    name: "partner.customer_delivery.index",
    component: () => import("@/views/customer_delivery/List.vue"),
    beforeEnter: auth.requireAuth,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
