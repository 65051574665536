<template>
    <div class="product-related">
      <span class="title" style="font-weight: 700; font-size: 14px">{{ listLanguage.search_related_products }}</span>
        <div class="pt-2">
            <el-row :gutter="8">
              <div v-for="(item) in listRelated" :key="item.id">
                <el-col :span="12" class="pt-2">
                  <el-card :body-style="{ padding: '0px' }">
                    <img :src="item.picUrl" class="image">
                    <div style="padding: 14px;">
                      <div class="title">
                        <el-link :href="item.itemUrl" target="_blank" class="title-product">
                            {{ item.title }}
                        </el-link>
                      </div>
                      <div class="price">
                        <span v-if="item.promotionPrice > 0">
                          ¥ {{ (item.promotionPrice / 100).toFixed(2) | formatCurrency  }}
                        </span>
                        <span v-else>
                          ¥ {{ (item.price / 100).toFixed(2) | formatCurrency  }}
                        </span>
                        <span style="float:right;">
                          0 đã bán
                        </span>
                      </div>
                    </div>
                    <div class="bottom clearfix">
                      <el-button type="button" size="small" class="btn-detail btn">
                        <el-link :href="item.itemUrl" target="_blank" class="title-product title-link">
                          {{ listLanguage.search_see_detail }}
                        </el-link>
                      </el-button>
                      <el-button @click="gotoDetail(item)" type="button" size="small" class="btn-order btn pull-right">
                          {{ listLanguage.search_order }}
                      </el-button>
                    </div>
                  </el-card>
                </el-col>
              </div>
            </el-row>
      </div>
    </div>
  </template>

  <script>
  import { mapActions, mapGetters } from "vuex";
  export default {
    name: "ShoppingProductRelated",
    props: {
      listRelated: Array,
    },
    metaInfo() {
      return {
        title: "Thông tin",
      };
    },
    components: {},
    data() {
      return {
        loading: true,
      };
    },
    computed: {
      ...mapGetters("account", ["logged", "listLanguage"]),
    },
    mounted() {
    },
    methods: {
      ...mapActions("account", ["getAccountInfo"]),
      gotoDetail(item) {
        let routeData = this.$router.resolve(
          { name: 'shopping-detail', query:
            {
              search: this.$route.query.search,
              source: this.$route.query.source,
              itemId: item.itemId
            }
          });
        window.open(routeData.href, '_blank');
      }
    },
    watch: {},
  };
  </script>

  <style lang="css" scoped>
  .title-link {
    font-size: 12px;
  }
  .title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 15px;
    height: 50px;
    overflow: hidden;
  }
  .pull-right {
    float: right;
  }
  .bottom {
    padding: 10px;
  }
  .btn {
    padding: 10px;
  }
  .btn-order {
    background: rgb(254, 103, 1);
    color: #fff;
  }
    .image {
      max-height: 150px;
      width: 100%;
      object-fit: contain;
    }
    .button {
      width: 100%;
    }
  </style>
