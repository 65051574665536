import { render, staticRenderFns } from "./TableSupplier.vue?vue&type=template&id=dcaa71f2&scoped=true"
import script from "./TableSupplier.vue?vue&type=script&lang=js"
export * from "./TableSupplier.vue?vue&type=script&lang=js"
import style0 from "./TableSupplier.vue?vue&type=style&index=0&id=dcaa71f2&prod&lang=css&scoped=true"
import style1 from "./TableSupplier.vue?vue&type=style&index=1&id=dcaa71f2&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcaa71f2",
  null
  
)

export default component.exports