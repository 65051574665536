<template>
    <div class="payment-request-filter mt-3 filterMobile">
        <div class="d-flex align-items-center filter maxContentM">
          <div class="d-flex flex-column mr-10">
            <span class="label">{{ listLanguage.common_select_source }}</span>
            <el-select @change="filterPayment(source_id, 'source_id')" v-model="source_id" :placeholder="listLanguage.common_select_source" clearable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="d-flex flex-column mr-10">
            <span class="label">{{ listLanguage.common_select_source_type }}</span>
            <el-select @change="filterPayment(source_type, 'source_type')" v-model="source_type" :placeholder="listLanguage.common_select_source_type" clearable>
              <el-option
                v-for="item in source_type_list"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="d-flex flex-column mr-10">
            <span class="label">Chọn loại tiền TT</span>
            <el-select @change="filterPayment(type_fund, 'type_fund')" v-model="type_fund" placeholder="Chọn loại tiền thanh toán" clearable>
              <el-option
                v-for="item in type_fund_list"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="mr-10 d-flex flex-column">
            <span class="label">{{ listLanguage.common_order_code }}</span>
            <el-input
              @change="filterPayment(order_code, 'order_code')"
              class="input-filter" :placeholder="listLanguage.common_order_code"
              v-model="order_code"
              clearable
              ></el-input>
          </div>
          <div class="mr-10 d-flex flex-column">
            <span class="label">{{ listLanguage.common_purchase_account }}</span>
            <el-input
              @change="filterPayment(user_name_payment, 'user_name_payment')"
              class="input-filter" :placeholder="listLanguage.common_purchase_account"
              v-model="user_name_payment" clearable
              ></el-input>
          </div>
          <div class="d-flex flex-column mr-10" v-if="!user.parent_id">
            <span class="label">{{ listLanguage.common_select_agent }}</span>
            <el-select @change="filterPayment(user_id, 'user_id')" v-model="user_id" :placeholder="listLanguage.common_select_agent" clearable>
              <el-option
                v-for="item in listChild"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="d-flex flex-column mr-10">
            <span class="label">{{ listLanguage.common_date }}</span>
            <div class="d-flex-align-items-center">
              <el-date-picker
                @change="filterPayment(from_created_at, 'from_created_at')"
                class=" mr-10"
                v-model="from_created_at"
                type="date"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
                :placeholder="listLanguage.common_date_form" >
              </el-date-picker>
              <el-date-picker
                @change="filterPayment(to_created_at, 'to_created_at')"
                v-model="to_created_at"
                type="date"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
                :placeholder="listLanguage.common_date_to">
              </el-date-picker>
            </div>
          </div>
          <div class="d-flex flex-column mr-10">
            <span class="label">Chọn theo ngày</span>
            <el-select @change="filterPayment(option_date, 'option_date')" v-model="option_date" placeholder="Chọn" clearable>
              <el-option
                  v-for="item in optionsDate"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
    </div>
  </template>

  <script>
  import EventBus from '@/EventBus'
  import AuthLogin from "@/AuthLogin";
import { mapGetters } from "vuex";
  export default {
    name: "FilterPayment",
    props: {
      listChild: Array,
    },
    metaInfo() {
      return {
        title: "Yêu cầu thanh toán",
      };
    },
    components: {

    },
    data() {
      return {
        options: [
          {
            value: '1',
            label: '1688'
          },
          {
            value: '2',
            label: 'Taobao'
          }, {
            value: '3',
            label: 'Alipay'
          }
        ],
        source_type_list: [
          {
            value: '1',
            label: 'Xadal'
          },
          {
            value: '2',
            label: 'Add On'
          },
          {
            value: '4',
            label: 'Auto'
          },

        ],
        type_fund_list: [
          {
            value: 1,
            label: 'Việt Nam'
          },
          {
            value: 2,
            label: 'Trung Quốc'
          },

        ],
        source_id: '',
        source_type: '',
        type_fund: '',
        order_code: '',
        from_created_at: '',
        to_created_at: '',
        user_name_payment: '',
        user_id:'',
        user: AuthLogin.getUser(),
        optionsDate: [
          {
            name: "Ngày tạo",
            value: "1",
          },
          {
            name: "Ngày thanh toán",
            value: "2",
          },
        ],
        option_date: "1",
      };
    },
    computed: {
      ...mapGetters("account", ["logged", "accountInfo", "listLanguage"]),

    },
    mounted() {
    },
    methods: {
      // filterPayment(value, type) {
      //   var obj = {};
      //   obj[type] = value;
      //   this.$emit('changeFilter', obj);
      // },
      filterPayment() {
        var obj = {};
        // obj[type] = value;
        obj = {
          source_id: this.source_id,
          source_type: this.source_type,
          order_code: this.order_code,
          from_created_at: this.from_created_at,
          to_created_at: this.to_created_at,
          user_name_payment: this.user_name_payment,
          user_id: this.user_id,
          type_fund: this.type_fund,
          option_date: this.option_date,
        }
        EventBus.$emit('handTitle', this.option_date);
        this.$emit('changeFilter', obj);
      },
    },
    watch: {

    },
  };
  </script>

  <style lang="css" scoped>
  .payment-request-filter {
    height: 82px;
    background: #ffffff;
  }
  .payment-request-filter .filter {
    padding: 10px;
  }
  .payment-request-filter .filter .item {
    padding: 8px;
  }
  .payment-request-filter .filter .item.active {
    background: #2196F3;
    border-radius: 4px;
    color: #ffffff;
  }

  .mr-10 {
    margin-right: 10px;
  }
  .label {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
  }
  .count {
    background: #962DFF;
    color: #ffffff;
    border-radius: 3px;
    padding: 1px 13px;
  }
  .input-filter {
    /* margin-top: 6px; */
    width: 150px;
  }
  </style>
  <style>
  .filter .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 120px;
  }
  .filter .el-select {
    width: 130px;
  }

  @media only screen and (max-width: 768px), (max-width: 1023px) {
  .filterMobile {
    height: auto !important;
    overflow-x: auto;
  }
  .maxContentM {
    width: max-content;
  }
}
</style>
