<template>
    <div class="product-detail" v-if="productDetail">
      <el-row :gutter="32">
            <el-col :span="8">
                <el-card :body-style="{ padding: '0px' }" shadow="hover">
                    <img :src="activeThumbnail ? activeThumbnail : require('@/static/images/product/default.png')"
                         class="image">
                    <div style="padding: 14px;" :class="checkThumb ? '' : 'hide-thumb'">
                        <div class="d-flex align-items-center justify-content-center listPicbottom"
                            :class="checkThumb ? '' : 'hide-thumb'">
                            <div v-for="(thumb, thumbIndex) in productDetail.images" :key="thumbIndex"
                                 class="mr-2"
                            >
                                <img v-if="thumb" @click="setCurrentThumbnail(thumb)" :src="thumb" alt="Image Thumbnail"/>
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="16">
                <div class="d-flex flex-column">
                    <div class="title-header-product">
                        <el-link :href="productDetail.detail_url" target="_blank" class="title-product">
                            {{ productDetail.title }}
                        </el-link>
                    </div>
                    <div class="shop pd-0-12 d-flex align-items-center">
                        <span class="title-normal">{{ listLanguage.search_products_of_the_shop }}: </span>
                        <span class="title-header-product pl-1">{{ productDetail.shopName }}</span>
                        <div class="d-flex align-items-center pl-2 txt_chat_ncc" @click="showChatNCC()">
                          <img v-if="chatNcc == false" src="@/static/icon/detail-search/chat.svg" alt="" />
                          <i v-else class="el-icon-loading"></i>
                          <span class="pl-2">{{ listLanguage.search_chat_with_supplier }}</span>
                        </div>
                    </div>
                    <el-row class="pd-0-12">
                        <el-col :span="4">
                            <span class="title-header-product">{{ listLanguage.search_price }}:</span>
                        </el-col>
                        <el-col :span="20">
                          <span class="price-product" v-if="skuItem.price">
                                {{ skuItem.price | formatCurrency }} ¥
                          </span>
                            <span class="price-product" v-else>
<!--                                ¥ {{ (productDetail.promotionPrice > 0 && productDetail.promotionPrice < productDetail.price) ?
                                (productDetail.promotionPrice/100) : (productDetail.price/100) }}-->
                                 {{(productDetail.promotionPrice > 0 ? productDetail.promotionPrice / 100 : productDetail.price / 100) | formatCurrency }} ¥
                            </span>
                        </el-col>
                    </el-row>
                    <div v-if="productDetail.skuProperties">
                        <el-row class="pd-0-12 d-flex"
                            v-for="(item, index) in productDetail.skuProperties.data" :key="index"
                        >
                            <el-col :span="4">
                                <span class="title-header-product">{{ index }}</span>
                            </el-col>
                            <el-col :span="20" v-model="actives" v-if="index.indexOf('颜色') > -1" >
                                <span
                                    v-for="(prop, key) in item" :key="key"
                                    @click="handleActive(key, index, prop, item)"
                                    style="display: inline-block" class="p-0-2 mb-2"
                                >
                                    <span  @click="setCurrentThumbnail(prop.image)"  ref="prop" :id="key" :class="{
                                        'active': !!actives.includes(key), 'property-image' : prop.image.indexOf('http') > -1, 'property': prop.image.indexOf('http') == -1
                                    }">
                                    <span class="image-sku" v-if="prop.image.indexOf('http') > -1">
                                      <img :src="prop.image" class="item-image"/>
                                    </span>
                                        {{ key }}
                                    </span>
                                </span>
                            </el-col>
                            <el-col :span="20" v-model="actives" v-else>
                                <span
                                    v-for="(prop, key) in item" :key="key"
                                    @click="handleActive(key, index, prop, item)"
                                    style="display: inline-block" class="p-0-2 mb-2"
                                >
                                    <span class="property" ref="prop" :id="key" :class="{
                                        'active': !!actives.includes(key),
                                    }">
                                        {{ key }}
                                    </span>
                                </span>
                            </el-col>
                        </el-row>
                    </div>
                    <el-row class="pd-0-12">
                        <el-col :span="4">
                            <span class="title-header-product">{{ listLanguage.search_quantity }} : </span>
                        </el-col>
                        <el-col :span="20">
                            <el-input-number
                                v-model="quantityNumber" @change="setMoneyRelatedTerms()"
                                :min="1" :max="productDetail.inventory"
                                v-loading="isUpdatingNumber"
                            ></el-input-number>
                            <span class="ml-3"> {{ listLanguage.search_in_stock }}: {{ getQuantityWh }}</span>
                        </el-col>
                    </el-row>
                    <el-row class="pd-0-12">
                        <el-col :span="4">
                           <p></p>
                        </el-col>
                        <el-col :span="20">
                            <el-button class="btn-add-cart" @click="addToCart()" :loading="!canAddToCart">{{ listLanguage.search_add_to_cart }}</el-button>
                            <el-button class="btn-order" @click="orderNowConfirm()" :loading="!canSubmit">
                              {{ listLanguage.search_order_now }}
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>
        <!-- // địa chỉ nhạn hàng  -->
        <Address
            :dialogAddress="dialogAddress"
            :source="'taobao'"
            :listData="listData"
            @cancel="cancelAddress"
            @addressDone="addressDone"
        />
    </div>
  </template>

  <script>
  import Address from "@/views/shopping/addressCN/Form";
  import { mapActions, mapGetters } from "vuex";
  import axios from "axios";
  export default {
    name: "ShoppingProductDetail",
    props: {
        productDetail: Object,
        activeThumbnail: String,
    },
    metaInfo() {
      return {
        title: "Thông tin",
      };
    },
    components: {
      Address
    },
    data() {
      return {
        dialogAddress: false,
        listData: [],
        loading: false,
        chatNcc: false,
        quantityNumber: 1,
        listThumbnail: [],
        isUpdatingNumber: false,
        activeThumb: this.activeThumbnail,
        itemSizeSelected: '',
        actives: [],
        isSelected: [],
        arrayUniqueByKey : [],
        arrayItemSku: [],
        arrayValueIdFilter: [],
        canSubmit: true,
        canAddToCart: true,
        arrayKey : [],
        skuItem : {
            'title': '',
            'mpSkuId': '',
            'quantity': 1,
            'mpId': '',
            'price': 0,
            'picUrl' : '',
            'valueName' : '',
            'quantity_in_stock' : 0,
            'propertyAttr' : '',
            'supplier': ''
        },
        receiver: '',
      };
    },
    computed: {
      ...mapGetters("account", ["logged", "listLanguage"]),

      checkThumb() {
        let thumbs  = this.productDetail.images;
        let isThumb = false;

        if (!thumbs || !thumbs.length) {
            return isThumb;
        }

        thumbs.forEach((thumb) => {
          if (thumb) {
            isThumb = true;
          }
        });

        return isThumb;
      },
      getQuantityWh() {
        if (this.skuItem.quantity_in_stock > 0) {
          return this.skuItem.quantity_in_stock;
        }

        return this.productDetail.inventory ?  this.productDetail.inventory :
          (this.productDetail.skuList ? this.productDetail.skuList[0].quantity : 0)
      }
    },
    mounted() {
      console.log('productDetail', this.productDetail);
    },
    methods: {
      // lấy data bảng
      fetchDataShippingAddress() {
        this.loading = true
        axios.get("shipping_address").then((response) => {
          this.loading = false;
          switch (response.code) {
            case 200:
              this.listData = response.data;
              break;
              case 500:
              this.$notify({
                title: "có lỗi xảy ra",
                message: response.message,
                type: "warning",
                });
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },
      // địa chỉ nhận hàng
      cancelAddress() {
        this.dialogAddress = false;
      },
      addressDone(data) {
        this.receiver = data;
        this.dialogAddress = false;
        this.orderNow(this.productDetail);
      },
      // chat vs ncc
      showChatNCC() {
        this.chatNcc = true;
        let data = {
          sourceType: "item",
          bizId: String(this.productDetail.itemId),
        }
        axios.post("/user/get-iframe-chat-taobao", data).then((response) => {
          this.chatNcc = false;
          switch (response.code) {
            case 200:
              window.open(response.data.link);
              break;
            case 400:
              this.$notify({
                title: "Thất bại",
                message: response.errors[0],
                type: "warning",
              });
              break;
            case 422:
              this.$notify({
                title: "Có lỗi xảy ra",
                message: Object.values(response.data)[0],
                type: "warning",
              });
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },
      ...mapActions("account", ["getAccountInfo"]),
      handleActive (key, index, item, items) {
        this.arrayItemSku.push(items);
        for (let i = 0 ; i < this.$refs.prop.length; i++) {
          if (this.$refs.prop[i].id === key) {
            this.isSelected.push({ "key": key, "index": index, 'valueId' :  item.valueId});
            const unique = 'index';
            this.arrayUniqueByKey = [...new Map(this.isSelected.map(item =>
              [item[unique], item])).values()];

            if (this.actives.includes(key)) {
              const remove = this.arrayKey.indexOf(key);
              if (remove > -1) {
                this.actives.splice(remove, 1);
              }
              this.actives = this.actives.filter(s => s !== key )
              if (this.actives.length === 0) {
                this.arrayUniqueByKey = [];
              }
            } else {
              if (this.actives.length  === 0) {
                this.actives.push(key)
              } else {
                this.actives = [...new Set(this.arrayUniqueByKey.map(item => item.key))];
              }
            }

            if (this.arrayValueIdFilter.includes(item.valueId)) {
              const remove = this.arrayKey.indexOf(item.valueId);
              if (remove > -1) {
                this.arrayValueIdFilter.splice(remove, 1);
              }
              this.arrayValueIdFilter = this.arrayValueIdFilter.filter(s => s !== item.valueId )
              if (this.arrayValueIdFilter.length === 0) {
                this.arrayUniqueByKey = [];
              }
            } else {
              if (this.arrayValueIdFilter.length  === 0) {
                this.arrayValueIdFilter.push(item.valueId)
              } else {
                this.arrayValueIdFilter = [...new Set(this.arrayUniqueByKey.map(item => item.valueId))];
              }
            }
          }
        }
        let objectValue = Object.values(this.productDetail.skuProperties.dataMpSkuId);
        for (let j = 0; j < objectValue.length; j ++) {
          let mapping = this.arrayValueIdFilter.filter(x => objectValue[j].keyItem.includes(x))
          if (mapping.length === objectValue[j].keyItem.length) {
              this.skuItem = {
                'title': this.productDetail.title,
                'mpSkuId': objectValue[j].mpSkuId,
                'mpId': objectValue[j].mpId,
                'quantity_in_stock': objectValue[j].quantity,
                'price': objectValue[j].promotionPrice > 0 ? objectValue[j].promotionPrice / 100 : objectValue[j].price / 100,
                'picUrl' :  objectValue[j].picUrl ?  objectValue[j].picUrl : this.productDetail.image,
                'valueName' : objectValue[j].valueName,
                'propertyAttr' : objectValue[j].propertyAttr,
                'supplier': {
                  'id': this.productDetail.shopId,
                  'name': this.productDetail.shopName,
                  'url': ''
                }
              };
          }
        }
        if (this.skuItem.mpSkuId) {
          console.log(this.skuItem, this.actives);
        }

      },
      addToCart(){
          if(!this.validateBeforeOrder()) {
            return;
          }

          let addToCart = [];

          this.skuItem.quantity = this.quantityNumber;

          if(this.productDetail.skuProperties.countSku === 0) {
            this.skuItem.supplier = {
                'id': this.productDetail.shopId,
                'name': this.productDetail.shopName,
                'url': ''
            };

            addToCart = [
              {
                'title': this.productDetail.title,
                'mpSkuId': this.productDetail.skuList[0].mpSkuId,
                'mpId': this.productDetail.mpId,
                'quantity_in_stock': this.productDetail.skuList[0].quantity_in_stock,
                'price': this.productDetail.skuList[0].promotionPrice > 0
                  ? this.productDetail.skuList[0].promotionPrice / 100
                  : this.productDetail.skuList[0].price,
                'picUrl' :  this.productDetail.image,
                'valueName' : '',
                'quantity' : this.skuItem.quantity,
                'supplier': this.skuItem.supplier,
                'source' : 'Taobao'
              }
            ]
          } else {
            addToCart = [
                {
                  'title': this.skuItem.title,
                  'mpSkuId': this.skuItem.mpSkuId,
                  'mpId': this.skuItem.mpId,
                  'quantity_in_stock': this.skuItem.quantity_in_stock,
                  'price': this.skuItem.price,
                  'picUrl' :  this.skuItem.picUrl,
                  'valueName' : (this.skuItem.propertyAttr),
                  'quantity' : this.skuItem.quantity,
                  'supplier': this.skuItem.supplier,
                  'source' : 'Taobao'
                }
            ]
          }
          this.canAddToCart = false;
          axios.post('/cart/product/add-to-cart', addToCart).then((response) => {
            this.loading = false;
            this.canAddToCart = true;
            switch (response.code) {
              case 200:
                //console.log(response)
                this.$notify({
                  title: "Thành công",
                  message: "Thêm sản phẩm vào giỏ hàng thành công",
                  type: "success",
                });
                break;
              default:
                this.$notify.error({
                  title: "Error",
                  message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
                });
                break;
          }
          }).catch(errors => {
              console.log(errors.response)
          });
      },
      validateBeforeOrder() {
        let countSku = this.productDetail.skuProperties.countSku;
        if (countSku > 0 && parseInt(this.actives.length) < parseInt(countSku)) {
              this.$notify.error({
                  title: "Lỗi!",
                  message: "Vui lòng chọn đầy đủ thuộc tính!",
              });
              return false;
        }
        if (this.skuItem.quantity_in_stock > 0 && this.quantityNumber > this.skuItem.quantity_in_stock ) {
          this.$notify.error({
            title: "Lỗi!",
            message: "Số lượng mua không được nhiều hơn số lượng tồn kho",
          });
          return false;
        }
        return true;
      },
      setCurrentThumbnail(url) {
        this.$emit('changeThumbnail', url);
      },
      setMoneyRelatedTerms() {

      },
      orderNowConfirm() {
        this.dialogAddress = true;
        this.fetchDataShippingAddress();
      },
      orderNow(product) {
        if(!this.validateBeforeOrder()) {
          return;
        }

        this.canSubmit = false;

        let items = [];

        if (!this.skuItem.supplier) {
          this.skuItem.supplier = {
            'id': this.productDetail.shopId,
            'name': this.productDetail.shopName,
            'url': ''
          };
        }

        let data = {
          currency: 'CNY',
          itemId: product.mpId,
          orderLineNo: '1',
          price: product.promotionPrice > 0 ? product.promotionPrice : product.price,
          quantity: this.quantityNumber,
          skuId: this.skuItem.mpSkuId ? this.skuItem.mpSkuId : this.productDetail.skuList[0].mpSkuId,
          title: product.title,
          image: product.image,
          supplier: this.skuItem.supplier
        };
        items.push(data);

        let dataFinal = {
          totalPrice: product.promotionPrice > 0 ?
          product.promotionPrice * this.quantityNumber :
          product.price * this.quantityNumber,
          totalQuantity: this.quantityNumber,
          items: items,
          sourceType: 'xadal',
          receiver: this.receiver
        };

        axios.post('order/store', dataFinal).then((response) => {
            this.loading = false;
            this.canSubmit = true;
            switch (response.code) {
              case 200:
                this.$notify({
                  title: "Thành công",
                  message: "Đặt hàng thành công",
                  type: "success",
                });
                break;
              default:
                this.$notify.error({
                  title: "Lỗi",
                  message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
                });
                break;
          }
          }).catch(errors => {
              console.log(errors.response)
          });
      }
    },
    watch: {
      //
    },
  };
  </script>

  <style lang="css" scoped>
  .image-sku {
    position: relative;
  }
  .item-image {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 3px;
  }
  .property-image {
    padding: 7px 6px 7px 27px;
    background: #ECECEC;
    color: #333333;
    cursor: pointer;
  }

  .property-image .color {
    display: flex;
    width: 86px;
  }
  .property-image :hover {
    color: #ffffff;
    background: #6C5CE7;
  }
  .property-image.active {
    color: #ffffff;
    background: #6C5CE7;
  }
  .p-0-2 {
    padding: 8px 8px 8px 0;
  }
    .product-detail {
        background: #ffffff;
        padding: 15px 3px;
    }
  .property {
    padding: 7px 20px;
    background: #ECECEC;
    color: #333333;
    cursor: pointer;
  }
  .property.color {
    display: flex;
    width: 86px;
  }
  .property:hover {
    color: #ffffff;
    background: #6C5CE7;
  }
  .property.active {
    color: #ffffff;
    background: #6C5CE7;
  }
    .image {
        width: 100%;
        max-height: 500px;
        object-fit: contain;
    }
    .title-normal {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }
    .title-product {
        color: #333333;
        font-size: 16px;
    }
    .title-header-product {
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
    }
    .pd-0-12 {
        padding: 12px 0;
    }
    .price-product {
        color: #DA100B;
        font-size: 16px;
    }
    .btn-order {
      padding: 7px 14px;
      width: 210px;
      font-weight: 500;
      font-size: 15px;
      line-height: 26px;
      background: #FD6120;
      color: #ffffff;
    }
    .btn-add-cart {
        padding: 7px 14px;
        width: 210px;
        font-weight: 500;
        font-size: 15px;
        line-height: 26px;
        background: #26b01c;
        color: #ffffff;
    }
    .listPicbottom {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-x: hidden;
    }

    .listPicbottom > div {
        width: 53px;
        aspect-ratio: 1/1;
        cursor: pointer;
    }

    .listPicbottom img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .hide-thumb {
        display: none !important;
    }
    .txt_chat_ncc {
      color: var(--1, #00D0FF);
      font-size: 14px;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      cursor: pointer;
    }
</style>
