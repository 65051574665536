<template>
  <div class="home-index-drawer frame-content-common-2022">
    <div class="icon_open">
        <i @click="drawer = true" class="el-icon-s-unfold"></i>
    </div>

    <el-drawer
        title="Modal change account password"
        :visible.sync="drawer"
        :direction="'ltr'"
        size="70%"
        :with-header="false"
        custom-class="account-drawer-change-pw"
      >
       <div class="menu_group d-flex flex-column">
        <div class="d-flex align-items-center">
          <el-avatar :size="44"> <img class="image-left" src="@/static/icon/menu-left/account.svg" alt="" /></el-avatar>
          <div class="d-flex flex-column pl-2">
            <span>Tài khoản</span>
            <span style="font-weight: 700;">{{ profile.name }}</span>
          </div>
        </div>
        <div class="menu_item mt-4 d-flex align-items-center" @click="toHome()">
          <img class="pr-2" src="@/static/icon/iconmb/db.svg" alt="" />
          <span>Dashboard</span>
        </div>
        <div class="menu_item d-flex align-items-center">
          <el-collapse>
            <el-collapse-item>
              <template slot="title">
                <div class="d-flex align-items-center">
                  <img class="pr-2" src="@/static/icon/iconmb/mh.svg" alt="" />
                  <span>Mua hàng</span>
                </div>
              </template>
              <div class="d-flex align-items-center submenuItem" style="margin-top: 15px;" @click="toShoppingSearch()">
                <img class="pr-2" src="@/static/icon/iconmb/tk.svg" alt="" />
                <span>Tìm kiếm</span>
              </div>
              <div class="d-flex align-items-center submenuItem">
                <img class="pr-2" src="@/static/icon/iconmb/bag.svg" alt="" @click="toShoppingCart()" />
                <span>Giỏ hàng</span>
              </div>
              <div class="d-flex align-items-center submenuItem" @click="toListAddress();">
                <img class="pr-2" src="@/static/icon/iconmb/location.svg" alt="" />
                <span>Địa chỉ nhận hàng TQ</span>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="menu_item d-flex align-items-center" @click="toPaymentRequest()">
          <img class="pr-2" src="@/static/icon/iconmb/money.svg" alt="">
          <span>Yêu cầu thanh toán</span>
        </div>
        <div class="menu_item d-flex align-items-center" @click="toUser()">
          <img class="pr-2" src="@/static/icon/iconmb/people.svg" alt="">
          <span>Quản lý tài khoản</span>
        </div>
        <div class="menu_item d-flex align-items-center" @click="toTransaction()">
          <img class="pr-2" src="@/static/icon/iconmb/dollar.svg" alt="" />
          <span>Tài chính</span>
        </div>
        <div class="menu_item d-flex align-items-center" @click="toConvert()">
          <img class="pr-2" src="@/static/icon/iconmb/convert.svg" alt="" />
          <span>Chuyển đổi</span>
        </div>
       </div>
      </el-drawer>

  </div>
</template>

<script>
import AuthLogin from "@/AuthLogin";
export default {
  name: "HomeIndex",
  metaInfo() {
    return {
      title: "",
    };
  },
  components: {},
  data() {
    return {
      loading: true,
      drawer: false,
      profile: {},

    };
  },
  mounted() {
    this.profile = AuthLogin.user;
  },
  methods: {
    toTransaction() {
      this.pushRouter("partner.transaction.index");
      this.drawer = false;
    },
    toPaymentRequest() {
      this.pushRouter("payment-request");
      this.drawer = false;
    },
    toHome() {
      this.pushRouter("home-index");
      this.drawer = false;
    },
    toUser() {
      this.pushRouter("user");
      this.drawer = false;
    },
    toShoppingSearch() {
      this.pushRouter("shopping-search");
      this.drawer = false;
    },
    toShoppingCart() {
      this.pushRouter("shopping-cart");
      this.drawer = false;
    },
    toConvert() {
      this.pushRouter("partner.convert.index");
      this.drawer = false;
    },
    toListAddress() {
      this.pushRouter("partner.address.index");
      this.drawer = false;
    },
    pushRouter(name, params = '') {
      this.$router.push({ name: name, params: params }).catch(() => {})
    }
  },
  watch: {},
};
</script>

<style lang="css" scoped>
.icon_open {
    padding: 10px;
    color: #fff;
    background: #00194C;
}
.menu_item {
  padding: 20px 0;
  border-bottom: 1px solid #294C94;
}
.submenuItem {
  padding: 20px 0 20px 20px;
  /* border-bottom: 1px solid #294C94; */
}
</style>
<style>
.home-index-drawer .el-drawer__body {
  padding: 10px;
}
.menu_item .el-collapse {
  width: 100%;
}
.menu_item .el-collapse-item__header {
  border: none;
  line-height: 0;
  height: 20px;
}
.menu_item .el-collapse {
  border: none;
}
</style>