import axios from "axios";
import ls from "local-storage";
import { SET_ACCOUNT_INFO, REMOVE_ACCOUNT_INFO, SET_LANGUAGE} from "@/store/mutation-types";
import AuthLogin from "@/AuthLogin";

const getAccountInfo = ({ commit }) => {
  commit(SET_ACCOUNT_INFO, ls.get('token'));
};

const removeAccountInfo = ({ commit }) => {
  commit(REMOVE_ACCOUNT_INFO);
};

const getLanguage = ({ commit }) => {
  axios.get("user/edit/" + AuthLogin.user.id).then(function (response) {
    switch (response.code) {
      case 200:
        commit(SET_LANGUAGE, response.data.langByLanguage);
        break;
      case 401:
        break;
      default:
        break;
    }
  });
};

export default {
  getAccountInfo,
  removeAccountInfo,
  getLanguage,
};
