<template>
  <div class="home-index rame-content-common-2022">
    <div class="xadal_title_head">
      <span>{{ listLanguage.home_title }}</span>
    </div>
    <div class="content_head d-flex align-items-center justify-content-between">
      <div class="box_head_1 d-flex align-items-center justify-content-between" :class="userLogin.parent_id == null ? 'box_head_30' : ''">
        <div class="txt_content d-flex flex-column">
          <span class="bold_txt">{{ dashboard.waiting_process }}</span>
          <span class="des">{{ listLanguage.home_wait_for_pay }}</span>
        </div>
        <img src="@/static/icon/home/icon1.svg" alt="" />
      </div>
      <div class="box_head_2 d-flex align-items-center justify-content-between" :class="userLogin.parent_id == null ? 'box_head_30' : ''">
        <div class="txt_content d-flex flex-column">
          <span class="bold_txt">{{ dashboard.paid }}</span>
          <span class="des">{{ listLanguage.home_payment_has_been_successful }}</span>
        </div>
        <img src="@/static/icon/home/icon2.svg" alt="" />
      </div>
<!--      lợi nhuận-->
      <div class="box_head_ln d-flex align-items-center justify-content-between box_head_30" v-if="userLogin.parent_id == null">
        <div class="txt_content d-flex flex-column">
          <span class="des title_ln ">Lợi nhuận đã nhận</span>
          <div class="d-flex flex-column content_ln_text">
            <div class="d-flex flex-column box_content">
              <span class="text">CNY</span>
              <span class="text">{{ dashboard.profit.amount_revenue_cn }}</span>
            </div>
            <div class="d-flex flex-column box_content ln_vnd">
              <span class="text">VNĐ</span>
              <span class="text">{{ dashboard.profit.amount_revenue_vn }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box_head_ln_dc d-flex align-items-center justify-content-between box_head_30" v-if="userLogin.parent_id == null">
        <div class="txt_content d-flex flex-column">
          <span class="des title_ln title_ln_dc">Lợi nhuận đang chờ</span>
          <div class="d-flex flex-column content_ln_text">
            <div class="d-flex flex-column box_content">
              <span class="text">CNY</span>
              <span class="text">{{ dashboard.profit.amount_spending_cn }}</span>
            </div>
            <div class="d-flex flex-column box_content ln_vnd">
              <span class="text">VNĐ</span>
              <span class="text">{{ dashboard.profit.amount_spending_vn }}</span>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="xadal_title_head">
      <span>{{ listLanguage.menu_account }}</span>
    </div>
      <div class="content d-flex content_mb">
          <div class="content_vn">
              <div class="content_2">
              <div class="d-flex align-items-center">
                <img src="@/static/icon/home/vn.svg" alt="" />
                <span class="txt_content_d pl-2">Tài khoản Việt Nam</span>
              </div>
              <div class="d-flex flex-column pt-2">
                <span class="py-2 txt_sodu">{{ listLanguage.home_current_balance }}</span>
                <span class="txt_number">{{ dashboard.total_amount.amount | vnd3 }}</span>
              </div>
            </div>
          </div>
          <div class="content_cn">
              <div class="content_2">
                <div class="d-flex align-items-center">
                  <img src="@/static/icon/home/china.svg" alt="" />
                  <span class="txt_content_d pl-2">Tài khoản TQ - taobao</span>
                </div>
                <div class="d-flex flex-column pt-2">
                  <span class="py-2 txt_sodu">{{ listLanguage.home_current_balance }}</span>
                  <span class="txt_number">{{ dashboard.total_amount.amount_convert | cn }}</span>
                </div>
              </div>
            </div>
            <div class="content_cn">
              <div class="content_2">
                <div class="d-flex align-items-center">
                  <img src="@/static/icon/home/china.svg" alt="" />
                  <span class="txt_content_d pl-2">Tài khoản TQ - 1688</span>
                </div>
                <div class="d-flex flex-column pt-2">
                  <span class="py-2 txt_sodu">{{ listLanguage.home_current_balance }}</span>
                  <span class="txt_number">{{ dashboard.total_amount.amount_convert_1688 | cn }}</span>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import AuthLogin from "@/AuthLogin";

require('@/static/css/home/list.css')
import { mapGetters, mapActions } from "vuex";
export default {
  name: "HomeIndex",
  metaInfo() {
    return {
      title: "Xadal"
    };
  },
  components: {

  },
  data() {
    return {
      loading: true,
      dashboard: [],
      userLogin: {},
    };
  },
  computed: {
    ...mapGetters("account", ["logged", "accountInfo", "listLanguage"]),
  },
  mounted() {
    console.log(AuthLogin.user);
    this.userLogin = AuthLogin.user;
    this.getAccountInfo();
    this.fetchData();
  },
  methods: {
    ...mapActions("account", ["getAccountInfo"]),
    fetchData() {
      this.loading = true
      axios.get("dashboard_client", { params: this.filter }).then((response) => {
        this.loading = false
        switch (response.code) {
          case 200:
            this.dashboard = response.data;
            break;
          case 500:
            this.$notify({
              title: "có lỗi xảy ra",
              message: response.message,
              type: "warning",
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
  },
  watch: {

  },
};
</script>

<style lang="css" scoped src="@/static/css/home/list.css">

</style>
