<template>
  <div>
    <el-dialog
      class="dialogCreateC"
        title=""
        top="50px"
        width="70%"
        :close-on-click-modal="false"
        :visible="dialogCreateComplain"
    >
      <div class="dialog_body">
        <div class="d-flex align-items-center listTab">
          <span class="tab_item itemActive">Thông tin khiếu nại</span>
          <span class="tab_item">Xử lý khiếu nại</span>
          <span class="tab_item">Đã xử lý</span>
        </div>
        <div class="content_create">
          <div class="content_left">
            <div class="title">
              <span>Thông tin đơn hàng</span>
            </div>
            <div class="data">
              <span class="txt">Mã đơn hàng</span>
              <span>{{ detailProduct.order_code }}</span>
            </div>
            <div class="data">
              <span class="txt">Mã đơn hàng mua hàng</span>
              <span>{{ detailProduct.purchase_order_outer_id }}</span>
            </div>
            <div class="data">
              <span class="txt">Thời gian đặt hàng</span>
              <span>{{ detailProduct.order_create }}</span>
            </div>
            <div class="data">
              <span class="txt">Tên nguời bán</span>
              <el-select @change="changeSeller()" size="mini" v-model="seller" placeholder="Chọn" class="select_content">
                <el-option
                    v-for="item in detailProduct.suppliers"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="listProduct"></div>
            <div class="data">
              <span class="txt">Tổng tiền thanh toán</span>
              <span class="colorRed"> ¥ {{ total | formatCurrency }}</span>
            </div>
            <div class="data">
              <span class="txt">Phí vận chuyển</span>
              <span class="colorRed">¥ {{ detailProduct.total_fee | formatCurrency }}</span>
            </div>
            <div class="data">
              <span class="txt">Tổng tiền đặt hàng</span>
              <span class="colorRed">¥ {{ detailProduct.total_price | formatCurrency }}</span>
            </div>
          </div>
          <div class="content_right">
            <div class="title">
              <span>Vui lòng điền yêu cầu hoàn tiền</span>
            </div>
            <div class="data">
              <span class="txt">Mặt hàng hoàn trả</span>
            </div>
            <div class="list_product">
              <el-row>
                <el-col :span="12" v-for="item in listProDuct" :key="item.id">
                  <div class="d-flex align-items-center py-3">
                    <el-checkbox v-if="dataCreateComplain.source_id == 1" @change="productChecked(item);" v-model="item.checked"></el-checkbox>
                    <div v-else @click="checkProductTaobao(item)" style="cursor: pointer">
                      <img style="width: 20px;height: 20px" v-if="order_detail_id == item.order_detail_id" src="@/static/icon/complainv2/radio.svg" alt="">
                      <img style="width: 20px;height: 20px" v-else src="@/static/icon/complainv2/no-radio.svg" alt="">
                    </div>
                    <img :src="item.image" alt="">
                    <div class="d-flex flex-column">
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <!--            //Phương án xử lý-->
            <div class="data">
              <span class="txt">Phương án xử lý <span class="colorRed">*</span></span>
            </div>
            <div class="data">
              <div class="d-flex align-items-center radiocheck" v-for="item in optionsPP" :key="item.key" @click="checkPP(item)">
                <img v-if="radio == item.key" src="@/static/icon/complainv2/radio.svg" alt="">
                <img v-else src="@/static/icon/complainv2/no-radio.svg" alt="">
                <span>{{ item.value }}</span>
              </div>
            </div>
            <!--            Tình trạng hàng hóa-->
            <div class="data">
              <span class="txt">Tình trạng hàng hóa <span class="colorRed">*</span></span>
            </div>
<!--            <div class="data d-flex flex-column align-items-start">-->
<!--              <div class="d-flex align-items-center radiocheck" v-for="item in optionsStatus" :key="item.key" @click="checkStatus(item)">-->
<!--                <img v-if="status == item.key" src="@/static/icon/complainv2/radio.svg" alt="">-->
<!--                <img v-else src="@/static/icon/complainv2/no-radio.svg" alt="">-->
<!--                <span>{{ item.value }}</span>-->
<!--              </div>-->
<!--            </div>-->
            <el-row>
              <el-col :span="8" v-for="item in optionsStatus" :key="item.key">
                <div class="d-flex align-items-center radiocheck py-2" @click="checkStatus(item)">
                  <img v-if="status == item.key" src="@/static/icon/complainv2/radio.svg" alt="">
                  <img v-else src="@/static/icon/complainv2/no-radio.svg" alt="">
                  <span>{{ item.value }}</span>
                </div>
              </el-col>
            </el-row>
            <!--            Lý do hoàn trả-->
            <div class="data">
              <span class="txt"> Lý do hoàn trả  <i v-if="loadingListRefund" class="el-icon-loading"></i> <span class="colorRed">*</span></span>
            </div>
            <el-select @change="changereason()" style="width: 100%" size="mini" v-model="valueRefund" placeholder="Chọn" class="select_content">
              <el-option
                  v-for="item in options"
                  :key="item.reasonId"
                  :label="item.reasonDesc"
                  :value="item.reasonId">
              </el-option>
            </el-select>
            <!--            Số tiền hoàn trả-->
            <div class="data">
              <span class="txt"> Số tiền hoàn trả <span class="colorRed">*</span></span>
            </div>
            <el-input type="number" style="width: 100%" v-model="moneyRefund"></el-input>
          </div>
        </div>
      </div>
      <div class="dialog-footer d-flex align-items-center justify-content-end pt-4">
        <el-button
          class="btn_cancel"
          @click="toCancel()"
        >Đóng</el-button>
        <el-button
          class="btnDone"
          @click="toCreate()"
          :disable="!canSubmit"
          :loading="!canSubmit"
        >Gửi yêu cầu</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateComplain",
  // metaInfo() {
  //   return {
  //     title: "Đăng kí",
  //   };
  // },
  props: {
    dialogCreateComplain: Boolean,
    dataCreateComplain: Object,
  },
  components: {},
  data() {
    return {
      canSubmit: true,
      loadingListRefund : false,
      valueRefund: "",
      options: [],
      optionsPP : [],
      optionsStatus : [],
      radio: "",
      status: "",
      url: "",
      detailProduct: {},
      seller: "",
      total: "",
      sub_item_ids: [],
      order_detail_id: "",
      moneyMax: 0,
      moneyRefund: 0,

      listProDuct: [],
      urlCreate: "",
      reason_description: "",
    };
  },
  computed: {},
  mounted() {
  },
  methods: {
    // lấy sản phẩm taobao
    checkProductTaobao(item) {
      this.order_detail_id = item.order_detail_id;
      this.fetchListRefund();
    },
    // lấy message
    fetchMessage(item) {
      this.loading = true
      let data = {
        refund_id: item,
      };
      axios.post("complain-auto/refund-list-message", data).then((response) => {
        this.loading = false;
        switch (response.code) {
          case 200:
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    changereason() {
      this.options.forEach((item) => {
        if(this.valueRefund == item.reasonId) {
          this.reason_description = item.reasonDesc;
        }
      })
    },
    toCreate() {
      if (this.radio == '') {
        this.$notify({
          title: "Vui lòng kiểm tra lại thông tin",
          message: "Phương án không được để trống",
          type: "warning",
        });
        return;
      }
      if (this.status == '') {
        this.$notify({
          title: "Vui lòng kiểm tra lại thông tin",
          message: "Tình trạng hàng hóa không được để trống",
          type: "warning",
        });
        return;
      }
      if (this.valueRefund == '') {
        this.$notify({
          title: "Vui lòng kiểm tra lại thông tin",
          message: "Lý do không được để trống",
          type: "warning",
        });
        return;
      }
      let  data = {};
      if(this.dataCreateComplain.source_id == 1) {
        this.urlCreate = "refund-detail-create";
        data = {
          plan: this.radio,
          good_status: this.status,
          reason_id: this.valueRefund,
          reason_description: this.reason_description,
          refund_fee: this.moneyRefund,
          request_payment_id: this.dataCreateComplain.id,
          sub_item_ids: this.sub_item_ids,
        };
      } else if (this.dataCreateComplain.source_id == 2) {
        this.urlCreate = "refund-submit";
        data = {
          plan: this.radio,
          good_status: this.status,
          reason_id: this.valueRefund,
          reason_description: this.reason_description,
          refund_fee: this.moneyRefund,
          request_payment_id: this.dataCreateComplain.id,
          order_detail_id: this.order_detail_id,
        };
      }
      this.canSubmit = false;
      axios.post("complain-auto/" + this.urlCreate, data).then((response) => {
        this.canSubmit = true;
        this.loadingListRefund = false;
        switch (response.code) {
          case 200:
            this.$emit('toDoneCreate');
            if(this.dataCreateComplain.source_id == 2) {
              this.fetchMessage(response.data.refund_id);
            }
            this.$notify({
              title: "Thành công",
              type: "success",
            });
            break;
          case 422:
            this.$notify({
              title: "có lỗi xảy ra",
              message: response.data.error,
              type: "warning",
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    // chọn sản phẩm {
    productChecked() {
      this.moneyMax = 0;
      this.moneyRefund = 0;
      this.listProDuct.forEach((item) => {
        if(item.checked) {
          let total_money_refund = (parseFloat(item.price) * parseFloat(item.quantity)).toFixed(2);
          let total_money_max = (parseFloat(item.price) * parseFloat(item.quantity)).toFixed(2);
          this.moneyRefund += parseFloat(total_money_refund);
          this.moneyMax += parseFloat(total_money_max)
        }
      })
    },
    // chọn người bán
    changeSeller() {
      this.detailProduct.suppliers.forEach((item) => {
        if(this.seller == item.id) {
          this.listProDuct = item.items;
          this.order_detail_id = this.listProDuct[0].order_detail_id;
          this.fetchListRefund();
        }
      })
    },
    // lấy dữ liệu detail
    fetchDetail() {
      this.loading = true
      axios.get("order/detail/" + this.dataCreateComplain.order_code ).then((response) => {
        this.loading = false;
        switch (response.code) {
          case 200:
            this.detailProduct = response.data;
            this.seller = response.data.suppliers[0].id;
            this.total = (parseFloat(this.detailProduct.total_price) - parseFloat(this.detailProduct.total_fee)).toFixed(2);
            this.listProDuct = this.detailProduct.suppliers[0].items
            this.listProDuct.map(function (i) {
              i.checked = true;
              return i;
            });
            this.listProDuct = JSON.parse(JSON.stringify(this.listProDuct));
            this.order_detail_id = this.detailProduct.suppliers[0].items[0].order_detail_id
            this.fetchListRefund();
            break;
          case 500:
            this.$notify({
              title: "có lỗi xảy ra",
              message: response.message,
              type: "warning",
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    //
    fetchListRefund() {
      if(this.radio == '' || this.status == '') {
        return
      }
      this.loadingListRefund = true;
      this.options = [];
      this.valueRefund = '';
      this.sub_item_ids = [];
      if(this.dataCreateComplain.source_id == 1) {
         this.url = "refund-reason-list"
      } else if (this.dataCreateComplain.source_id == 2) {
         this.url = "refund-render"
      }
      if(this.detailProduct) {
        this.detailProduct.suppliers.forEach((item) => {
          item.items.forEach((i) => {
            // this.order_detail_id = i.order_detail_id;
            this.sub_item_ids.push(i.sub_item_id);
          })
        })
      }
      // 1688
      let data = [];
      if(this.dataCreateComplain.source_id == 1) {
        data = {
          good_status: this.status,
          plan: this.radio,
          request_payment_id: this.dataCreateComplain.id,
          sub_item_ids: this.sub_item_ids,
        }
      } else if (this.dataCreateComplain.source_id == 2) {
        data = {
          good_status: this.status,
          plan: this.radio,
          request_payment_id: this.dataCreateComplain.id,
          order_detail_id: this.order_detail_id,
        }
      }
      axios.post("complain-auto/" + this.url, data).then((response) => {
        this.loadingListRefund = false;
        switch (response.code) {
          case 200:
            this.options = response.data.refundReasons;
            if(this.dataCreateComplain.source_id == 1) {
              this.moneyMax = 0;
              this.moneyRefund = 0;
              this.listProDuct.forEach((item) => {
                if(item.checked) {
                  let total_money_refund = (parseFloat(item.price) * parseFloat(item.quantity)).toFixed(2);
                  let total_money_max = (parseFloat(item.price) * parseFloat(item.quantity)).toFixed(2);
                  this.moneyRefund += parseFloat(total_money_refund);
                  this.moneyMax += parseFloat(total_money_max);
                }
              })
            } else {
              this.moneyMax = response.data.maxRefundFee;
              this.moneyRefund = response.data.maxRefundFee
            }
            // this.moneyRefund = response.data.maxRefundFee;
            break;
            case 422:
            this.$notify({
              title: "có lỗi xảy ra",
              message: response.data.error,
              type: "warning",
            });
              break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    // lấy dữ liệu options
    fetchData() {
      this.loading = true
      axios.post("complain-auto/get-option-refund", ).then((response) => {
        this.loading = false;
        switch (response.code) {
          case 200:
            if(this.dataCreateComplain.source_id == 1) {
              this.optionsStatus = response.data.option_1688;
              // this.status = response.data.option_1688[0].key
            } else if (this.dataCreateComplain.source_id == 2) {
              this.optionsStatus = response.data.option_taobao;
              // this.status = response.data.option_taobao[0].key
            }
            this.optionsPP = response.data.arr_plain;
            // this.radio = this.optionsPP[0].key;
            this.fetchDetail();
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    // phương án xử lý
    checkPP(item) {
      this.radio = item.key;
      this.fetchListRefund();
    },
    // tình trạng hàng hóa
    checkStatus(item) {
      this.status = item.key
      this.fetchListRefund();
    },
    toCancel() {
      this.$emit('toCancelCreate');
    },
  },
  watch: {
    dataCreateComplain:function () {
      this.moneyRefund = 0;
      this.radio = '';
      this.status = '',
      this.fetchData();
    },
    moneyRefund:function () {
      if(this.moneyRefund > this.moneyMax) {
        this.$notify({
          title: "có lỗi xảy ra",
          message: 'Số tiền hoàn trả không được lớn hơn' + this.moneyMax,
          type: "warning",
        });
      }
    }
  },
};
</script>

<style lang="css" scoped src="@/static/css/complain_v2/create.css">

</style>
<style>
  .el-dialog__headerbtn {
    /*display: none;*/
  }
</style>
