<template>
  <div class="component-header-top d-flex justify-content-between align-items-center">
    <div class="logo_bt" :class="showText == true ? 'zoomLogo' : ''">
      <a href="https://xadal.com/" target="_blank"><img style="max-width: 100%;" src="@/static/images/login/logo.svg" alt="" /></a>
      <!-- <img style="max-width: 100%;" src="@/static/images/login/logo.svg" alt="" /> -->
    </div>
    <div class="head_left d-flex align-items-center isMobile">
      <!-- // phấn số dư -->
      <div class="d-flex align-items-center pr-3">
        <!-- <span>Số dư:</span>
        <span class="pl-2" style="color: #FD9D19; font-size: 14px;">{{ amount | vnd3 }}</span> -->
        <el-dropdown>
          <span style="cursor: pointer;">Số dư: <span class="pl-2" style="color: #FD9D19; font-size: 14px;">{{ dashboard.amount | vnd3 }}</span> <i class="el-icon-arrow-down el-icon--right"></i></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <span>Tài khoản TQ - Taobao: <span class="pl-2" style="color: #FD9D19; font-size: 14px;">{{ dashboard.amount_convert | cn }}</span></span>
            </el-dropdown-item>
            <el-dropdown-item>
              <span>Tài khoản TQ - 1688: <span class="pl-2" style="color: #FD9D19; font-size: 14px;">{{ dashboard.amount_convert_1688 | cn }}</span></span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- // phần tỷ giá  -->
      <div class="d-flex align-items-center exchange_rate_head" v-if="dataVip.accTaobao">
        <div class="d-flex align-items-center exrate_box">
          <img src="@/static/icon/header-top/exrate.svg" alt="" />
          <span>Tỷ giá: </span>
        </div>
        <div class="d-flex align-items-center exrate_box">
          <span class="title">{{ dataVip.accTaobao.name }}</span>
          <span class="dess">{{ dataVip.accTaobao.price }} đ / ¥</span>
        </div>
        <div class="d-flex align-items-center exrate_box">
          <span class="title">{{ dataVip.acc1688.name }}</span>
          <span class="dess">{{ dataVip.acc1688.price }} đ / ¥</span>
        </div>
      </div>
      <!-- // -->
      <div class="d-flex align-items-center lang_content language mr-3">
          <img v-if="me.language == 'vi'" src="@/static/images/login/vn.svg" alt="" />
          <img v-if="me.language == 'cn'" src="@/static/images/login/cn.svg" alt="" />
          <img v-if="me.language == 'en'" src="@/static/images/login/en.svg" alt="" />
          <el-select
            v-model="me.language"
            size="mini"
            @change="changeLang()"
          >
            <el-option
              v-for="item in lang"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      <img src="@/static/icon/header-top/icon_1.svg" alt="">
      <div @click="showNoti()" class="noti_global" style="margin-top: 3px;">
        <img src="@/static/icon/header-top/notification.svg" alt="">
        <span class="count" v-if="me.total_noti_unread > 0">{{ me.total_noti_unread }}</span>
      </div>
      <!-- // noti  -->
      <div class="content-noti">
        <el-dialog
          :visible.sync="dialogVisibleNoti"
        >
          <div class="hader_dialog">
            <img src="@/static/icon/header-top/noti.svg" alt="" />
            <span>{{ listLanguage.menu_notification }}</span>
          </div>
          <div class="loading-content">
            <div class="body_dialog_content" v-for="item in dataNoti" :key="item.id">
              <div style="min-width: 20px">
                <div class="check_time_seen" v-if="item.time_seen == 0"></div>
              </div>
              <div class="content_text_noti d-flex align-items-center" @click="seenNoti(item)">
                <span>{{ item.created_at }}</span>
                <span class="pl-1">{{ item.content }}</span>
              </div>
            </div>
            <infinite-loading @infinite="infiniteHandler"></infinite-loading>
          </div>

          <!-- //  -->
          <div class="d-flex align-items-center justify-content-center tick_all_noti" @click="clickAllNoti()">
            <img src="@/static/icon/header-top/tickall.svg" alt="" />
            <span>{{ listLanguage.menu_notification_mark_as_read }}</span>
          </div>
        </el-dialog>
      </div>
      <!-- end noti -->
      <img src="@/static/icon/header-top/user.svg" alt="">
      <img @click="showConfig()" src="@/static/icon/header-top/setting.svg" alt="">
      <!-- <img src="@/static/icon/header-top/more.svg" alt=""> -->
      <el-dropdown>
        <img src="@/static/icon/header-top/more.svg" alt="">
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <div class="d-flex align-items-center">
              <img class="pr-2" src="@/static/icon/header-top/nh.svg" alt="" />
              <span @click="toBank()">Ngân hàng</span>
            </div>
          </el-dropdown-item>
          <el-dropdown-item>
            <div class="d-flex align-items-center">
              <img class="pr-2" src="@/static/icon/header-top/export.svg" alt="" />
              <span @click="confirmLogout()">{{ listLanguage.menu_log_out }}</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- //  -->
    <div class="isMobileRight">
      <i @click="drawerIcon = true" class="el-icon-s-grid"></i>
    </div>

    <el-drawer
        title="Modal change account password"
        :visible.sync="drawerIcon"
        :direction="'rtl'"
        size="70%"
        :with-header="false"
        custom-class="account-drawer-change-pw"
      >
        <div class="d-flex flex-column">
          <div class="">
            <div class="d-flex align-items-center lang_content language mr-3">
              <img v-if="langInput == 'vi'" src="@/static/images/login/vn.svg" alt="" />
              <img v-if="langInput == 'cn'" src="@/static/images/login/cn.svg" alt="" />
              <img v-if="langInput == 'en'" src="@/static/images/login/en.svg" alt="" />
              <el-select
                v-model="me.language"
                size="mini"
                @change="changeLang()"
              >
                <el-option
                  v-for="item in lang"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <!-- <div class="menu_item_head">
            <img src="@/static/icon/header-top/icon_1.svg" alt="">
          </div> -->
          <div class="menu_item_head d-flex align-items-center">
            <img class="pr-2" src="@/static/icon/header-top/notification.svg" alt="">
            <span>Thông báo</span>
          </div>
          <div class="menu_item_head d-flex align-items-center" @click="showConfig()">
            <img class="pr-2" src="@/static/icon/header-top/setting.svg" alt="">
            <span>Cấu hình tài khoản</span>
          </div>
          <!-- <div class="menu_item_head">
            <img src="@/static/icon/header-top/user.svg" alt="">
          </div> -->

          <div class="menu_item_head d-flex align-items-center" v-if="dataVip.accTaobao">
            <div class="d-flex align-items-center exrate_box">
              <img src="@/static/icon/header-top/exrate.svg" alt="" />
              <span class="pl-2">Tỷ giá: </span>
            </div>
            <div class="d-flex align-items-center exrate_box">
              <span class="title">{{ dataVip.accTaobao.name }}</span>
              <span class="dess">{{ dataVip.accTaobao.price }} đ / ¥</span>
            </div>
          </div>
          <div class="menu_item_head d-flex align-items-center" v-if="dataVip.accTaobao">
            <div class="d-flex align-items-center exrate_box">
              <img src="@/static/icon/header-top/exrate.svg" alt="" />
              <span class="pl-2">Tỷ giá: </span>
            </div>
            <div class="d-flex align-items-center exrate_box">
              <span class="title">{{ dataVip.acc1688.name }}</span>
              <span class="dess">{{ dataVip.acc1688.price }} đ / ¥</span>
            </div>
          </div>

          <div class="menu_item_head d-flex align-items-center">
            <img class="pr-2" src="@/static/icon/header-top/nh.svg" alt="" />
            <span @click="toBank()">Ngân hàng</span>
          </div>

          <div class="menu_item_head d-flex align-items-center">
            <img class="pr-2" src="@/static/icon/header-top/more.svg" alt="">
            <span @click="confirmLogout()">Thoát tài khoản</span>
          </div>
        </div>
      </el-drawer>

    <!--  -->

    <!-- // cấu hình tài khoản  -->
      <el-dialog
        :title="listLanguage.account_configuration"
        top="50px"
        width="40%"
        class="pop_up_config"
        :close-on-click-modal="false"
        :visible.sync="dialogConfig"
      >
        <div class="add_user_form">
          <el-form
            :model="formConfig"
            ref="add-user-form"
            autocomplete="off"
          >
            <el-form-item :label="listLanguage.account_select_website">
              <el-select
                v-model="formConfig.website"
                placeholder="-- Chọn --"
                class="frame-select-common-2022"
                popper-class="frame-select-popper-common-2022"
                @change="changeWebsite()"
                style="width: 100%"
              >
                <el-option
                  v-for="item in listWeb"
                  :key="item.name"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="listLanguage.account_id_staff">
              <el-input
                v-model="formConfig.user_id"
                class="frame-input-common-2022"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-if="formConfig.website == 'taobao'" v-model="formConfig.is_auto_create_request_payment">Tự động chuyển đơn Taobao sang Xadal</el-checkbox>
              <el-checkbox v-else v-model="formConfig.is_auto_create_request_payment_1688">Tự động chuyển đơn 1688 sang Xadal</el-checkbox>
            </el-form-item>
            <el-form-item :label="listLanguage.account_name">
              <el-input
                v-model="formConfig.user_name"
                class="frame-input-common-2022"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item :label="listLanguage.account_password">
              <el-input
                type="password"
                v-model="formConfig.password"
                class="frame-input-common-2022"
                autocomplete="off"
              ></el-input>
            </el-form-item>

          </el-form>

          <div class="btnSubmitForm d-flex justify-content-end align-items-center">
            <el-button @click="dialogConfig = false">{{ listLanguage.common_go_back }}</el-button>
            <el-button
              :disable="!canSubmit"
              :loading="!canSubmit"
              @click="submitFormUpdate()"
              class="btnSubmit"
            >
              <span>{{ listLanguage.common_confirm }}</span>
            </el-button>
          </div>

        </div>
      </el-dialog>

  </div>
</template>

<script>
import EventBus from '@/EventBus'
import axios from "axios";
// import ls from "local-storage";
import { mapActions, mapGetters } from "vuex";
import InfiniteLoading from 'vue-infinite-loading';
import AuthLogin from "@/AuthLogin";
require('@/static/css/layout/header.css')


export default {
  name: "LayoutHeaderTop",
  components: {
    InfiniteLoading,
  },
  props: {
    showText: Boolean,
    loadNoti: Number,
  },

  data() {
    return {
      seach: "",
      drawerIcon: false,
      showMenuTop: false,
      dialogVisibleNoti: false,
      canSubmit: true,
      userInfo: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      dataVip:[],
      list: [],
      customer_id: "",

      filter: {
        page: 1,
        per_page: 10,
      },

      // ngôn ngữ
      langInput: "vi",

      me: {},

      lang: [
        {
          name: "Tiếng Việt",
          value: "vi",
        },
        {
          name: "English",
          value: "en",
        },
        {
          name: "中国人",
          value: "cn",
        },
      ],

      // list Noti
      dataNoti: [],

      pagination: {
        total: 0,
        count: 0,
        per_page: 0,
        current_page: 1,
        total_pages: 0,
      },

      listWeb: [
        {
          name: "Taobao",
          value: "taobao",
        },
        {
          name: "1688",
          value: "1688",
        },
      ],
      dialogConfig: false,
      listCustomerConfig: {},
      formConfig: {
        website: "taobao",
        user_id: "",
        user_name: "",
        password: "",
        is_auto_create_request_payment: false,
        is_auto_create_request_payment_1688: false,
      },
      amount: {},
      dashboard: {
        amount: 0,
        amount_convert: 0,
        amount_convert_1688: 0
      },

      checkLoadSD: "",
    };
  },
  computed: {
    ...mapGetters("account", ["accountInfo", "listLanguage"]),
    ...mapGetters(["path", "full_path"]),

  },
  mounted() {
    EventBus.$on('loadSD', this.handlerSD);
    this.customer_id = AuthLogin.user.id;
    // document.addEventListener("click", this.documentClick);
    this.fetchMe();
    this.fetchDataVip();
    this.fetchSodu();

    this.socketNoti();
  },
  destroyed() {
    // document.removeEventListener("click", this.documentClick);
  },
  methods: {
    handlerSD(e) {
      this.checkLoadSD = e;
    },
    pushRouter(name, params = '') {
      this.$router.push({ name: name, params: params }).catch(() => {})
    },
    toBank() {
      this.pushRouter("partner.bank.index");
    },
    fetchDataVip(){
      axios.get("/user/infor-user").then((response) => {
          this.dataVip = response.data
      });
    },
    showConfig() {
      this.dialogConfig = true;
      if(this.formConfig.website == 'taobao') {
        this.formConfig.is_auto_create_request_payment = this.listCustomerConfig.is_auto_create_request_payment == 1 ? true : false;
        this.formConfig.user_id = this.listCustomerConfig.taobao_user_id;
        this.formConfig.user_name = this.listCustomerConfig.taobao_user_name;
        this.formConfig.password = this.listCustomerConfig.taobao_user_password;
      } else {
        this.formConfig.is_auto_create_request_payment_1688 = this.listCustomerConfig.is_auto_create_request_payment_1688 == 1 ? true : false;
        this.formConfig.user_id = this.listCustomerConfig.m1688_user_id;
        this.formConfig.user_name = this.listCustomerConfig.m1688_user_name;
        this.formConfig.password = this.listCustomerConfig.m1688_user_password;
      }
    },
    changeWebsite() {
      if(this.formConfig.website == 'taobao') {
        this.formConfig.user_id = this.listCustomerConfig.taobao_user_id;
        this.formConfig.user_name = this.listCustomerConfig.taobao_user_name;
        this.formConfig.password = this.listCustomerConfig.taobao_user_password;
        this.formConfig.is_auto_create_request_payment = this.listCustomerConfig.is_auto_create_request_payment == 1 ? true : false;
      } else {
        this.formConfig.user_id = this.listCustomerConfig.m1688_user_id;
        this.formConfig.user_name = this.listCustomerConfig.m1688_user_name;
        this.formConfig.password = this.listCustomerConfig.m1688_user_password;
        this.formConfig.is_auto_create_request_payment_1688 = this.listCustomerConfig.is_auto_create_request_payment_1688 == 1 ? true : false;
      }
    },
    submitFormUpdate() {
      this.canSubmit = false;
      let data = {};
      data.email = AuthLogin.user.email;
      data.name  = AuthLogin.user.name;
      data.id  = AuthLogin.user.id;
      data.is_auto_create_request_payment = this.formConfig.is_auto_create_request_payment;
      data.is_auto_create_request_payment_1688 = this.formConfig.is_auto_create_request_payment_1688;
      data.website = this.formConfig.website;
      if(this.formConfig.website == 'taobao') {
        data.taobao_user_id = this.formConfig.user_id;
        data.taobao_user_name = this.formConfig.user_name;
        data.taobao_user_password = this.formConfig.password;
        data.m1688_user_id = this.listCustomerConfig.m1688_user_id;
        data.m1688_user_name = this.listCustomerConfig.m1688_user_name;
        data.m1688_user_password = this.listCustomerConfig.m1688_user_password;
      } else {
        data.m1688_user_id = this.formConfig.user_id;
        data.m1688_user_name = this.formConfig.user_name;
        data.m1688_user_password = this.formConfig.password;
        data.taobao_user_id = this.listCustomerConfig.taobao_user_id;
        data.taobao_user_name = this.listCustomerConfig.taobao_user_name;
        data.taobao_user_password = this.listCustomerConfig.taobao_user_password;
      }
      axios.post("/user/update/" + this.customer_id, data).then((response) => {
        this.canSubmit = true;
        switch (response.code) {
          case 200:
            this.$notify({
              title: "Thành công",
              message: response.message,
              type: "success",
            });
            this.dialogConfig = false;
            this.fetchMe();
            break;
          case 400:
            this.$notify({
              title: "Thất bại",
              message: response.errors[0],
              type: "warning",
            });
            break;
          case 500:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: response.message,
              type: "warning",
            });
            break;
          case 422:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: Object.values(response.data)[0],
              type: "warning",
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    // đánh dấu tất cả đã đọc
    clickAllNoti() {
      this.dataNoti.forEach((i) => {
        i.time_seen = 1
      });
      axios.post("notification/click-all-noti").then((response) => {
        switch (response.code) {
          case 200:
            this.fetchMe();
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    // xem từng noti
    seenNoti(item) {
      if(item.time_seen == 0) {
        axios.post("notification/click-noti/" + item.id).then((response) => {
          switch (response.code) {
            case 200:
              if (this.me.total_noti_unread > 0) {
                this.me.total_noti_unread = this.me.total_noti_unread - 1;
              }
              this.dataNoti.forEach((i) => {
                if(item.id == i.id) {
                  i.time_seen = 1
                }
              });
              this.fetchMe();
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      }
    },
    // đổi ngôn ngữ
    // AuthLogin.user
    changeLang() {
      let data = {
        email: AuthLogin.user.email,
        name: AuthLogin.user.name,
        id: AuthLogin.user.id,
        language: this.me.language,
      }
      axios.post("user/update/" + AuthLogin.user.id, data).then((response) => {
        switch (response.code) {
          case 200:
            location.reload();
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    //
    showNoti() {
        this.dialogVisibleNoti = true;
        this.filter.page = 1;
        this.fetchData();

    },

    infiniteHandler($state) {
      this.fetchData(true, $state);
    },

  // lấy dữ liệu
    fetchData(loading = true, $state = '') {
      if (loading) {
        this.loading = true;
      }
      axios.get("notification", { params: this.filter }).then((response) => {
        this.loading = false
        switch (response.code) {
          case 200:
            // this.tableData = response.data.results.items;

            var dataItems      = response.data.items;
            var dataPagination = response.data.pagination;

            if ($state) {
              if (this.filter.page > 1) {
                this.dataNoti.push(...dataItems);
              }

              this.filter.page += 1;
            } else {
              this.dataNoti = dataItems;
            }

            this.pagination.total    = dataPagination.total;
            this.pagination.per_page = dataPagination.per_page;

            if ($state) {
              if (this.filter.page > dataPagination.total_pages) {
                $state.complete();
              } else {
                $state.loaded();
              }
            }


            break;
            case 500:
            this.$notify({
              title: "có lỗi xảy ra",
              message: response.message,
              type: "warning",
              });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },

    fetchMe() {
      axios.get("user/edit/" + this.customer_id).then((response) => {
        switch (response.code) {
          case 200:
            this.me = response.data.user;
            this.listCustomerConfig = response.data.customer_config
            console.log(response.data.customer_config);
            break;
            case 500:
            this.$notify({
              title: "có lỗi xảy ra",
              message: response.message,
              type: "warning",
              });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },


    // documentClick(e) {
    //   let el = this.$refs.dropdownProfile;
    //   let target = e.target;
    //   if (el && el !== target && !el.contains(target)) {
    //     this.showMenuTop = false;
    //   }
    // },

    ...mapActions("account", ["removeAccountInfo"], ["getAccountInfo"]),
    mouseLeave() {
      this.showMenuTop = false;
    },
    confirmLogout() {
      this.$confirm(this.listLanguage.common_confirm + ' ' + this.listLanguage.menu_log_out, "Warning", {
        confirmButtonText: this.listLanguage.common_ok,
        cancelButtonText: this.listLanguage.common_cancel,
        type: "warning",
      })
        .then(() => {
          AuthLogin.logout();
          this.removeAccountInfo();
        })
        .catch(() => {});
    },

    // lấy số dư
    fetchSodu() {
      this.loading = true
      axios.get("dashboard_client", { params: this.filter }).then((response) => {
        this.loading = false
        switch (response.code) {
          case 200:
            this.amount = response.data.total_amount.amount;
            this.dashboard = response.data;
            this.dashboard = {
              amount: response.data.total_amount.amount,
              amount_convert: response.data.total_amount.amount_convert,
              amount_convert_1688: response.data.total_amount.amount_convert_1688,
            };
            break;
          case 500:
            this.$notify({
              title: "có lỗi xảy ra",
              message: response.message,
              type: "warning",
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },

    socketNoti() {
      // Join vào room
      this.$socket.emit("join-to-room-code", AuthLogin.user.code);
    },

  },

  watch: {
    // showText: function() {
    //   console.log('showText2222', this.showText);
    // }
    // load lại số noti khi chọn tác vụ mấy màn khác
    loadNoti: function() {
      this.fetchMe();
    },
    'checkLoadSD':function() {
      this.fetchSodu();
    }
  },

  sockets: {
    connect() {
      this.socketNoti();
    },
    "xadal-noti": function (data) {
      console.log(data);
      this.me.total_noti_unread += 1;
    },
  }
};
</script>

<style lang="css" scoped src="@/static/css/layout/header.css">
</style>
<style>

.isMobileRight {
  display: none;
}
@media only screen and (max-width: 768px), (max-width: 1023px) {
  .isMobile {
    display: none !important;
  }
  .isMobileRight {
    display: block;
  }
  .component-header-top .el-message-box {
    width: 100%;
  }
  .pop_up_config .el-dialog{
    width: 98% !important;
  }
}
.component-header-top .el-drawer__body {
  padding: 10px;
}
.menu_item_head {
  padding: 20px 0;
  border-bottom: 1px solid #294C94;
}
.logo_bt {
  width: 86px;
}
.zoomLogo {
  width: 15%;
}
  .menu_item_head .title {
    color: var(--14, #FFF);
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    padding: 5px;
    background: #962DFF;
    margin-right: 10px;
    border-radius: 4px;
    text-transform: capitalize;
    margin-left: 10px;
  }

</style>
