<template>
  <div id="app">
      <div class="is-login
      ">
        <div v-if="isLogin" class="headerTop">
          <header-top :showText="showText" :loadNoti="loadNoti"></header-top>
        </div>
        <div v-if="isLogin" class="menuleftMb">
            <menu-left-mb></menu-left-mb>
        </div>
        <div class="d-flex contenView" :class="[logged == false ? 'full-contenView' : '']">
          <div class="app-menu-left" v-if="logged" :class="[showText ? 'app-menu-left-full' : '']">
            <menu-left @toggleMenu="toggleMenuParent"></menu-left>
          </div>
          <div
            class="content-right app-content-right"
            :class="[showText ? 'app-content-right-full' : '' || logged == false ? 'full-screen' : '' | path == 'api-center' ? 'padding0' : '' ]"
          >
            <transition :name="logged ? 'slide-fade' : ''">
              <router-view @checkNotiNummber="checkNotiNummber" />
            </transition>
          </div>
        </div>
      </div>
      <div class="not-login">
        <!-- <router-view /> -->
      </div>
    </div>
</template>

<script type="text/javascript">
import { mapGetters, mapActions, mapState } from "vuex";
import ls from "local-storage";
import MenuLeft from "@/views/layout/MenuLeft";
import MenuLeftMb from "@/views/layout/MenuLeftMb";
import HeaderTop from "@/views/layout/HeaderTop";
import AuthLogin from "@/AuthLogin";

export default {
  name: "App",
  metaInfo () {
    return {
      title: this.title,
      titleTemplate: 'Xadal | %s',
      showText: false,

      
    }
  },
  components: {
    MenuLeft,
    HeaderTop,
    MenuLeftMb,
  },
  computed: {
    ...mapState(
      {
        accountInfo: state => state.accountInfo
      }),
    ...mapGetters("account", ["logged"]),
    ...mapGetters(["path", "full_path"])
  },
  data() {
    return {
      showText: false,
      isLogin: false,

      loadNoti: 1
    };
  },
  created() {
    this.getLanguage();
    this.checkIsLogin();
  },
  mounted() {
    if(AuthLogin.check()) {
      this.checkLogin = true;
    }
    this.checkLoged();
    if(this.path == "") {
      this.$router.push({ name: "home-index" });
    }
  },
  methods: {
    ...mapActions("account", ["getAccountInfo", "getLanguage"]),
    checkIsLogin() {
      let token   = ls.get("token");
      this.isLogin = token ? true : false;
      if(this.isLogin == true) {
        this.getLanguage();
        console.log(1);
      }
    },
    checkLoged() {
      let token = ls.get("token");
      if (!token) {
        if (this.path !== "register") {
          this.$router.push({ name: "account-login" });
        }
      } else {
        if (!this.logged) {
          this.getAccountInfo();
        }
      }
    },

    toggleMenuParent(value) {
      this.showText = !value;
    },

    // load noti khi chọc các tác vụ 
    checkNotiNummber(value) {
      this.loadNoti = value
    },

  },
  watch: {
    logged(val) {
      this.isLogin = val ? true : false;
    },
  }
};
</script>

<style>
.headerTop {
  height: 50px;
}
.contenView {
  /* height: calc(100vh - 50px); */
  /* overflow-y: scroll; */
  width: 100%;
  background: #F2F3F7;
  /* padding-bottom: 5%; */
  height: 100%;

}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.full-screen {
  /* height: 100%; */
  width: 100% !important;
  padding: 0 !important;
  background: #fff !important;
}
.full-contenView {
  width: 100%;
  height: calc(100vh);
}
.content-right {
  width: calc(100% - 115px);
  background: #F2F3F7;
  padding-left: 15px;
  padding-right: 15px;
  height: 100%;
}
.app-content-right-full {
  width: 85% !important;
  transition: 0.3s;
}
.app-menu-left {
  min-width: 86px;
  min-height: 100vh;
  background: #00194c;
}
.app-menu-left-full {
  width: 15% !important;
}
.padding0 {
  padding: 0 !important;
  width: 100%;
}

.menuleftMb {
  display: none;
}

.menuleftDt {
  display: block;
}

@media only screen and (max-width: 768px), (max-width: 1023px) {
  .menuleftMb {
    display: block
  }
  .app-menu-left {
    display: none;
  }
  .content-right {
    width: 100%;
    padding: 10px;
    overflow-x: hidden;
  }
}
</style>
