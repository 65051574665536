<template>
    <div class="payment-request-index" id="ST2023">
      <!--<BreadcrumbTop text="Trang chủ" text2="Tìm kiếm sản phẩm" />-->
      <div class="xadal_title_head">
        <span>{{ listLanguage.search_title }}</span>
      </div>
      <FilterShop :sources="sources" :filter="filter" :canSubmit="canSubmit" @search="search" />
      <Items :loading="loading" :table-data="tableData" :pagination="pagination" :filter="filter"
        :showProduct="showProduct" @search="changePage" />
    </div>
  </template>

  <script>
  import axios from "axios";
  //import BreadcrumbTop from "@/components/BreadcrumbTop";
  import FilterShop from "./components/search/Filter.vue";
  import Items from "./components/search/Items.vue";
  import { mapGetters } from "vuex";

  export default {
    name: "ShoppingSearch",
    metaInfo() {
      return {
        title: "Tìm kiếm",
      };
    },
    components: {
      //BreadcrumbTop,
      FilterShop,
      Items
    },
    data() {
      return {
        loading: false,
        listStatus: [],
        tableData: [],
        filter: {
          source: 'taobao',
          search: '',
          price_from: '',
          price_to: '',
          per_page: 24,
          page: 1
        },
        totalData: {},
        pagination: {
          total: 0,
          page: 1,
          per_page: 24
        },
        sources: [
          {
            val: 'taobao',
            name: 'Taobao'
          },
          {
            val: '1688',
            name: '1688'
          }
        ],
        canSubmit: true,
        showProduct: false
      };
    },
    computed: {
      ...mapGetters("account", ["logged", "accountInfo", "listLanguage"]),

    },
    mounted() {
      if(this.$route.query.search) {
        this.filter.search = this.$route.query.search;
        this.fetchData();
      }
    },
    methods: {
      // lấy dữ liệu
      async fetchData() {
        this.loading = true;

        if (!this.filter.search) {
          this.$notify({
            title: "Vui lòng kiểm tra lại thông tin",
            message: "Vui lòng nhập từ khóa cần tìm kiếm",
            type: "warning",
          });
          this.loading   = false;
          this.canSubmit = true;
          return;
        }

        this.canSubmit = false;

        this.checkSearchLink();

        const data = {
          search: this.filter.search,
        }
        const filter = {
          ...this.filter,
          is_china_freeshiping: Number(this.filter.is_china_freeshiping),
        }
        delete filter.search;
        //console.log(data, filter);
        const response = await axios.post("cart/product/search", data, { params: filter });

        if (response.code !== 200) {
          this.$notify({
            title: "Có lỗi xảy ra",
            message: response.message,
            type: "warning",
          });
          this.loading   = false;
          this.canSubmit = true;
          return;
        }

        const responseData = response?.data;
        // trường hợp tìm kiếm theo link hoặc id, có thể không tìm thấy sản phẩm
        if (!responseData) {
          this.$notify({
            title: "Có lỗi xảy ra",
            message: 'Không tìm thấy sản phẩm tương ứng',
            type: "warning",
          });
          this.tableData = [];
          this.loading   = false;
          this.canSubmit = true;
          return;
        }
        // trường hợp tìm kiếm theo từ khóa sẽ ở dưới này
        //console.log(responseData, 'data res');
        this.setDataFilter(responseData);
        //console.log(this.pagination, 'page');

        this.showProduct = true;
        this.loading     = false;
        this.canSubmit   = true;
      },
      setDataFilter(responseData) {
        //console.log(responseData, 'res');
        let source = this.filter.source;

        switch (source) {
          case 'taobao':
            this.tableData = responseData?.resultList || [];
            this.pagination.total = responseData?.totalResults || 1;
            break;
          case '1688':
            this.tableData           = responseData?.data || [];
            this.pagination.total    = responseData?.totalRecords || 1;
            this.pagination.page     = responseData?.totalPage || 1;
            this.pagination.per_page = responseData?.pageSize || 1;
            break;
        }
      },
      search(data) {
        this.filter      = data;
        this.filter.page = 1;

        this.fetchData();
      },
      changePage(data) {
        this.filter = data;

        this.fetchData();
      },
      checkSearchLink() {
        let search = this.filter.search;
        if (search.indexOf('http') !== -1) {
          let productId = this.getProductIdFromLink(search);
          //console.log(productId, 'pId');
          this.toDetail(productId);
          return;
        }
      },
      getProductIdFromLink(search) {
        let productId = '';

        let is1688 = search.indexOf('1688.com') !== -1;
        if (is1688) {
          let startIndex = search.indexOf('offer/');
          let endIndex   = search.indexOf('.html');

          return search.substring(startIndex + 6, endIndex);
        }
        // tao bao, tmall
        let isTaobao = search.indexOf('taobao.com') !== -1 || search.indexOf('tmall.com') !== -1;
        if (isTaobao) {
          let startIndex = search.indexOf('?id=') !== -1 ? search.indexOf('?id=') : search.indexOf('&id=');
          if (startIndex == -1) {
            return productId;
          }

          let endIndex   = search.indexOf('&', startIndex + 4);
          //console.log(search, startIndex, endIndex, 'check');

          if (endIndex > 0) {
            return search.substring(startIndex + 4, endIndex);
          }

          return search.substr(startIndex + 4);
        }

        return productId;
      },
      toDetail(itemId, target = 'internal') {
        if (!itemId) {
          return;
        }

        let targets = ['internal', 'external'];
        let filter  = this.filter;

        if (targets.includes(target)) {
          window.open(
              this.$router.resolve({
                name: "shopping-detail",
                query: {search: filter.search, itemId: itemId, source: filter.source},
              }).href
              ,
            '_blank'
          );
        }
      }
    },
    watch: {
      //
    },
  };
  </script>

  <style lang="css" scoped>

  </style>
