<template>
  <div class="home-index frame-content-common-2022">
    <el-dialog
        top="5%"
        width="90%"
        :visible="dialogCreateMany"
        :show-close="false"
        title="Yêu cầu ký gửi"
    >
      <div class="body-dialog" v-if="showForm == false">
        <el-table
            ref="multipleTable"
            :data="dataDeposit"
            style="width: 100%"
            v-loading="loading"
            @selection-change="handleSelection"
        >
          <el-table-column type="selection" width="50" align="center">
          </el-table-column>
          <el-table-column type="index" align="center" label="STT"
          ></el-table-column>
          <el-table-column align="center" label="Mã đơn hàng" min-width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.order_code }}</span>
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center justify-content-center">
                  <div>
                  <span class="source-name">
                {{ scope.row.source_name }}
                </span>
                  </div>
                  <div class="pl-1 source_type">
                    <span class="source-name" v-if="scope.row.source_type == 1">Xadal</span>
                    <span class="source-name" v-if="scope.row.source_type == 2">Add On</span>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="Mã đặt hàng" min-width="120">
            <template slot-scope="scope">
              {{ scope.row.purchase_order_outer_id }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="TK mua hàng" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ scope.row.user_name }}</div>
              {{ scope.row.user_name_payment }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="Ghi chú" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.note }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="Ngày thanh toán">
            <template slot-scope="scope">
              <span>{{ scope.row.payment_sent_date }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="Tổng tệ">
            <template slot-scope="scope">
              <div class="money">{{ scope.row.foreign_currency | formatCurrency }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="Giá trị giao dịch">
            <template slot-scope="scope">
              <span class="money-transaction">{{ scope.row.transaction_value | vnd }}</span>
              <div v-if="scope.row.vip_price > 0" style="color: #000;">
                {{ scope.row.vip_price | vnd2 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="Tình trạng">
            <template slot-scope="scope">
              <span>{{ scope.row.status_text }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div
            class="china-panigation-1988 d-flex justify-content-end pt-2"
        >
          <el-pagination
              background
              layout="sizes, prev, pager, next"
              :page-size="pagination.per_page"
              :total="pagination.total"
              :current-page="filter.page"
              @current-change="changeCurrentPage"
              :page-sizes="[15, 50, 75, 100]"
              @size-change="handleSizeChange"
          >
          </el-pagination>
        </div>

        <div class="footer-dialog d-flex align-items-center justify-content-end" style="margin-top: 15px;">
          <el-button class="btnToCancel" @click="cancelCreateMany();">Trở về</el-button>
          <el-button class="btnDone" @click="changeShowForm()">Gửi yêu cầu</el-button>
        </div>
      </div>

<!--      // phần form -->

      <div v-else>
        <FormCreateMany
          :dataSelect="dataSelect"
          @cancelForm="cancelForm"
          @backList="backList"
          @createDoneForm="createDoneForm"
        ></FormCreateMany>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import FormCreateMany from "@/views/deposit/FormCreateMany";
export default {
  name: "CreateManyDeposit",
  props: {
    dialogCreateMany: Boolean,
  },
  metaInfo() {
    return {
      title: "Tạo dơn ký gửi",
    };
  },
  components: {
    FormCreateMany
  },
  data() {
    return {
      canSubmit: true,
      loading: true,
      showForm: false,
      filter: {
        status_deposit: 1,
        page: 1,
        per_page: 15
      },
      dataDeposit: [],
      pagination: {
        total: 0,
        page: 0,
        per_page: 0
      },
      dataSelect: [],
    };
  },
  computed: {},
  mounted() {
  },
  methods: {
    createDoneForm() {
      this.$emit('doneCreateMany');
    },
    backList() {
      this.dataSelect = [];
      this.showForm = false;
    },
    cancelForm() {
      this.showForm = true;
      this.cancelCreateMany();
    },
    changeShowForm() {
      if (this.dataSelect.length == 0) {
        this.$notify({
          title: "Có lỗi xảy ra",
          message: "Vui lòng chọn đơn cần tạo",
          type: "warning",
        });
        return;
      }
      this.showForm = true;
    },
    handleSelection(row) {
      this.dataSelect = [];
      row.map(function (i) {
        i.note = "";
        i.package_number = 1;
        i.is_woodworking = false;
        i.is_shock = false;
        i.is_inspection = false;
        i.is_insurance = false;
        return i;
      });
      row = JSON.parse(JSON.stringify(row));
      this.dataSelect = row;
    },
    cancelCreateMany() {
      this.$emit('cancelCreateMany');
    },
    fetchData(loading = true) {
      if (loading) {
        this.loading = true
      }
      axios.get("request_payment", {params: this.filter}).then((response) => {
        this.loading = false;
        switch (response.code) {
          case 200:
            this.dataDeposit = response.data.items;
            this.pagination = response.data.pagination;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    // phân trang
    handleSizeChange(val) {
      // eslint-disable-next-line vue/no-mutating-props
      this.filter.per_page = val;
      this.fetchData();
    },
    // Thay đổi Current Page
    changeCurrentPage(currentPage) {
      this.filter.page = currentPage;
      document.getElementById("ST2023").scrollIntoView({behavior: "smooth"});
      this.fetchData();
    },
  },
  watch: {
    dialogCreateMany: function () {
      this.fetchData();
    }
  },
};
</script>

<style lang="css" scoped src="@/static/css/deposit/create.css">

</style>
