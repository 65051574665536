import ls from "local-storage";
import router from "@/router";
import { SET_ACCOUNT_INFO, REMOVE_ACCOUNT_INFO, SET_LANGUAGE} from "@/store/mutation-types";

export default {
  [SET_ACCOUNT_INFO](state, accountInfo) {
    state.accountInfo = accountInfo;
    state.logged = true;
  },
  [REMOVE_ACCOUNT_INFO](state) {
    ls.remove("token");
    document.cookie = "x-client=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "info=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    state.accountInfo = {};
    state.logged = false;
    router.push({ name: "account-login" });
  },
  [SET_LANGUAGE](state, listLanguage) {
    state.listLanguage = listLanguage;
  },
};
