<template>
    <div class="product-description">
     {{ listLanguage.search_product_description }}
      <div>
        <div v-html="description"></div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  export default {
    name: "ShoppingProductDescription",
    props: {
      description: String,
    },
    metaInfo() {
      return {
        title: "Thông tin",
      };
    },
    components: {},
    data() {
      return {
        loading: true,
      };
    },
    computed: {
      ...mapGetters("account", ["logged", "listLanguage"]),
    },
    mounted() {
    },
    methods: {
      ...mapActions("account", ["getAccountInfo"]),
    },
    watch: {},
  };
  </script>
  
  <style lang="css" scoped>
    .product-description {
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
    }
  </style>
  <style>
  .product-description img {
    max-width: 100%;
  }
</style>
  