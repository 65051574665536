<template>
    <div class="product-table mt-3">
      <!-- DANH SÁCH SẢN PHẨM -->
      <el-row :gutter="24" :loading="true" style="min-height: 300px;">
        <!-- KHI CHƯA CHỌN CATEGORY -->
        <p class="text-center mt-5" v-if="!showProduct && !loading">{{ listLanguage.search_please_select }}</p>
        <!-- ĐANG CHỜ LOAD SẢN PHẨM -->
        <div v-if="loading" class="loading-overlay">
          <div class="spinner"></div>
        </div>
        <!-- ĐÃ LOAD SẢN PHẨM XONG -->
        <p class="text-center mt-5" v-if="showProduct && !tableData.length">{{ listLanguage.search_not_be_found }}</p>
        <el-col :xs="12" :sm="6" :md="4" :lg="4" v-for="item in tableData" :key="item.id">
          <div class="box-product my-4" v-if="formSearch.source == 'taobao'">
            <div>
              <div class="item-image" v-bind:style="{ backgroundImage: 'url(' +
                (item.picUrl ? item.picUrl : require('@/static/images/product/default.png')) +
                ')' }" @click="toDetail(item)">
                <!--<div class="d-flex freeship">-->
                  <!--<img src="@/static/icon/search/delivery.svg" alt="" style="align-self: center;" />-->
                  <!--<div style="color: #FFFFFF; align-self: center;"> -->
                    <!--{{ (item.is_china_freeshiping == 0 && item.delivery_fee > 0) ? item.delivery_fee : 'Freeship' }}-->
                  <!--</div>-->
                <!--</div>-->
              </div>
            </div>
            <div class="content">
              <div class="txt-sp hoverPointer" @click="toDetail(item)">
                <span>{{ item.title }}</span>
              </div>
              <div class="price-content pt-2">
                <span class="txt-red">¥ {{ (item.promotionPrice > 0 && item.promotionPrice < item.price) ?
                  (item.promotionPrice/100) : (item.price/100) }}</span>
                <!--<span class="pb-1 txt-red">{{ configList.exchange_rate * ((item.promotionPrice > 0 && item.promotionPrice < item.price) ? item.promotionPrice : item.price) | vnd }}</span>-->
                <span class="txt-sale">
                      {{ formatItemSales(item.salesVolume) || 0 | vnd4 }}<span v-if="formatItemSales(item.salesVolume) && item.salesVolume >= 10">+</span> đã bán
                    </span>
              </div>
              <!--<div class="amount pt-1">-->
                <!--<span class="txt-red">¥ {{ (item.promotionPrice > 0 && item.promotionPrice < item.price) ? item.promotionPrice : item.price }}</span>-->
              <!--</div>-->
              <div class="mt-2" style="height: 35px; overflow-y: hidden;">
                <p style="margin: 0; color: grey; font-size: 13px;">{{ listLanguage.search_supplier }}: {{ item.shopName }}</p>
              </div>
            </div>
            <div class="action-buttons pt-2">
              <el-button @click="toDetail(item, 'external')" style="width: 100%">
                {{ listLanguage.search_see_detail }}
              </el-button>
              <!--<el-button @click="toDetail(item, 'internal')" style="background-color: #fe6701; color: #ffffff;">-->
                <!--Đặt hàng-->
              <!--</el-button>-->
            </div>
          </div>

          <div class="box-product my-4" v-if="formSearch.source == '1688'">
            <div>
              <div class="item-image" v-bind:style="{ backgroundImage: 'url(' +
                (item.imageUrl ? item.imageUrl : require('@/static/images/product/default.png')) +
                ')' }" @click="toDetail(item)">
                <!--<div class="d-flex freeship">-->
                <!--<img src="@/static/icon/search/delivery.svg" alt="" style="align-self: center;" />-->
                <!--<div style="color: #FFFFFF; align-self: center;"> -->
                <!--{{ (item.is_china_freeshiping == 0 && item.delivery_fee > 0) ? item.delivery_fee : 'Freeship' }}-->
                <!--</div>-->
                <!--</div>-->
              </div>
            </div>
            <div class="content">
              <div class="txt-sp hoverPointer" @click="toDetail(item)">
                <span>{{ item.subject }}</span>
              </div>
              <div class="price-content pt-2">
                <span class="txt-red">¥ {{ item.priceInfo.price }}</span>
                <!--<span class="pb-1 txt-red">{{ configList.exchange_rate * ((item.promotionPrice > 0 && item.promotionPrice < item.price) ? item.promotionPrice : item.price) | vnd }}</span>-->
                <span class="txt-sale">
                  {{ formatItemSales(item.monthSold) || 0 | vnd4 }}<span v-if="formatItemSales(item.monthSold) && item.monthSold >= 10">+</span> đã bán
                </span>
              </div>
              <!--<div class="amount pt-1">-->
              <!--<span class="txt-red">¥ {{ (item.promotionPrice > 0 && item.promotionPrice < item.price) ? item.promotionPrice : item.price }}</span>-->
              <!--</div>-->
              <div class="mt-2" style="overflow-y: hidden;">
                <p style="margin: 0; color: grey; font-size: 13px;">Tỉ lệ mua lại: {{ item.repurchaseRate }}</p>
              </div>
              <div class="mt-2" style="height: 35px; overflow-y: hidden;">
                <p style="margin: 0; color: grey; font-size: 13px;">Nhà cung cấp: {{ item.shopName }}</p>
              </div>
            </div>
            <div class="action-buttons pt-2">
              <el-button @click="toDetail(item, 'external')" style="width: 100%">
                Xem chi tiết
              </el-button>
              <!--<el-button @click="toDetail(item, 'internal')" style="background-color: #fe6701; color: #ffffff;">-->
                <!--Đặt hàng-->
              <!--</el-button>-->
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- PAGINATION -->
      <div class="china-panigation-1988 d-flex justify-content-end pt-1 pagi-search" v-if="tableData.length">
        <el-pagination
          background layout="prev, pager, next"
          :page-size="pagination.per_page" :total="pagination.total"
          :current-page="formSearch.page" @current-change="changeCurrentPage"
        ></el-pagination>
      </div>
    </div>
  </template>

  <script>
  import { mapGetters } from "vuex";

  export default {
    name: "TableProduct",
    props: {
      loading: Boolean,
      showProduct: Boolean,
      tableData: Array,
      pagination: Object,
      filter: Object
    },
    metaInfo() {
      return {
        title: "Danh sách sản phẩm",
      };
    },
    components: {

    },
    data() {
      return {
        configList: {
          exchange_rate: 3.5
        },
        formSearch: this.filter
      };
    },
    computed: {
      ...mapGetters("account", ["logged", "accountInfo", "listLanguage"]),

    },
    mounted() {
      //console.log(this.tableData, 'table data')
    },
    methods: {
      formatItemSales(sales) {
        const formattedSales = String(sales);
        const sale = formattedSales?.includes('万') ? formattedSales?.split('万')[0] * 10000 : formattedSales?.split('+')[0];
        return Number(sale);
      },
      toDetail(item, target = 'internal') {
        //console.log(item);
        let targets = ['internal', 'external'];
        let filter = this.formSearch;

        let itemId = this.getItemIdBySource(filter.source, item);
        if (!itemId) {
          return;
        }

        if (targets.includes(target)) {
          window.open(
            target === 'internal'
              ? this.$router.resolve({
                name: "shopping-detail",
                query: {search: filter.search, itemId: itemId, source: filter.source},
              }).href
              : (item.itemUrl ?? ''),
            '_blank'
          )
        }
      },
      getItemIdBySource(source, item) {
        let itemId = '';

        switch (source) {
          case 'taobao':
            itemId = item.itemId;
            break;
          case '1688':
            itemId = item.offerId;
            break;
        }

        return itemId;
      },
      convertStringToSlug(string) {
        return String(string)
          .normalize('NFKD') // split accented characters into their base characters and diacritical marks
          .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
          .trim() // trim leading or trailing whitespace
          .toLowerCase() // convert to lowercase
          .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
          .replace(/\s+/g, '-') // replace spaces with hyphens
          .replace(/-+/g, '-'); // remove consecutive hyphens
      },
      changeCurrentPage(currentPage) {
        console.log(this.formSearch, '123');
        this.formSearch.page = currentPage;
        this.$emit('search', this.formSearch);
        document.getElementById("ST2022").scrollIntoView({behavior: "smooth"});
      }
    },
    watch: {

    },
  };
  </script>


<style lang="css" scoped>
  .main-index {
    padding: 0 25px;
    background: #fff;
    min-height: calc(100vh - 130px);
  }

  .main-index .title-header {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px; /* 100% */
    letter-spacing: 0.32px;
    text-transform: uppercase;
    padding-top: 31px;
  }

  /* search website */
  .main-index .seach-china-2023 {
    padding: 16px 0;
  }

  .main-index .select-mall-index {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 1px solid #BFD5EC;
    background: #FFF;
    width: 80px;
    height: 35px;
    padding: 0 10px 0 20px;
    border-right: 1px solid #bfd5ec;
    cursor: pointer;
    position: relative;
  }

  .select-mall-index .image img {
    height: 22px;
    margin-right: 5px;
  }

  .sub-select-mall {
    position: absolute;
    min-height: 100px;
    background-color: #fff;
    width: 102px;
    left: 0;
    top: 35px;
    z-index: 1;
  }

  .sub-select-mall .image {
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-bottom: 1px solid #ccc;
  }

  .sub-select-mall .image:hover {
    background-color: #f2f2f2;
    transition: all 0.3s;
  }

  .main-index .seach-china-2023 input {
    width: 70%;
    font-size: 13px;
    color: #333;
    margin-right: 8px;
    outline: none;
    border: 1px solid #BFD5EC;
    height: 33px;
    border-left: none;
    padding-left: 17px;
  }

  .main-index .seach-china-2023 input::-webkit-input-placeholder {
    font-weight: 300;
    color: #1771C1;
    font-size: 12px;
    font-style: italic;
  }

  .seach-button-hhqq {
    width: 80px;
    margin-left: -40px;
    z-index: 999;
  }

  .seach-button-hhqq i {
    color: #fff !important;
  }

  .btn-hhqq {
    background: #fe6701;
    border-radius: 20px;
    height: 35px;
    width: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-hhqq:hover {
    cursor: pointer;
  }

  .btn-hhqq:hover img {
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
  }

  /* // */
  .main-index .txt-filter {
    color: #575757;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.28px;
    text-transform: capitalize;
    padding-right: 16px;
  }

  .btn-filter {
    width: 132px;
    height: 30px;
    border-radius: 4px;
    background: #26B01C;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 500;
    border: 1px solid #26B01C;
    cursor: pointer;;
  }

  .btn-filter:hover {
    background: #fff;
    color: #26B01C;
  }

  /* // bảng search */
  .box-main-search-category {
    background: #F0F1F6;
    display: flex;
    align-items: center;
  }

  .box-main-search-category img {
    margin: 0 5px;
  }

  .box-col-4 {
    background: #F0F1F6;
    width: 100%;
    cursor: pointer;
    margin: 4px 0;
    padding: 10px 0;
  }

  /* // sản phẩm  */
  .box-product {
    border: 1px solid #ECECEC;
    background-color: #FFF;
  }



  .box-product .item-image {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 220px;
    cursor: pointer;
    background-size: cover;
    position: relative;
  }
  .box-product .item-image .freeship{
    height: 25px;
    background-color: #084C94;
    position: absolute;
    bottom: 0%;
    opacity: 0.7;
    width: 100%;
    background: linear-gradient(to top left, transparent 15%, #084C94 70%);
  }
  .txt-detail {
    border: 1px solid #ECECEC;
    background-color: #FFF;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0.28px;
  }

  .txt-detail:hover {
    background: #1771C1;
    color: #fff;
  }

  .box-product .content {
    padding: 8px;
  }

  .txt-sp {
    color: #1771C1;
    height: 59px;
    max-height: 59px;
    overflow: hidden;
  }

  .txt-sp span {
    overflow: hidden;
  }

  .txt-sale {
    float: right;
    color: grey;
  }

  .txt-red {
    color: red;
  }
</style>

<style>
  .main-index .frame-filter-innput-2022 .el-input__inner {
    width: 130px;
  }

  .main-index .frame-filter-innput-2022 input {
    text-align: left;
    padding-left: 30px;
  }

  .txt-dropdown {
    font-size: 12px;
    width: 100%;
  }

  .pagi-search {
    padding-right: 0 !important;
  }

  @media only screen and (max-width: 576px) {
    .box-main-search-category {
      width: 190px !important;
    }
  }

  .input_search_key {
    width: 70%;
    border: 1px solid #BFD5EC;
    border-left: none;


  }

  .input_search_key input {
    height: 35px;
    border-radius: 0;
    border-left: none;
  }

  /************************/
  /**** CUSTOM CLASSES ****/
  /************************/
  .hoverPointer:hover {
    cursor: pointer !important;
  }

  .providerBlock {
    max-height: 42px;
    overflow-y: hidden;
  }

  .txt-provider {
    color: grey;
    margin: 8px 0 0 !important;
  }

  .action-buttons {
    padding: 0 8px 8px;
    display: flex;
    justify-content: space-between;
  }

  .action-buttons button {
    font-size: 12px;
    padding: 8px;
  }

  .action-buttons button + button {
    margin-left: 0;
  }

  /************************/
  /**** CUSTOM LOADING ****/
  /************************/
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: #ECECEC;
    z-index: 999;
  }

  .spinner {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1.2s linear infinite;
    margin-top: 120px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .frame-filter-item-2022 .el-select input {
    border-radius: 20px;
    height: 32px;
    border-color: #409EFF;
  }

  .frame-filter-item-2022 .el-button {
    height: 32px;
    padding: 4px 8px;
  }

  .frame-filter-item-2022 .el-checkbox {
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .frame-filter-item-2022 .el-checkbox .el-checkbox__label {
    margin-right: 8px;
  }

  .frame-filter-item-2022 .el-checkbox .el-checkbox__inner {
    border-radius: 4px;
  }
</style>
