<template>
  <div class="home-index frame-content-common-2022 dialog_create_con">
    <el-dialog
      top="5%"
      width="60%"
      :visible="dialogCreateConsignment"
      :show-close="false"
      title="Yêu cầu ký gửi"
    >
      <div class="body-dialog">
        <div class="form_create_item">
          <div class="form_title">
            <span>Địa chỉ kho nhận hàng</span>
          </div>
          <div class="d-flex align-items-center pt-2">
            <div v-for="item in optionWHCN" :key="item.id" class="pr-3">
              <div class="content">
                <img src="@/static/icon/consignment/check.svg" alt="" v-if="item.id == wh_id">
                <img src="@/static/icon/consignment/no-check.svg" alt="" v-else  @click.stop="changeWHID(item)">
                <span @click.stop="changeWHID(item)">{{ item.name }}</span>
              </div>
            </div>
          </div>
          <div v-if="showInfo" style="line-height: 24px;padding: 10px 0;">
            <div>Địa chỉ nhận hàng: {{ infoWarehouse.address }}</div>
            <div>Người nhận: {{ infoWarehouse.receiver }}</div>
            <div>Mã bưu điện: {{ infoWarehouse.postcode }}</div>
            <div>Số điện thoại: {{ infoWarehouse.phone_number }}</div>
          </div>
        </div>
        <!-- // -->
        <div class="form_create_item pt-2">
          <div class="form_title">
            <span>Vận chuyển</span>
          </div>
          <div class="d-flex align-items-center pt-2">
            <div v-for="item in optionShip" :key="item.value" class="pr-3">
              <div class="content">
                <img src="@/static/icon/consignment/check.svg" alt="" v-if="item.value == type_transport">
                <img src="@/static/icon/consignment/no-check.svg" alt="" v-else  @click.stop="changeShip(item.value)">
                <span @click.stop="changeShip(item.value)">{{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form_create_item pt-2">
          <div class="form_title">
            <span>Địa chỉ trả hàng</span>
          </div>
          <div class="content pt-2">
            <el-select class="select_address" v-model="address_id" placeholder="Chọn địa chỉ nhận hàng">
              <el-option
                v-for="item in optionAddress"
                :key="item.id"
                :label="item.receiver + ' - ' + item.phone_number + ' - ' + item.address + ' - ' + item.ward_name + ' - ' + item.district_name + ' - ' + item.province_name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="add_address">
            <span class="title" @click="showFormAdd()">+ Thêm địa chỉ</span>
            <div class="form_delivery_add" v-if="showFormDeliveryAdd">
              <div class="content_form">
                <span>Họ và tên <span style="color: red;">*</span></span>
                <el-input
                  placeholder="Họ và tên"
                  v-model="deliveryAdd.receiver"
                  class="frame-input-common-2022"
                  size="mini"
                  clearable
                ></el-input>
              </div>
              <div class="content_form">
                <span>Số điện thoại <span style="color: red;">*</span></span>
                <el-input
                  placeholder="Số điện thoại"
                  type="number"
                  v-model="deliveryAdd.phone_number"
                  class="frame-input-common-2022"
                  size="mini"
                  clearable
                ></el-input>
              </div>
              <div class="content_form">
                <span>Địa chỉ <span style="color: red;">*</span></span>
                <el-select
                  v-model="deliveryAdd.province_id"
                  placeholder="Tỉnh thành"
                  class="frame-select-common-2022 account-profile-select-city"
                  popper-class="frame-select-popper-common-2022"
                  clearable
                  size="mini"
                >
                  <el-option
                    v-for="item in listProvince"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    filterable
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="deliveryAdd.district_id"
                  placeholder="Quận huyện"
                  class="frame-select-common-2022 account-profile-select-city"
                  popper-class="frame-select-popper-common-2022"
                  clearable
                  size="mini"
                >
                  <el-option
                    v-for="item in listDistrict"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="deliveryAdd.ward_id"
                  placeholder="Phường xã"
                  class="frame-select-common-2022 account-profile-select-city"
                  popper-class="frame-select-popper-common-2022"
                  clearable
                  size="mini"
                  style="margin-right: 0;"
                >
                  <el-option
                    v-for="item in listWard"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="content_form">
                <span>Địa chỉ chi tiết <span style="color: red;">*</span></span>
                <el-input
                  placeholder="Nhập địa chỉ"
                  v-model="deliveryAdd.address"
                  class="frame-input-common-2022"
                  size="mini"
                  clearable
                ></el-input>
              </div>
              <!-- //  -->
              <div class="d-flex justify-content-end">
                <el-button class="btnToCancel" @click="showFormDeliveryAdd = false">Đóng</el-button>
                <el-button
                  class="btnDone"
                  @click="submitFormAddDelivery()"
                  :disable="!canSubmit"
                  :loading="!canSubmit"
                >Thêm địa chỉ</el-button>
              </div>
            </div>
          </div>
          <!-- // bảng detail  -->
          <div class="table_detail_pack">
            <el-table
            ref="multipleTable"
            :data="createSelect"
            style="width: 100%"
            >
              <el-table-column label="STT" align="center" width="50" type="index"></el-table-column>
              <el-table-column label="Mã đặt hàng" align="center" width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.source_id == 2">{{ scope.row.purchase_order_outer_id }}</span>
                  <span v-else>{{ scope.row.order_code }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Mã Vận đơn" align="center" width="100">
                <template>
                  <span></span>
                </template>
              </el-table-column>
              <el-table-column label="Số kiện" align="center" width="150">
                <template slot-scope="scope">
                  <div>
                    <el-input-number v-model="scope.row.package_number" :min="1" size="mini"></el-input-number>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Ghi chú" align="center" min-width="60">
                <template slot-scope="scope">
                  <el-input
                    type="textarea"
                    :rows="3"
                    v-model="scope.row.note"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="Dịch vụ gia tăng" align="left" min-width="120px">
                <template slot-scope="scope">
                  <div class="">
                    <div class="content pr-4" @click="scope.row.is_woodworking = !scope.row.is_woodworking">
                      <img src="@/static/icon/consignment/box.svg" alt="" v-if="scope.row.is_woodworking">
                      <img src="@/static/icon/consignment/no-box.svg" alt="" v-else>
                      <span>Đóng gỗ</span>
                    </div>
                    <div class="content pr-4" @click="scope.row.is_shock = !scope.row.is_shock">
                      <img src="@/static/icon/consignment/box.svg" alt="" v-if="scope.row.is_shock">
                      <img src="@/static/icon/consignment/no-box.svg" alt="" v-else>
                      <span>Chống sóc</span>
                    </div>
                    <div class="content pr-4" @click="scope.row.is_inspection = !scope.row.is_inspection">
                      <img src="@/static/icon/consignment/box.svg" alt="" v-if="scope.row.is_inspection">
                      <img src="@/static/icon/consignment/no-box.svg" alt="" v-else>
                      <span>Kiểm đếm</span>
                    </div>
                    <div class="content pr-4" @click="scope.row.is_insurance = !scope.row.is_insurance">
                      <img src="@/static/icon/consignment/box.svg" alt="" v-if="scope.row.is_insurance">
                      <img src="@/static/icon/consignment/no-box.svg" alt="" v-else>
                      <span>Bảo hiểm hàng hóa</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!--  -->
        </div>
      </div>
      <div class="footer-dialog d-flex align-items-center justify-content-end" style="margin-top: 15px;">
        <el-button class="btnToCancel" @click="cancelCreate();">Hủy bỏ</el-button>
        <el-button
          class="btnDone"
          @click="addressDoneCreate()"
          :disable="!canSubmit"
          :loading="!canSubmit"
        >Xác nhận</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "CreateFormIndex",
  props: {
    dialogCreateConsignment: Boolean,
    createSelect: Array,
  },
  metaInfo() {
    return {
      title: "",
    };
  },
  components: {},
  data() {
    return {
      loading: true,
      loadDone: false,
      canSubmit: true,
      wh_id: "",
      type_transport: "",
      address_id: "",
      optionWHCN: [],
      optionShip: [
        {
          name: "Bán chính ngạch",
          value: "1",
        },
        {
          name: "Chính ngạch",
          value: "2",
        },
      ],
      is_woodworking: false,
      is_shock: false,
      is_inspection: false,
      is_insurance: false,
      optionAddress: [],
      showFormDeliveryAdd: false,
      deliveryAdd: {
        receiver: "",
        phone_number: "",
        province_id: "",
        district_id: "",
        ward_id: "",
        address: "",
      },
      filter: {
        page: 1,
        per_page: 50,
        status: 1
      },
      listProvince: [],
      listDistrict: [],
      listWard: [],
      tableData: [{}],
      note: "",
      showInfo: false,
      infoWarehouse: {
        address: '',
        receiver: '',
        postcode: '',
        phone_number: '',
      }
    };
  },
  mounted() {
    this.fetchListCustomerDelivery();
    this.fetchProvince();
    this.fetchWHCN();
  },
  methods: {
    // thêm địa chỉ nhận hàng
    submitFormAddDelivery() {
      if(!this.deliveryAdd.receiver || !this.deliveryAdd.phone_number || !this.deliveryAdd.province_id || !this.deliveryAdd.district_id || !this.deliveryAdd.ward_id || !this.deliveryAdd.address
      ) {
        this.$notify({
            title: "Cảnh báo",
            message: "Vui lòng chọn nhập đầy đủ thông tin",
            type: "warning",
          });
        return;
      }
      this.canSubmit = false;
      axios.post("/deposit/customer-delivery/create", this.deliveryAdd).then((response) => {
        this.canSubmit = true;
        switch (response.code) {
          case 200:
            this.$notify({
              title: "Thành công",
              message: response.message,
              type: "success",
            });
            this.fetchListCustomerDelivery();
            this.showFormDeliveryAdd = false;
            break;
          case 400:
            this.$notify({
              title: "Thất bại",
              message: response.errors[0],
              type: "warning",
            });
            break;
          case 422:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: Object.values(response.data)[0],
              type: "warning",
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    //
    cancelCreate() {
      this.wh_id = "";
      this.type_transport = "";
      this.is_woodworking = false;
      this.is_shock = false;
      this.is_inspection = false;
      this.is_insurance = false;
      this.$emit('cancelCreate');
    },
    addressDoneCreate() {
      this.canSubmit = false;
      let data = {
        warehouse_id: this.wh_id,
        customer_delivery_id: this.address_id,
        type_transport: this.type_transport,
        is_woodworking: this.is_woodworking,
        is_shock_proof: this.is_shock,
        is_inspection: this.is_inspection,
        is_insurance: this.is_insurance,
        createSelect: this.createSelect
      }
      axios.post("/deposit/store/", data).then((response) => {
        this.canSubmit = true;
        switch (response.code) {
          case 200:
            this.$notify({
              title: "Thành công",
              message: response.message,
              type: "success",
            });
            this.$emit('addressDoneCreate', true);
            break;
          case 400:
            this.$notify({
              title: "Thất bại",
              message: response.errors[0],
              type: "warning",
            });
            break;
          case 422:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: Object.values(response.data)[0],
              type: "warning",
            });
            break;
          case 500:
            this.$notify({
              title: "Có lỗi xảy ra",
              message: response.message,
              type: "error",
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });

    },
    changeWHID(item) {
      this.wh_id = item.id;
      this.showInfo = true;
      this.infoWarehouse.address = item.address;
      this.infoWarehouse.receiver = item.receiver;
      this.infoWarehouse.postcode = item.postcode;
      this.infoWarehouse.phone_number = item.phone_number;
    },
    changeShip(value) {
      this.type_transport = value;
    },
    showFormAdd() {
      this.deliveryAdd = {
        receiver: "",
        phone_number: "",
        province_id: "",
        district_id: "",
        ward_id: "",
        address: "",
      },
      this.showFormDeliveryAdd = !this.showFormDeliveryAdd
    },
    // ds địa chỉ nhận hàng
    fetchListCustomerDelivery() {
      this.loading = true

      axios.get("deposit/customer-delivery", {params: this.filter}).then((response) => {
        this.loading = false;
        switch (response.code) {
          case 200:
            this.optionAddress = response.data.items;
            this.optionAddress.forEach((item) => {
              if(item.is_default == 1) {
                this.address_id = item.id;
              }
            })
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    fetchProvince() {
      axios.get("deposit/get-province").then((response) => {
        switch (response.code) {
          case 200:
            this.listProvince = response.data;
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
            });
            break;
        }
      });
    },
    fetchDistrict() {
      axios
        .get("deposit/get-district/" + this.deliveryAdd.province_id)
        .then((response) => {
          switch (response.code) {
            case 200:
              this.listDistrict = response.data;
              this.loadDone = true;
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
    },
    fetchWard() {
      axios
        .get("deposit/get-ward/" + this.deliveryAdd.district_id)
        .then((response) => {
          switch (response.code) {
            case 200:
              this.listWard = response.data;
              this.loadDone = true;
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
    },

    fetchWHCN() {
      axios
        .get("deposit/get-warehouse-cn")
        .then((response) => {
          switch (response.code) {
            case 200:
              this.optionWHCN = response.data;
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
    },
  },
  watch: {
    "deliveryAdd.province_id": function () {
      if (this.loadDone) {
        this.deliveryAdd.district_id = "";
      }
      if (this.deliveryAdd.province_id) {
        this.fetchDistrict();
      } else {
        this.listDistrict = [];
      }
    },
    "deliveryAdd.district_id": function () {
      if (this.loadDone) {
        this.deliveryAdd.ward_id = "";
      }
      if (this.deliveryAdd.district_id) {
        this.fetchWard();
      } else {
        this.listWard = [];
      }
    },
  },
};
</script>

<style lang="css" scoped>
.form_create_item .form_title {
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  color: #545454;
}
.form_create_item .content {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 24px;
  cursor: pointer;
}
.form_create_item .content img {
  margin-right: 5px;
}
.add_address {
  padding-bottom: 10px;
}
.add_address .title {
  font-size: 12px;
  color: #00D0FF;
  cursor: pointer;
}
.form_delivery_add {
  background: #f0f8ff;
  padding: 10px;
  margin: 10px 0;
}
.form_delivery_add {
  font-size: 12px;
}
.form_delivery_add .content_form {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.form_delivery_add .content_form span {
  min-width: 90px;
}

</style>
<style>
.dialog_create_con .el-dialog__body {
  padding: 10px 20px;
}
.form_create_item .select_address {
  width: 100%;
  margin-bottom: 10px;
}
.form_create_item .select_address input {
  font-size: 12px;
  height: 35px;
}
.form_create_item .select_address .el-input__icon {
  line-height: 35px;
}
.account-profile-select-city {
  margin-right: 10px;
  width: 100%;
}
.table_detail_pack .el-table th.el-table__cell>.cell {
  font-size: 12px;
  font-weight: 600;
}
.table_detail_pack .el-table__empty-block {
  min-height: 45px;
  font-size: 12px;
}
.table_detail_pack .el-table .cell {
  font-size: 12px;
}
</style>
