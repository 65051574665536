<template>
  <div class="payment-request-filter mt-3">
    <!-- DANH SÁCH MALL, Ô TÌM KIẾM -->
    <el-row :class="isMobile ? '' : 'd-flex align-items-center justify-content-center'"
      style="background-color: #fff; padding: 10px"
    >
        <el-col :xs="24" :sm="24" :md="18" :lg="18" class="mt-2 mb-2"
          :class="isMobile ? '' : 'd-flex align-items-center justify-content-center'"
        >
          <el-col :xs="24" :sm="24" :md="3" :lg="3" class="mt-2">
            <span class="label mr-2">{{ listLanguage.common_search }}</span>
          </el-col>
          <el-col :xs="24" :sm="24" :md="21" :lg="21" class="mt-2 mb-2">
            <el-col :xs="12" :sm="12" :md="10" :lg="10" class="">
              <el-select style="width: 96%" class="mr-2 mt-2" @change="changeVal(formSearch.source, 'source')" v-model="formSearch.source" placeholder="Chọn nguồn" clearable>
                <el-option
                  v-for="item in sources"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val">
                </el-option>
              </el-select>
            </el-col>
            <el-col :xs="12" :sm="12" :md="10" :lg="10" class="mt-2 mb-2">
                <el-input
                class="mr-2" :placeholder="listLanguage.search_keywords + ' Taobao, 1688'"
                v-model="formSearch.search"
                style="width: 96%"
                clearable
                ></el-input>
            </el-col>
            <el-col :xs="24" :sm="24" :md="4" :lg="4" class="mt-2 mb-2">
              <el-button
                style="background-color: #962DFF; color: #fff"
                @click="search"
                :loading="!canSubmit"
              >
                <span>
                  {{ listLanguage.common_search }}
                </span>
              </el-button>
            </el-col>
          </el-col>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" style="padding: 0 10px" class="mt-2 mb-2">
          <div class="" style="text-align: right" v-if="showCategories" @click="showHideCategories">
            <el-icon class="el-icon-arrow-down mr-1" style="color: red"></el-icon> {{ listLanguage.search_category }}
          </div>
          <div class="" style="text-align: right" v-if="!showCategories" @click="showHideCategories">
            <el-icon class="el-icon-arrow-up mr-1" style="color: red"></el-icon> {{ listLanguage.search_category }}
          </div>
        </el-col>
    </el-row>

    <!--<el-row class="d-flex mt-3" style="background-color: #fff; padding: 10px">-->
      <!--<el-col :span="24" class="d-flex align-items-center">-->
        <!--<span class="label mr-2">Lọc theo giá</span>-->
        <!--<el-input-->
          <!--v-model="formSearch.price_from"-->
          <!--class="mr-2"-->
          <!--placeholder="Từ"-->
          <!--type="number"-->
          <!--:min="1"-->
          <!--style="width: 15%"-->
        <!--&gt;-->
        <!--</el-input>-->
        <!--<el-input-->
          <!--v-model="formSearch.price_to"-->
          <!--class="mr-2"-->
          <!--placeholder="Đến"-->
          <!--type="number"-->
          <!--:min="1"-->
          <!--style="width: 15%"-->
        <!--&gt;</el-input>-->
          <!--&lt;!&ndash;<el-button&ndash;&gt;-->
            <!--&lt;!&ndash;style="background-color: #00D0FF; color: #fff"&ndash;&gt;-->
            <!--&lt;!&ndash;@click="search"&ndash;&gt;-->
          <!--&lt;!&ndash;&gt;Áp dụng&ndash;&gt;-->
          <!--&lt;!&ndash;</el-button>&ndash;&gt;-->
      <!--</el-col>-->
    <!--</el-row>-->

    <!-- CATEGORIES -->
    <el-row :gutter="24" class="mt-3" v-if="showCategories">
      <el-col :xs="12" :sm="6" :md="4" :lg="4" v-for="item in categories" :key="item.id">
        <div class="box-col-4">
          <el-dropdown @command="showKeySearch" class="d-flex">
            <div class="box-main-search-category el-dropdown-link">
              <img v-if="item.id == 1" src="@/static/icon/search/icon_1.svg" alt=""/>
              <img v-if="item.name == 'Thời trang nam'" src="@/static/icon/search/icon_2.svg" alt=""/>
              <img v-if="item.name == 'Thời trang trẻ em'" src="@/static/icon/search/icon_3.svg" alt=""/>
              <img v-if="item.name == 'Sản phẩm mẹ và bé'" src="@/static/icon/search/icon_4.svg" alt=""/>
              <img v-if="item.name == 'Giày dép'" src="@/static/icon/search/icon_5.svg" alt=""/>
              <img v-if="item.name == 'Trang phục thể thao'" src="@/static/icon/search/icon_7.svg" alt=""/>
              <img v-if="item.name == 'Đồ chơi'" src="@/static/icon/search/icon_8.svg" alt=""/>
              <img v-if="item.name == 'Túi xách, vali'" src="@/static/icon/search/icon_9.svg" alt=""/>
              <img v-if="item.name == 'Điện thoại'" src="@/static/icon/search/icon_10.svg" alt=""/>
              <img v-if="item.name == 'Linh kiện điện tử'" src="@/static/icon/search/icon_11.svg" alt=""/>
              <img v-if="item.name == 'Phụ kiện thể thao'" src="@/static/icon/search/icon_12.svg" alt=""/>
              <img v-if="item.name == 'Điện gia dụng'" src="@/static/icon/search/icon_13.svg" alt=""/>
              <span>{{ item.name }}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="i in item.child" :key="i.id" :command="i">
                <el-dropdown @command="showKeySearch">
                  <span @command="showKeySearch(i)" class="txt-dropdown">
                    {{ i.name }} <i v-if="i.child.length > 0" class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="y in i.child" :key="y.id" :command="y">
                      <span @command="showKeySearch(y)" class="txt-dropdown">{{ y.name }}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from "vuex";

  export default {
    name: "ShoppingFilter",
    props: {
      canSubmit: Boolean,
      sources: Array,
      filter: Object,
    },
    components: {
      //
    },
    data() {
      return {
        formSearch: this.filter,
        showCategories: true,
        loadingCate: true,
        categories: [],
        isSubmit: this.canSubmit
      };
    },
    computed: {
      ...mapGetters("account", ["logged", "accountInfo", "listLanguage"]),

      isMobile() {
        // if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        //   return true
        // } else {
        //   return false
        // }

        return window.innerWidth < 992
      }
    },
    mounted() {
      this.fetchCategory();
      //console.log(window.innerWidth, 'width')
    },
    methods: {
      // lấy dữ liệu
      fetchCategory() {
        axios.get("cart/product/categories").then((response) => {
          this.loadingCate = false;

          switch (response.code) {
            case 200:
              this.categories = response.data;
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },
      showKeySearch(i) {
        this.formSearch.search = i.name
        this.formSearch.page   = 1
        setTimeout(() => {
          this.search();
        }, 200);
      },
      changeVal(value, key) {
        let formSearch  = this.formSearch;
        formSearch[key] = value;
        this.formSearch = formSearch;
        console.log(this.formSearch);
      },
      showHideCategories() {
        this.showCategories = !this.showCategories;
      },
      search() {
        this.$emit('search', this.formSearch);
      }
    },
    watch: {

    },
  };
</script>

<style lang="css" scoped>
  .payment-request-filter {
    overflow-x: hidden;
  }

  .select-mall-index .image img {
    height: 22px;
    margin-right: 5px;
  }

  .main-index .seach-china-2023 input {
    width: 70%;
    font-size: 13px;
    color: #333;
    margin-right: 8px;
    outline: none;
    border: 1px solid #BFD5EC;
    height: 33px;
    border-left: none;
    padding-left: 17px;
  }

  .main-index .seach-china-2023 input::-webkit-input-placeholder {
    font-weight: 300;
    color: #1771C1;
    font-size: 12px;
    font-style: italic;
  }

  .seach-button-hhqq {
    width: 80px;
    margin-left: -40px;
    z-index: 999;
  }

  .seach-button-hhqq i {
    color: #fff !important;
  }

  .btn-hhqq {
    background: #fe6701;
    border-radius: 20px;
    height: 35px;
    width: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn-hhqq:hover {
    cursor: pointer;
  }

  .btn-hhqq:hover img {
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
  }

  /* // */
  .main-index .txt-filter {
    color: #575757;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.28px;
    text-transform: capitalize;
    padding-right: 16px;
  }

  .btn-filter {
    width: 132px;
    height: 30px;
    border-radius: 4px;
    background: #26B01C;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 500;
    border: 1px solid #26B01C;
    cursor: pointer;;
  }

  .btn-filter:hover {
    background: #fff;
    color: #26B01C;
  }

  /* // bảng search */
  .box-main-search-category {
    /*background: #ffffff;*/
    display: flex;
    align-items: center;
  }

  .box-main-search-category img {
    margin: 0 5px;
  }

  .box-col-4 {
    background: #fff;
    width: 100%;
    cursor: pointer;
    margin: 4px 0;
    padding: 10px 0;
  }

  /* // sản phẩm  */
  .box-product {
    border: 1px solid #ECECEC;
    background-color: #FFF;
  }

  .box-product .item-image {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 220px;
    cursor: pointer;
    background-size: cover;
    position: relative;
  }
  .box-product .item-image .freeship{
    height: 25px;
    background-color: #084C94;
    position: absolute;
    bottom: 0%;
    opacity: 0.7;
    width: 100%;
    background: linear-gradient(to top left, transparent 15%, #084C94 70%);
  }
  .txt-detail {
    border: 1px solid #ECECEC;
    background-color: #FFF;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0.28px;
  }

  .txt-detail:hover {
    background: #1771C1;
    color: #fff;
  }

  .box-product .content {
    padding: 8px;
  }

  .txt-sp {
    color: #1771C1;
    height: 59px;
    max-height: 59px;
    overflow: hidden;
  }

  .txt-sp span {
    overflow: hidden;
  }

  .txt-sale {
    float: right;
    color: grey;
  }

  .txt-red {
    color: red;
  }
</style>

<style>
  .main-index .frame-filter-innput-2022 .el-input__inner {
    width: 130px;
  }

  .main-index .frame-filter-innput-2022 input {
    text-align: left;
    padding-left: 30px;
  }

  .txt-dropdown {
    font-size: 12px;
    width: 100%;
  }

  .pagi-search {
    padding-right: 0 !important;
  }

  @media only screen and (max-width: 576px) {
    .box-main-search-category {
      width: 190px !important;
    }
  }

  .input_search_key {
    width: 70%;
    border: 1px solid #BFD5EC;
    border-left: none;


  }

  .input_search_key input {
    height: 35px;
    border-radius: 0;
    border-left: none;
  }

  /************************/
  /**** CUSTOM CLASSES ****/
  /************************/
  .hoverPointer:hover {
    cursor: pointer !important;
  }

  .providerBlock {
    max-height: 42px;
    overflow-y: hidden;
  }

  .txt-provider {
    color: grey;
    margin: 8px 0 0 !important;
  }

  .action-buttons {
    padding: 0 8px 8px;
    display: flex;
    justify-content: space-between;
  }

  .action-buttons button {
    font-size: 12px;
    padding: 8px;
  }

  .action-buttons button + button {
    margin-left: 0;
  }

  /************************/
  /**** CUSTOM LOADING ****/
  /************************/
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
    z-index: 999;
  }

  .spinner {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1.2s linear infinite;
    margin-top: 120px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .frame-filter-item-2022 .el-select input {
    border-radius: 20px;
    height: 32px;
    border-color: #409EFF;
  }

  .frame-filter-item-2022 .el-button {
    height: 32px;
    padding: 4px 8px;
  }

  .frame-filter-item-2022 .el-checkbox {
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  .frame-filter-item-2022 .el-checkbox .el-checkbox__label {
    margin-right: 8px;
  }

  .frame-filter-item-2022 .el-checkbox .el-checkbox__inner {
    border-radius: 4px;
  }
</style>
