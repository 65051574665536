<template>
    <div class="payment-request-index" id="ST2023" v-loading="loadingApi">
      <div class="xadal_title_head d-flex justify-content-between">
        <span>{{ listLanguage.payment_title }}</span>
        <div class="d-flex align-items-center create_request justify-content-center" @click="showFormCreate()">
          <i class="el-icon-circle-plus-outline"></i>
          <span>Thêm mới </span>
        </div>
      </div>
      <!-- <BreadcrumbTop text="Trang chủ" text2="Yêu cầu thanh toán" /> -->
      <status-payment :listStatus="listStatus" :status="filter.status" @status="status"></status-payment>
      <filter-payment :listChild="listChild" @changeFilter="changeFilter"></filter-payment>
      <table-payment
        :tableData="tableData"
        :pagination="pagination"
        :filter="filter"
        :listReason="listReason"
        :listSourcePayment="listSourcePayment"
        :source="source"
        :flagCheckTab="flagCheckTab"
        @handleCheck="handleCheck"
        @fetchList="fetchList"
        @tab="tab"
        @fetchStatusTable="fetchStatusTable"
        @detail="showDetail"
        @fetchNoti="fetchNoti"
      ></table-payment>
      <order-detail v-if="!detail.suppliers || !detail.suppliers.length" :detail="detail" :dialog-show="dialogShow" @close="closeModal"></order-detail>
      <order-detail-supplier v-else :detail="detail" :dialog-show="dialogShow" @close="closeModal"></order-detail-supplier>

      <!-- /form thêm mới  -->
      <CreateRequest
        :dialogCreateRequest="dialogCreateRequest"
        @cancelCreateRequest="cancelCreateRequest"
        @doneCreate="doneCreate"
      />


    </div>
  </template>

  <script>
  import axios from "axios";
  // import BreadcrumbTop from "@/components/BreadcrumbTop";
  import FilterPayment from "../../views/payment_request/components/Filter.vue";
  import StatusPayment from "../../views/payment_request/components/Status.vue";
  import TablePayment from "../../views/payment_request/components/Table.vue";
  import OrderDetail from "../../views/payment_request/Detail.vue";
  import OrderDetailSupplier from "../../views/payment_request/DetailSupplier.vue";
  import CreateRequest from "@/views/payment_request/components/CreateRequestPayment";
  import { mapGetters } from "vuex";

  export default {
    name: "PaymentRequestIndex",
    metaInfo() {
      return {
        title: "Yêu cầu thanh toán",
      };
    },
    components: {
      // BreadcrumbTop,
      FilterPayment,
      StatusPayment,
      TablePayment,
      OrderDetail,
      OrderDetailSupplier,
      CreateRequest,
    },
    data() {
      return {
        loading: true,
        loadingApi: false,
        dialogCreateRequest: false,
        listStatus: [],
        listChild:[],
        listReason: [],
        listSourcePayment: [],
        tableData: [],
        flagCheckTab: false,
        filter: {
          tab: 2,
          source_id: '',
          user_id: '',
          order_code: '',
          status: '',
          from_created_at: '',
          to_created_at: '',
          user_name_payment: '',
          page: 1,
          per_page: 15
        },
        totalData: {},
        pagination: {
          total: 0,
          page: 0,
          per_page: 0
        },
        dialogShow: false,
        source: "",
        detail: {}
      };
    },
    computed: {
      ...mapGetters("account", ["logged", "accountInfo", "listLanguage"]),

    },
    mounted() {
      this.fetchData();
      this.fetchStatus();
      this.fetchChildUser();
      this.fetchReason();
      this.fetchSourcePayment()
    },
    methods: {
      tab (data) {
        this.filter.tab = data;
        this.fetchData();
      },
      // phần thêm mới
      showFormCreate() {
        this.dialogCreateRequest = true;
      },
      cancelCreateRequest() {
        this.dialogCreateRequest = false;
      },
      doneCreate() {
        this.dialogCreateRequest = false;
        this.fetchData();
      },
      // lấy dữ liệu
      fetchData(loading = true) {
        if(loading) {
          this.loading = true
        }
        if(this.filter.tab == 1) {
          this.flagCheckTab = true;
        }
        axios.get("request_payment", {params: this.filter}).then((response) => {
          this.loading = false;
          switch (response.code) {
            case 200:
              this.tableData = response.data.items;
              this.tableData.map(function (i) {
                i.checked = false;
                return i;
              });
              this.tableData = JSON.parse(JSON.stringify(this.tableData));
              this.pagination = response.data.pagination;
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },
      fetchStatus() {
        axios.get('/request_payment/list-status').then((response) => {
          this.loading = false;
          switch (response.code) {
            case 200:
              this.listStatus = response.data;
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },
      fetchChildUser() {
        axios.get('/request_payment/list-child').then((response) => {
          this.loading = false;
          switch (response.code) {
            case 200:
              this.listChild = response.data;
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },
      fetchReason() {
        axios.get('/request_payment/reason-cancel').then((response) => {
          this.loading = false;
          switch (response.code) {
            case 200:
              this.listReason = response.data;
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },
      fetchSourcePayment() {
        axios.get('/user_bank_payment/get-price').then((response) => {
          this.loading = false;
          switch (response.code) {
            case 200:
              this.listSourcePayment = response.data;
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },

      status(data) {
        this.filter.status = data;
        this.filter.page = 1;
        this.fetchData();
      },
      changeFilter(data) {
        this.source = data.source_id;
        this.filter.source_id = data.source_id;
        this.filter.source_type = data.source_type;
        this.filter.user_id = data.user_id;
        this.filter.order_code = data.order_code;
        this.filter.user_name_payment = data.user_name_payment;
        this.filter.from_created_at = data.from_created_at;
        this.filter.to_created_at = data.to_created_at;
        this.filter.page = 1;
        this.filter.type_fund = data.type_fund;
        this.filter.option_date = data.option_date;
        this.fetchData();
      },
      fetchList() {
        this.fetchData();
        this.fetchSourcePayment();
      },
      fetchStatusTable() {
        this.fetchStatus()
      },
      handleCheck(ids, source) {
        let data = {
          request_payment_ids: ids,
          source_id: source
        }
        this.loadingApi = true;
        axios.post("/request_payment/check-price-for-buyder", data).then((response) => {

          this.loadingApi = false;
          switch (response.code) {
            case 200:
              this.fetchData();
              this.$notify({
                type: 'success',
                message: response.message
              });
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        }).catch( errors => {
          this.loadingApi = false;
          this.canSubmit = true
          if (errors.response.data.code === 422) {
            this.$notify({
              title: "Có lỗi xảy ra",
              message: Object.values(errors.response.data)[0],
              type: "warning",
            });
          } else if (errors.response.code === 500){
              this.$notify({
                title: "Có lỗi xảy ra",
                message: errors.response.message,
                type: "warning",
              });
          } else {
            this.$notify({
              title: "Có lỗi xảy ra",
              message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              type: "warning",
            });
          }
        });
      },
      async showDetail(id) {
        //console.log(id, 123);
        await axios.get('/order/detail/' + id).then((response) => {
          this.loading = false;
          switch (response.code) {
            case 200: {
              this.detail = response.data;
              this.dialogShow = true;
              //console.log(this.dialogShow, this.detail, 111)
              break;
            }
            case 500: {
              this.dialogShow = false;
              this.$notify.error({
                title: "Error",
                message: response.message,
              });
              break;
            }
            default:
              this.dialogShow = false;
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },
      closeModal() {
        this.dialogShow = false;
      },
      fetchNoti() {
        // load lại noti khi thanh toán thành công
        this.$emit("checkNotiNummber", Math.floor(Math.random() * 100000) + 1);
      }
    },
    watch: {

    },
  };
  </script>

  <style lang="css" scoped>
.create_request {
background: #2196F3;
border-radius: 5px;
color: #fff;
font-size: 14px;
padding: 5px 10px;
cursor: pointer;
}
.create_request i {
  padding-right: 5px;
}
  </style>
