import axios from 'axios';
class Auth {
  constructor () {
    let userData = window.localStorage.getItem('user');
    let rolePermission = window.localStorage.getItem('rolePermission');
    this.user = userData !== 'undefined' ? JSON.parse(userData) : null;
    this.rolePermission = rolePermission !== 'undefined' ? JSON.parse(rolePermission) : null;
    this.token = window.localStorage.getItem('token');
    if (this.token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
    }
  }
  login (token, user, rolePermission) {
    window.localStorage.setItem('token', token);
    window.localStorage.setItem('user', JSON.stringify(user));
    window.localStorage.setItem('rolePermission', JSON.stringify(rolePermission));
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    this.token = token;
    this.user = user;
    this.rolePermission = rolePermission;
  }
  check () {
    return !!(this.token && this.user.status === 1);
  }
  getUser() {
    return this.user;
  }
  checkRolePermission (permission) {
    if (this.user.user_type === 1 ) {
      return true;
    }
    if (!this.rolePermission) {
      return false;
    }
    if (this.rolePermission) {
      let rolePermission = Object.values(this.rolePermission);
      for (let i = 0; i < rolePermission.length; i++) {
        if (permission === rolePermission[i]) {
          return true;
        }
      }
    }
    return false;
  }
  logout () {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('rolePermission');
    this.user = null;
  }
}
export default new Auth();
