<template>
    <div class="shopping-detail">
      <div class="xadal_title_head">
        <span>{{ listLanguage.search_title_product_detail }}</span>
      </div>
      <FilterShop :sources="sources" :filter="filter" :canSubmit="canSubmit" @search="search" />
      <div class="detail">
        <product v-loading="loadingPage" :productDetail="productDetail" :activeThumbnail="activeThumbnail" @changeThumbnail="changeThumbnail" v-if="$route.query.source == 'taobao'"></product>
        <Product1688 v-loading="loadingPage" :productDetail="productDetail" :activeThumbnail="activeThumbnail" @changeThumbnail="changeThumbnail" v-if="$route.query.source == '1688'"/>
      </div>
      <div class="box-description-related">
        <el-row :gutter="20">
            <el-col :span="16">
                <Description :description="description"></Description>
            </el-col>
            <el-col :span="8">
                <Related :listRelated="listRelated"></Related>
            </el-col>
        </el-row>
      </div>
    </div>
  </template>

  <script>
  import { mapActions, mapGetters } from "vuex";

  import Product from "../../views/shopping/component-detail/Product.vue";
  import Product1688 from "../../views/shopping/component-detail/Product1688.vue";
  import Description from "../../views/shopping/component-detail/Description.vue";
  import FilterShop from "./components/search/Filter.vue";
  import Related from "../../views/shopping/component-detail/Related.vue";
  import axios from "axios";
  export default {
    name: "ShoppingDetail",
    metaInfo() {
      return {
        title: "Chi tiết sản phẩm",
      };
    },
    components: {
        Product,
        Product1688,
        Description,
        FilterShop,
        Related
    },
    data() {
      return {
        loading: true,
        loadingPage: true,
        productDetail: {},
        description: "",
        listRelated: [],
        filter: {
          source: 'taobao',
          search: '',
          price_from: '',
          price_to: '',
          per_page: 24,
          page: 1
        },
        sources: [
          {
            val: 'taobao',
            name: 'Taobao'
          },
          {
            val: '1688',
            name: '1688'
          }
        ],
        canSubmit: true,
        activeThumbnail: '',
        source: "taobao"
      };
    },
    computed: {
      ...mapGetters("account", ["logged", "listLanguage"]),
    },
    mounted() {
     this.fetchDetail();
     this.fetchRelated();
     this.source = this.$route.query.source
    },
    methods: {
      ...mapActions("account", ["getAccountInfo"]),
      async fetchDetail() {
        let url = ""
        if(this.$route.query.source == 'taobao'){
          url = "cart/product/detail";
        }else{
          url = "cart/product/detail-1688";
        }
        this.loadingPage = true;
        axios.get(url, { params: {itemId: this.$route.query.itemId}}).then((response) => {
          this.loadingPage = false;
          this.loading = false;
          switch (response.code) {
            case 200:
              this.productDetail = response.data;
              this.description = response.data.description;
              if(this.$route.query.source == 'taobao'){
                this.activeThumbnail = this.productDetail.image;
              }else{
                this.activeThumbnail = this.productDetail?.productImage[0];
              }
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
              });
              break;
          }
        });
      },
      async fetchRelated() {
        let url = ""
        if(this.$route.query.source == 'taobao'){
          url = "cart/product/related";
        }else{
          url = "cart/product/related-1688";
        }
        axios.get(url, { params: {itemId: this.$route.query.itemId, keyword: this.$route.query.search}}).then((response) => {
          this.loading = false;
          switch (response.code) {
            case 200:
              if(this.$route.query.source == 'taobao') {
                this.listRelated = response.data.resultList;
              }else {
                this.listRelated = response.data.data.map(item => {
                  return {
                    picUrl: item.imageUrl,
                    // itemUrl: item.,
                    title: item.subject,
                    price: item?.priceInfo?.price,
                    itemId: item.offerId
                  }
                })
              }
              break;
            default:
              this.$notify.error({
                title: "Error",
                message: response.message,
              });
              break;
          }
        });
      },
      search(data) {
        this.filter      = data;
        this.filter.page = 1;
        this.$router.push({name: 'shopping-search', query: {search: this.filter.search}});
      },
      changeThumbnail(url) {
        this.activeThumbnail = url;
      }
    },
    watch: {},
  };
  </script>

  <style lang="css" scoped>
    .box-description-related {
        margin-top: 24px;
        background: #ffffff;
        padding: 15px 10px;
    }
  </style>
