<template>
  <div class="home-index-address frame-content-common-2022">
    <el-dialog
      top="5%"
      width="50%"
      :visible="dialogAddress"
      :show-close="false"
      title="Nhập thông tin nhận hàng"
    >

    <div class="listAddres">
      <div v-for="item in listData" :key="item.id">
        <div class="d-flex align-items-center mt-2" @click="changeAddress(item);">
          <img class="pr-2" src="@/static/icon/account/checked.svg" v-if="item.id == form.shipping_address_id" alt="" >
          <img class="pr-2" src="@/static/icon/account/no-checked.svg" v-else alt="" />
          <span>{{ item.name + ' - ' + item.phone + ' - ' + item.address + ' - ' + item.district + ' - ' + item.city }}</span>
        </div>
      </div>
    </div>

    <div class="mt-4 d-flex align-items-center" @click="changeAddress()">
      <img class="pr-2" src="@/static/icon/account/checked.svg" v-if="form.shipping_address_id == 'new'" alt="" >
      <img class="pr-2" src="@/static/icon/account/no-checked.svg" v-else alt="" >
      <span class="txt_add_form">Địa chỉ nhận hàng khác</span>
    </div>

    <div>
      <el-form :model="form" v-if="showForm">
        <div class="d-flex align-items-center">
          <div style="width: 50%">
            <el-form-item label="Mã bưu chính *">
              <el-input v-model="form.zip" clearable></el-input>
            </el-form-item>
          </div>
          <div style="width: 50%; margin: 5px">
            <el-form-item label="Quốc gia *">
              <el-input v-model="form.country"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div style="width: 50%">
            <el-form-item label="Tên người nhận *">
              <el-input v-model="form.name" clearable></el-input>
            </el-form-item>
          </div>
          <div style="width: 50%; margin: 5px">
            <el-form-item label="Số điện thoại *">
              <el-input v-model="form.phone" clearable></el-input>
            </el-form-item>
          </div>
        </div>
        <el-form-item label="Tỉnh / thành phố *">
          <el-input v-model="form.city" clearable></el-input>
        </el-form-item>
        <el-form-item label="Quận *">
          <el-input v-model="form.district" clearable></el-input>
        </el-form-item>
        <el-form-item label="Vùng miền *">
          <el-input v-model="form.address" clearable></el-input>
        </el-form-item>
      </el-form>
      <div class="footer-dialog d-flex align-items-center justify-content-end">
        <el-button class="btnToCancel" @click="cancel();">Hủy bỏ</el-button>
        <!-- <span @click="addressDone()">Xác nhận</span> -->
        <el-button
          class="btnDone"
          @click="addressDone()"
          :disable="!dialogAddress"
          :loading="!dialogAddress"
        >Xác nhận</el-button>

        <el-button
          class="btnDone"
          @click="addressDone_save()"
          :disable="!dialogAddress"
          :loading="!dialogAddress"
        >Xác nhận và lưu địa chỉ nhận hàng</el-button>
      </div>
    </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "FormAddress",
  props: {
    dialogAddress: Boolean,
    source: String,
    listData: Array,
  },
  metaInfo() {
    return {
      title: "",
    };
  },
  components: {},
  data() {
    return {
      showForm: false,
      option: [
        {
          name: "Việt Nam",
          value: 'vn',
        },
        {
          name: "Trung quốc",
          value: 'cn',
        },
      ],
      form: {
        zip: "",
        country: "",
        address: "",
        phone: "",
        mobile_phone: "",
        city: "",
        district: "",
        name: "",
        state: "",
        shipping_address_id: "",
      },
      cansubmit: true,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    changeAddress(item) {
      if (!item) {
        this.showForm = true;
        this.initData();
        this.form.shipping_address_id = 'new';
        return;
      }

      this.showForm = true;
      this.form     = {
        zip: item.zip,
        country: item.country,
        address: item.address,
        phone: item.phone,
        mobile_phone: item.phone,
        city: item.city,
        district: item.district,
        name: item.name,
        state: item.address,
        shipping_address_id: item.id,
      }
    },
    initData() {
      if (this.source == 'taobao') {
        this.form = {
          zip: "510430",
          country: "中国",
          address: "石井街道 凰岗村 凤鸣路117号凰岗二社大型停车场F1档口",
          phone: "18720101943",
          mobile_phone: "18720101943",
          city: "广州市",
          district: "白云区",
          name: "咚咚代收",
          state: "广东省"
        }
      } else { // 1688
        this.form = {
          zip: "311121",
          country: "中国",
          address: "仓前街道欧美金融城2幢1103",
          phone: "18926668611",
          mobile_phone: "18926668611",
          city: "杭州市",
          district: "余杭区", // areaText
          name: "游强",
          state: "浙江省" // provinceText
        }
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    addressDone() {
      //console.log(this.form, 'form data');
      if (!this.form.shipping_address_id) {
        this.$notify({
          title: "Lỗi",
          message: "Vui lòng chọn địa chỉ nhận hàng",
          type: "warning",
        });
        return;
      }

      if(!this.form.zip || !this.form.country || !this.form.address || !this.form.phone || !this.form.city || !this.form.district
      || !this.form.name
      ) {
        this.$notify({
            title: "Lỗi",
            message: "Vui lòng chọn nhập đầy đủ thông tin nhận hàng",
            type: "warning",
          });
        return;
      }
      // xác nhận và ko lưu địa chỉ nhận hàng
      this.form.status = 0;
      this.showForm = false;
      // this.initData();
      this.$emit('addressDone', this.form);
    },
    // addressDone_save
    addressDone_save() {
      //console.log(this.form, 'form data');
      if (!this.form.shipping_address_id) {
        this.$notify({
          title: "Lỗi",
          message: "Vui lòng chọn địa chỉ nhận hàng",
          type: "warning",
        });
        return;
      }

      if(!this.form.zip || !this.form.country || !this.form.address || !this.form.phone || !this.form.city || !this.form.district
      || !this.form.name
      ) {
        this.$notify({
            title: "Lỗi",
            message: "Vui lòng chọn nhập đầy đủ thông tin nhận hàng",
            type: "warning",
          });
        return;
      }
      // xác nhận và ko lưu địa chỉ nhận hàng
      this.form.status = 1
      this.$emit('addressDone', this.form);
    },
  },
  watch: {
    "form.phone": function(val) {
      this.form.mobile_phone = val;
    },
    "form.address": function (val) {
      this.form.state = val
    }
  },
};
</script>

<style lang="css" scoped>
.txt_add_form {
  color: #1771c1;
  cursor: pointer;
}
</style>
<style>
.home-index-address .el-input {
  width: 100%;
}
.home-index-address .el-select {
  width: 100%;
}
.btnDone {
  background: #10DD90 !important;
  color: #fff !important;
  font-size: 12px !important;
}
.btnToCancel {
  font-size: 12px !important;
}
</style>
